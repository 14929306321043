import React, { useState, useEffect } from "react";
import { Table, ModalBody } from "react-bootstrap";
import { Pagination } from "@mui/material";
import axios from "../ServiceApi/AxiosInstance";
import EmployerSidebar from "../Sidebar/EmployerSidebar";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Container, Form, Row, Col, Button, Modal } from "react-bootstrap";
import {
  getEmployee,
  DeleteEmployee,
  AddRedFlag,
  EmployeegetByEmployerId,
  EmployerRedflagList,
  redflagEmployeeList,
  getEmployeeData,
  getallemployeesearchbyId
} from "../../Components/Api/AdminApi";

import ReportTwoToneIcon from "@mui/icons-material/ReportTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { FiSearch } from "react-icons/fi";
import Avatar from "@mui/material/Avatar";
import Auth from "../ServiceApi/Auth";
import "bootstrap/dist/css/bootstrap.min.css"; //
import Loader from "../Loader/Loader";
import NotFound from "../NotFound/NotFound";
import Switch from "@mui/material/Switch";
import RateReviewIcon from "@mui/icons-material/RateReview";
import {
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Checkbox from "@material-ui/core/Checkbox";
import { useLocation } from "react-router-dom";
const EmployerEmployeeList = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Error, setError] = useState("");
  const [isActive, setIsActive] = useState("");
  const [description, setDescription] = useState("");
  const [employeeID, setEmployeeID] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ApiComplete, SetApiComplete] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [doc, setDoc] = useState("");
  const [policeNo, setPoliceNo] = useState("");
  const [showEmployee, setShowEmployee] = useState("0");
  const [redFlag, setRedflag] = useState(false);

  console.log(data,"got data to me final")


  const itemsPerPage = 8; // Change this number based on your desired items per page
  const imagePath = "https://api.knowyourmaid.com/";
  const auth = Auth.getUserId();
  const Employer = Auth.getEmployerID();

  const handleKeyPress = (e) => {
    if (e.key === "%") {
      e.preventDefault();
    }
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setError("");
    setDoc("");
    setPoliceNo("");
    setDescription("");
  };

  const handleToggleChange = (event) => {
    setIsActive(event.target.value);
  };

  const handleCheckBox = (e) => {
    setRedflag(e.target.checked);
  };

  useEffect(() => {
    let section = location?.state?.section;
    if (section !== undefined) {
      if (section === "employee") {
        setShowEmployee("2");
      } else if (section === "redFlag") {
        setShowEmployee("1");
        setRedflag(true);
      }
    } else {
      setShowEmployee("2");
    }
  }, [location]);
  useEffect(() => {
    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let details = data.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(details);
  }, [currentPage]);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    if (showEmployee === "1") {
      getRedflagListbyEmployer(Employer, searchValue);
    } else if (showEmployee === "2") {
      getEmployeeList(Employer, searchValue);
    }
    else if(showEmployee === '3' && searchValue !== ""){
      getAllEmployee(searchValue)
    }
  }, [Employer, searchValue, showEmployee, redFlag]);

  const openModal = (list) => {
    handleShow();
    setEmployeeID(list.id);
    setIsActive(list.red_flag);
  };

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    let contactregex = /^(?!0+$)\d+$/i;
    let nameregexx = /^[a-zA-Z-' ]+$/;

    if (description === "") {
      isValid = false;
      Error["description"] = "Please Enter Description";
    } else if (nameregexx.test(description) === false) {
      isValid = false;
      Error["description"] = "Please Enter Valid Description.";
    }

    if (isActive === "") {
      isValid = false;
      Error["isActive"] = "Please select option";
    }
    if (doc === "") {
      isValid = false;
      Error["doc"] = "Please select File";
    }

    setError(Error);
    return isValid;
  };
  const submit = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      const data = {
        red_flag: "Active",
        redFlag_desc: description,
        police_case_no: policeNo,
        redFlagDoc: doc,
      };
      AddRedFlag(data, employeeID)
        // axios
        //   .post(`http://localhost:8000/api/redFlag/${employeeID}`, data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Red Flag added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          cancelData();
          handleClose();
          getEmployeeList(Employer, searchValue);
        })
        .catch((err) => {
          console.log(err);
          // Handle network errors or other issues.
          Swal.fire({
            icon: "error",
            title: err.response.data.errors.redFlagDoc,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleListonToggleButton = (e, searchValue) => {
    setShowEmployee(e.target.value);
    // if (e.target.value === "2") {
    //   getEmployeeList(Employer, searchValue);
    // } else if (e.target.value === "3") {
    //   getRedflagListbyEmployer(Employer, searchValue);
    // } else {
    //   employeeList(searchValue);
    // }
  };

  const employeeList = (search) => {
    setLoading(true);
    getEmployee(searchValue)
      // axios.get(`http://localhost:8000/api/getEmp/${auth}`)
      .then((res) => {
        if (res.status == 200) {
          setData(res.data.results);
          setCurrentPage(1);
          let indexOfLastItem = 1 * itemsPerPage;
          let indexOfFirstItem = indexOfLastItem - itemsPerPage;
          let details = res.data.results.slice(
            indexOfFirstItem,
            indexOfLastItem
          );
          setCurrentItems(details);
        } else {
          // setLoading(false);
          setData([]);
          setCurrentItems([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        setCurrentItems([]);
      })
      .finally(() => {
        setLoading(false);
        SetApiComplete(true);
      });
  };

  const getEmployeeList = (Employer, searchValue) => {
    setLoading(true);
    EmployeegetByEmployerId(Employer, searchValue)
      // axios.get(`http://localhost:8000/api/getEmp/${auth}`)
      .then((res) => {
        console.log(res)
        if (res.status == 200) {
          setData(res.data);
          setCurrentPage(1);
          let indexOfLastItem = 1 * itemsPerPage;
          let indexOfFirstItem = indexOfLastItem - itemsPerPage;
          let details = res.data.slice(indexOfFirstItem, indexOfLastItem);
          setCurrentItems(details);
        } else {
          // setLoading(false);
          setData([]);
          setCurrentItems([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        setCurrentItems([]);
      })
      .finally(() => {
        setLoading(false);
        SetApiComplete(true);
      });
  };


  const getAllEmployee = (searchValue) => {
    setLoading(true);
    // getEmployee(searchValue)
    getallemployeesearchbyId(searchValue)
      // axios.get(`http://localhost:8000/api/getEmp/${auth}`)
      .then((res) => {
        console.log(res,"grt got")
        if (res.status == 200) {

          setData(res.data);
          setCurrentPage(1);
          let indexOfLastItem = 1 * itemsPerPage;
          let indexOfFirstItem = indexOfLastItem - itemsPerPage;
          let details = res.data.slice(indexOfFirstItem, indexOfLastItem);
          setCurrentItems(details);
        } else {
          // setLoading(false);
          setData([]);
          setCurrentItems([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        setCurrentItems([]);
      })
      .finally(() => {
        setLoading(false);
        SetApiComplete(true);
      });
  };


  const getRedflagListbyEmployer = (Employer, searchValue) => {
    setLoading(true); // Show loader when the request starts
    EmployerRedflagList(Employer, searchValue)
      .then((res) => {
        console.log(res, "get red");
        if (res.status === 200) {
          setData(res.data);
          setCurrentPage(1);
          let indexOfLastItem = 1 * itemsPerPage;
          let indexOfFirstItem = indexOfLastItem - itemsPerPage;
          let details = res.data.slice(indexOfFirstItem, indexOfLastItem);
          setCurrentItems(details);
        } else {
          setData([]);
          setCurrentItems([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setCurrentItems([]);
        setData([]);
      })
      .finally(() => {
        setLoading(false); // Hide loader when the request is complete (success or error)
        SetApiComplete(true);
      });
  };

  const getAllRedFlag = () => {
    setLoading(true); // Show loader when the request starts
    redflagEmployeeList(searchValue)
      .then((res) => {
        console.log(res, "get red +++++++++++++++");
        if (res.status === 200) {
          setData(res.data.employees);
          setCurrentPage(1);
          let indexOfLastItem = 1 * itemsPerPage;
          let indexOfFirstItem = indexOfLastItem - itemsPerPage;
          let details = res.data.employees.slice(
            indexOfFirstItem,
            indexOfLastItem
          );
          setCurrentItems(details);
        } else {
          setData([]);
          setCurrentItems([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setCurrentItems([]);
        setData([]);
      })
      .finally(() => {
        setLoading(false); // Hide loader when the request is complete (success or error)
        SetApiComplete(true);
      });
  };

  const deleteEmployee = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteEmployee(id)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Employee Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getEmployeeList(Employer, searchValue);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Employee Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash menu");
      });
  };

  const cancelData = () => {
    setDescription("");
    setIsActive("");
  };

  return (
    <>
      <EmployerSidebar />
      {loading && <Loader />}

      <>
        <Container>
          <div className="row mt-5">
            <h3 className="display-7 py-5 heading-h3 text-center">
              Employee <span className="display-7 heading-h3-green">List</span>
            </h3>

            <Row className="d-flex justify-content-between pt-3">
              <Col sm={3} className="mb-3">
                <Link to={"/Employee"}>
                  <div>
                    <button
                      type="submit"
                      class="btn-add-employee"
                      title="Click to add new Employee"
                      // onClick={submitVideo}
                    >
                      Add Employee
                    </button>
                  </div>
                </Link>
              </Col>
              <Col sm={3} className="mb-3">
                <Form.Group
                  className="justify-content-center"
                  controlId="formHorizontalEmail"
                >
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Search
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      size="small"
                      placeholder="Search by Employee Id No"
                      name="searchValue"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      onKeyPress={handleKeyPress}
                      startAdornment={
                        <InputAdornment position="start">
                          <FiSearch />
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div>
            {/* <Switch onChange={(e)=>handleListonToggleButton(e,searchValue)} title="on" checked={showEmployee} defaultChecked/> */}
            <RadioGroup
              row={true}
              className="inline-radionbtnwrap d-flex justify-content-end"
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="My Employees"
                value="2"
                checked={showEmployee === "2"}
                onChange={(e) => handleListonToggleButton(e, searchValue)}
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Red flagged"
                value="1"
                checked={showEmployee === "1"}
                onChange={(e) => handleListonToggleButton(e, searchValue)}
              />

              <FormControlLabel
                control={<Radio color="primary" />}
                label="Other Employees"
                value="3"
                checked={showEmployee === "3"}
                onChange={(e) => handleListonToggleButton(e, searchValue)}
              />

              {/* <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={redFlag}
                    onChange={(e) => handleCheckBox(e, searchValue)}
                  />
                }
                label="Redflag Employees"
                value="3"
              /> */}
            </RadioGroup>
          </div>
          <div className="py-5">
            <div className="row">
              <div className="col-sm-12 table-responsive text-center">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      {/* <th>Contact Person Name</th> */}
                      {/* <th>Contact Person No.</th> */}
                      <th>Employee Id No.</th>
                      <th>Contact</th>
                      <th>Address</th>
                      <th>Experience</th>
                      <th>Service Type</th>
                      <th>Image</th>
                      <th>Red Flag</th>
                      {showEmployee === "2" ? (
                        <th colSpan={3}>Action</th>
                      ) : null}

                      {/* <th>Delete</th>                                                      
              <th>Review</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((list, index) => (
                      <tr key={list.id}>
                        <td>
                          {index +
                            1 +
                            (currentPage * itemsPerPage - itemsPerPage)}
                        </td>
                        <td>{list.full_name}</td>
                        <td>{list.email === null ? "-" : list.email}</td>
                        {/* <td>{list.contact_person}</td> */}
                        {/* <td>{list.contact_per_num}</td> */}
                        <td>
                          {list.id_number === null ? "-" : list.id_number}
                        </td>
                        <td>{list.contact_number}</td>
                        <td>{list.address_1}</td>
                        <td>
                          {list.experience_in_years === "Fresher" ||
                          list.experience_in_years === null
                            ? "Fresher"
                            : `${list.experience_in_years} Year`}
                        </td>
                        <td>
                          {list.services.length === 0
                            ? "-"
                            : list.services
                                .map((a) => a?.service_name)
                                .filter(Boolean) // Filter out null or undefined values
                                .join(", ")}
                        </td>
                        <td className="text-center">
                          <IconButton sx={{ p: 0 }}>
                            <Avatar
                              alt="Remy Sharp"
                              src={imagePath + list.passportSize_img}
                            />
                          </IconButton>
                        </td>
                        {showEmployee === "2" ? (
                          <>
                            <td>
                              {list.red_flag == "Deactive" ? (
                                <IconButton
                                  onClick={() => openModal(list)}
                                  title="Click to add Red Flag"
                                >
                                  <ReportTwoToneIcon color="success" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  // onClick={() => openModal(list)}
                                  title="Red Flag Employee"
                                >
                                  <ReportTwoToneIcon color="error" />
                                </IconButton>
                              )}
                            </td>

                            <td style={{ width: "200px" }}>
                              <Link to={`/Edit_Employee/${list.id}`}>
                                <IconButton type="btn btn-primary" title="edit">
                                  <EditTwoToneIcon />
                                </IconButton>
                              </Link>
                              <IconButton
                                type="btn btn-primary"
                                onClick={() => deleteEmployee(list.id)}
                                title="delete"
                              >
                                <DeleteTwoToneIcon />
                              </IconButton>
                              <Link
                                to="/Reviews"
                                state={
                                  { employeeid: list.id } // Replace yourDataObject with the data you want to pass
                                }
                              >
                                <IconButton
                                  type="btn btn-primary"
                                  title="Review"
                                >
                                  <RateReviewIcon />
                                </IconButton>
                              </Link>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              {list.red_flag == "Deactive" ? (
                                <IconButton>
                                  <ReportTwoToneIcon
                                    color="success"
                                    title="Click to add Red Flag"
                                  />
                                </IconButton>
                              ) : (
                                <IconButton>
                                  <ReportTwoToneIcon
                                    color="error"
                                    title="Red Flag Employee"
                                  />
                                </IconButton>
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {data.length === 0 && ApiComplete ? <NotFound /> : null}

                <Pagination
                  count={Math.ceil(data.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
                <br />
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog-centered"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                <div className="text-center">
                  <h3 className="display-7 heading-h3">
                    Add
                    <span className="display-7 heading-h3-green">
                      {" "}
                      Red Flag !
                    </span>
                  </h3>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="container mt-4">
                <div className="row d-flex justify-content-center pt-6">
                  <div className="col-sm-12">
                    <div className="card form-border">
                      <div className="card-body">
                        <form action="/submit_review" method="POST">
                          <div className="col-sm-12">
                            <label htmlFor="userImage" className="form-label">
                              Add Description
                            </label>
                            <textarea
                              value={description}
                              className="form-control"
                              onChange={(e) => {
                                setDescription(e.target.value);
                                setError({ ...Error, description: "" });
                              }}
                            ></textarea>
                          </div>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {Error.description}
                          </div>

                          <div className="col-sm-12">
                            <label htmlFor="userImage" className="form-label">
                              Police Booking Number
                            </label>
                            <input
                              value={policeNo}
                              className="form-control"
                              onChange={(e) => {
                                setPoliceNo(e.target.value);
                                // setError({ ...Error, policeNo: "" });
                              }}
                            ></input>
                          </div>

                          <div className="col-sm-12">
                            <label htmlFor="userImage" className="form-label">
                              Add Document
                            </label>
                            <input
                              // value={doc}
                              type="file"
                              className="form-control"
                              onChange={(e) => {
                                setDoc(e.target.files[0]);
                                setError({ ...Error, doc: "" });
                              }}
                            ></input>
                          </div>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {Error.doc}
                          </div>

                          <br />
                          <div className="row d-flex justify-content-center">
                            <button
                              type="submit"
                              class="btn btn-primary col-sm-5"
                              onClick={submit}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </>
    </>
  );
};

export default EmployerEmployeeList;

import React, { useState, useEffect } from "react";
import SideNav from "../Sidebar/SideNav";
import axios from "../ServiceApi/AxiosInstance";
import { AddVideodata, getVideo, EditVideoData } from "../Api/AdminApi";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import { DeleteVideo } from "../Api/AdminApi";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import Loader from "../Loader/Loader";
import { Pagination } from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";

const AddVideo = () => {
  const [isActive, setIsActive] = useState(false);
  const [video, setVideo] = useState("");
  const [description, setDescription] = useState("");
  const [videoURL, setVideoURL] = useState([]);
  const [Error, setError] = useState("");
  const [data, setData] = useState([]);
  const [Status, setStatus] = useState("");
  const [Loading,setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Change this number based on your desired items per page


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const handleCheckboxChange = (e,a) => {
    setStatus(e.target.checked ? "Active" : "Deactive");
    // Additional logic for handling checkbox changes
    const id = a.id;
    const videodata = a.video_link;
    const descriptiondata = a.description
    const data = {
      video_link: videodata,
      description: descriptiondata,
      status: e.target.checked  ? "Active" : "Deactive",
    };
    EditVideoData(id, data)
      .then((res) => {
        console.log(res);  
        getVideoList();

      }).catch((err) => {
        console.log(err);
      });
  };

  // const handleCheckboxClick = (a) => {
  //   debugger

  // };

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=|c\/|u\/\w\/|user\/\w+)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

    if (video === "") {
      isValid = false;
      Error["video"] = "Please Paste Video Link";
    }else if (youtubeRegex.test(video) === false) {
      isValid = false;
      Error["video"] = "Please Enter Valid Link.";
    }
    // if(description === ""){
    //   isValid = false;
    //   Error["description"] = "Please Enter Description";
    // }
    // if (isActive === "") {
    //   isValid = false;
    //   Error["isActive"] = "Please select Status";
    // }

    setError(Error);
    return isValid;
  };

  const handleToggleChange = (event) => {
    setIsActive((prevIsActive) => !prevIsActive);
    setError({ ...Error, isActive: "" });
  };

  

  useEffect(() => {
    getVideoList();
  }, []);

  const getVideoList = () => {
    setLoading(true)
    getVideo("")
      .then((res) => {
        setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setLoading(false)
      })
  };

  const submitVideo = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      const data = {
        video_link: video,
        description:description,
        status: "Active",
      };
      //   axios
      //     .post("http://localhost:8000/api/video-link",data)
      AddVideodata(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Video added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          getVideoList();
          cancelData();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Video Not Added",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        });
    }
  };



  const deleteVideoList = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteVideo(id)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Video Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getVideoList();

            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Service Type Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash menu");
      });
  };

  const cancelData = () => {
    setVideo("");
    setDescription("");
    setIsActive("");
  };

  return (
    <>
      <SideNav />
      {Loading && <Loader/>}
      <div class="container mt-4">
        <div className="text-center">
          <h3 className="display-7 heading-h3">
            Add<span className="display-7 heading-h3-green"> Video !</span>
          </h3>
        </div>
        <div className="row d-flex justify-content-center pt-3">
          <div className="col-sm-6">
            <div className="card form-border">
              <div className="card-body">
                <form action="/submit_review" method="POST">
                  <div className="col-sm-12">
                    <label for="userImage" className="form-label">
                      Add Video URL
                    </label>
                    <input
                      value={video}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setVideo(e.target.value);
                        setError({ ...Error, video: "" });
                      }}
                    />
                  </div>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {Error.video}
                  </div>

                  <div className="col-sm-12">
                    <label for="userImage" className="form-label">
                      Add Description
                    </label>
                    <textarea
                      value={description}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setDescription(e.target.value);
                        // setError({ ...Error, description: "" });
                      }}
                    />
                  </div>
                  {/* <div style={{ color: "red", fontSize: 13 }}>
                    {description.video}
                  </div> */}

                  <br/>
                
                  <div className="row d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-primary col-sm-5"
                      onClick={submitVideo}
                    >
                      Submit Video
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col">
            <h3 className="display-7 heading-h3 text-center">
              Video <span className="display-7 heading-h3-green">List</span>
            </h3>
            <div className="container mt-5 text-center">
              <Table striped bordered hover className="fixedLayout">
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Video Link</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>

                    {/* <th className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                {currentItems.map((list, index) => (
                      
                        <tr key={list.id}>
                           <td>
                        {index +
                          1 +
                          (currentPage * itemsPerPage - itemsPerPage)}
                      </td>
                          <td className="wrapTxtCell">{list.video_link}</td>
                          <td className="wrapTxtCell">{list.description?list.description :"-"}</td>
                          <td>
                            {list.status}{" "}
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              checked={list.status === "Active"}
                              onChange={(e)=>handleCheckboxChange(e,list)}
                              // onClick={() => handleCheckboxClick(list)}
                            />
                          </td>
                          <td>
                          <IconButton
                              type="btn btn-primary"
                              onClick={() => deleteVideoList(list.id)}
                              title="delete"
                            >
                              <DeleteTwoToneIcon />
                            </IconButton>
                            </td>
                       
                        </tr>
                      
                    ))}
                </tbody>
              </Table>
            </div>
            <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddVideo;

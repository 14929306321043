import React, { useState, useEffect } from "react";
import SideNav from "../Sidebar/SideNav";
import { Table } from "react-bootstrap";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
const ServiceTypeList = () => {
  const [data, setData] = useState([]);
  console.log(data, "servicelist");

  useEffect(() => {
    getServiceList();
  }, []);

  const getServiceList = () => {
    axios
      .get("http://localhost:8000/api/service-type")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateList =(id)=>{
    window.location.href = "/ServiceType";
  }

  return (
    <>
      <SideNav />
      <div className="container py-5 mt-4">
        <div className="pb-3 text-center">
          <h3 className="display-7 heading-h3">Service Type <span className="display-7 heading-h3-green">List</span></h3>
        </div>
        <div className="row">
       <div className="col-sm-12">
       <Table striped bordered hover>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Description</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data.map((list) => (
              <tr>
                <td>{list.service_name}</td>
                <td>{list.description}</td>

                <td className="text-center">
                  <IconButton type="btn btn-primary" onClick={()=>updateList(list.id)}><EditTwoToneIcon/></IconButton>
                </td>
                <td className="text-center">
                  <IconButton type="btn btn-primary"><DeleteTwoToneIcon/></IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
       </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTypeList;

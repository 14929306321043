import React ,{useState,useEffect} from 'react';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import Auth from "../ServiceApi/Auth"; 
import { Email } from "@mui/icons-material";

function Topbar() {
  const role = Auth.getRole();

  const [tokenData,setTokenData] = useState("");
  const [mail,setMail] = useState("");
  const [name,setName] = useState("");

  useEffect(()=>{
    let mail = Auth.getEmail();
    setMail(mail);
    const token = Auth.getToken();
    setTokenData(token)
    let user = Auth.getName()
    setName(user)

  },[]);

  const handleLogout = ()=>{
    Auth.logout();

  }


  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">Know Your Maid</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#features">Home</Nav.Link> */}
            {/* <Link
              to="https://knowyourmaid.com/#about-us"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              About Us
            </Link> */}
            {role === 3 ? null :
            <Nav.Link eventKey={2} href="/#about-us">
              About Us
            </Nav.Link>
            }

            {/* <NavDropdown title="Services" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* <Nav.Link href="#pricing">Know Your Maid</Nav.Link> */}
          </Nav>
          <Nav>
            {/* <Link
              to="https://knowyourmaid.com/#memes"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none'}}
            >
              Conatct Us
            </Link> */}
            {role === 3 ? null :
            <Nav.Link eventKey={2} href="/#memes">
              Contact Us 
            </Nav.Link>
            }
         {tokenData === undefined?(
          <>
            <Nav.Link href="/Login">Login</Nav.Link>
            <Nav.Link href="/Register">Register</Nav.Link>
            </>  ):(
              <Nav.Link>
                {/* <p className='login-user-name'>{name}</p> */}
              </Nav.Link>

            )}
               {tokenData !== undefined?(
                <Nav.Link href="/Register" onClick={handleLogout}>Logout</Nav.Link>
                ):(
                  null
                )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Topbar;

import React, { useState, useEffect } from "react";
import SideNav from "../Sidebar/SideNav";
import { AddPayment } from "../Api/AdminApi";
import Swal from "sweetalert2";
import { useNavigate,useLocation } from "react-router-dom";
import Topbar from "../Home/Topbar";
import Auth from "../ServiceApi/Auth";

const Payment = () => {
  const location = useLocation();
  const userType = location.state?.userType;
  const MobileNo = location.state?.cardNo;
  const [cardNo, setCardNo] = useState("");
  const [date, setDate] = useState("");
  const [cv, setCv] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(userType === "Employer" ? "1200" :null);
  const [Error, setError] = useState("");


  const navigate = useNavigate()

  useEffect(()=>{
    const contact_number = location.state?.contact_number;
    setCardNo(contact_number)
  },[Auth])


  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    if (cardNo === "") {
      isValid = false;
      Error["cardNo"] = "Please Enter Phone Number";
    }
    // if (date === "") {
    //     isValid = false;
    //     Error["date"] = "Please Enter Expiry Date";
    //   }

    // if (cv === "") {
    //   isValid = false;
    //   Error["cv"] = "Please Enter CVV no";
    // }
    // if (name === "") {
    //   isValid = false;
    //   Error["name"] = "Please Enter Name";
    // }
    if (amount === "") {
      isValid = false;
      Error["amount"] = "Please Enter Amount";
    }

    setError(Error);
    return isValid;
  };

  const cancelField = () => {
    setAmount("");
    setCardNo("");
  };

  const payment = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      const data = {
        amount: amount,
        phone: cardNo,
      };
      AddPayment(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title:
              "Payment request Accepted Successfully,Please check on Register No for further Proccess",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          cancelField();
          navigate("/login")
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Topbar />
      <div class="container mt-4">
        <div className="text-center">
          <h3 className="display-7 heading-h3">
            Make{" "}
            <span className="display-7 heading-h3-green">Payment Here!</span>
          </h3>
      
        </div>
        <div className="row d-flex justify-content-center pt-3">
          <div className="col-sm-6">
            <div className="card form-border">
              <div className="card-body">
                <form className="row" action="/process_payment" method="POST">
                  <div class="form-group mb-3">
                    <label for="cardNumber" className="form-label">
                      Phone Number (Note: please enter your M-pesa registered Number)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cardNumber"
                      name="cardNumber"
                      // disabled
                      value={cardNo}
                      onChange={(e) => {
                        setCardNo(e.target.value);
                        setError({ ...Error, cardNo: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.cardNo}
                    </div>
                  </div>

                  {/* <div class="form-group col-md-6 mb-3">
                    <label for="expirationDate" className="form-label">
                      Expiration Date
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="expirationDate"
                      name="expirationDate"
                      placeholder="MM/YY"
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                        setError({ ...Error, date: "" });
                      }}
                    />
                      <div style={{ color: "red", fontSize: 13 }}>
                    {Error.date}
                  </div>
                  </div> */}
                  {/* <div class="form-group col-md-6 mb-3">
                    <label for="cvv" className="form-label">
                      CVV
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cvv"
                      name="cvv"
                      value={cv}
                      onChange={(e) => {
                        setCv(e.target.value);
                        setError({ ...Error, cv: "" });
                      }}
                    />
                      <div style={{ color: "red", fontSize: 13 }}>
                    {Error.cv}
                  </div>
                  </div> */}

                  {/* <div class="form-group mb-3">
                    <label for="cardHolderName" className="form-label">
                      Card Holder Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cardHolderName"
                      name="cardHolderName"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setError({ ...Error, name: "" });
                      }}
                    />
                      <div style={{ color: "red", fontSize: 13 }}>
                    {Error.name}
                  </div>
                  </div> */}
                  <div class="form-group mb-3">
                    <label for="amount" className="form-label">
                      Amount in(Ksh)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="amount"
                      name="amount"
                      value={amount}
                      // onChange={(e) => {
                      //   setAmount(e.target.value);
                      //   setError({ ...Error, amount: "" });
                      // }}
                      readonly
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.amount}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-primary col-sm-5"
                      onClick={payment}
                    >
                      Make Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payment;

import React, { useState, useEffect } from "react";
import Topbar from "../Home/Topbar";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import mop from "../../assets/images/mop.png";
import maid from "../../assets/images/maid.png";
import nurse from "../../assets/images/nurse.png";
import worker from "../../assets/images/worker.png";
import historian from "../../assets/images/historian.png";
import verified from "../../assets/images/correct1.png";
import progress from "../../assets/images/grow-up.png";
import safety from "../../assets/images/shield.png";
import Auth from "../ServiceApi/Auth";
import house from "../../assets/images/house.png";
import balance from "../../assets/images/balance.png";
import correct from "../../assets/images/correct.png";
import Imgcontact from "../../assets/images/contact-illustration.png";
import Accordion from "react-bootstrap/Accordion";
import {
  EmployeegetById,
  getRedFlagEmployee,
  AddContactUs,
  getActiveVideo,
  getQuestionsAnswers,
} from "../Api/AdminApi";
import getYouTubeID from "get-youtube-id";
import axios from "axios";
import "animate.css";
import Footer from "./Footer";
// slider import Slider from "react-slick";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Search } from "@mui/icons-material";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settings2 = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const LandingPage = () => {
  useEffect(() => {
    // Scroll to the element with the ID specified in the URL hash
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  const userData = Auth.getRole();
  const mail = Auth.getEmail();
  const userName = Auth.getName();
  const token = Auth.getToken();
  const openDoc = (id) => {
    EmployeegetById(id)
      .then((res) => {
        setFile(res.data.user.redFlagDoc);
        let liveurl = `https://api.knowyourmaid.com`;
        const pdfUrl = `${liveurl}/${res.data.user.redFlagDoc}`;
        // `${liveurl}/${res.data.user.redFlagDoc}`;
        // "https://api.knowyourmaid.com/storage/redFlag_Document/0irxzQrt7uDUPZoPQyPqZy4PNJmZX8lwLx8Kxt9U.pdf";
        window.open(pdfUrl, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleLogout = () => {
    Auth.logout();
  };
  const [FAQList, setFAQList] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [redFlagEmp, setRedFlagEmp] = useState([]);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Contact, setContact] = useState("");
  const [Message, setMessage] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [Error, setError] = useState("");
  const [file, setFile] = useState("");
  const [datalength, setDataLength] = useState([]);
  const imagePath = "https://api.knowyourmaid.com/";

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let contactregex = /^[0-9]+$/i;
    let pincoderegex = /^[1-9][0-9]{5}$/;
    let experiencenoregex = /^(?:0|[1-9]\d*)$/;

    if (Name === "") {
      isValid = false;
      Error["Name"] = "Please Enter Name";
    }
    if (Email === "") {
      isValid = false;
      Error["Email"] = "Please Enter Email";
    } else if (emailregex.test(Email) === false) {
      isValid = false;
      Error["Email"] = "Please Enter Valid Email";
    }
    if (Contact === "") {
      isValid = false;
      Error["Contact"] = "Please Enter Contact";
    } else if (contactregex.test(Contact) === false) {
      isValid = false;
      Error["Contact"] = "Please Enter Valid Contact No.";
    } else if (Contact.length > 10) {
      isValid = false;
      Error["Contact"] = "Please Enter Valid Contact No.";
    }
    if (Message === "") {
      isValid = false;
      Error["Message"] = "Please Enter Message";
    }

    setError(Error);
    return isValid;
  };

  useEffect(() => {
    getVideos("");
    getRedFlagEmp();
    faqList();
  }, []);

  const faqList = () => {
    getQuestionsAnswers()
      .then((res) => {
        if (res.status === 200) {
          let dataList = res.data.data;
          setFAQList(dataList);
        } else {
          console.log("data not found");
        }
      })
      .catch((err) => {
        console.log(err, "error in landing page FAQ");
      });
  };

  const getVideos = () => {
    getActiveVideo()
      .then((res) => {
        const data = res.data.video_link;
        setVideoData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(videoData.length, "length");

  const getRedFlagEmp = () => {
    getRedFlagEmployee()
      // axios.get("http://localhost:8000/api/empListRed")
      .then((res) => {
        const data = res.data.employees;
        setRedFlagEmp(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const contactUs = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      const data = {
        name: Name,
        email: Email,
        contact_number: Contact,
        message: Message,
      };
      AddContactUs(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title:
              "Your message has been sent successfully. We will get back to you shortly.",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
          resetField();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const resetField = () => {
    setName("");
    setEmail("");
    setContact("");
    setMessage("");
  };

  return (
    <>
      <div>
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="/">Know Your Maid</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                {/* <Nav.Link href="#features">Home</Nav.Link> */}
                <Nav.Link href="#about-us">About Us</Nav.Link>

                {/* <Nav.Link href="#pricing">Know Your Maid</Nav.Link> */}
              </Nav>
              <Nav>
                <Nav.Link eventKey={2} href="#memes">
                  Contact Us
                </Nav.Link>
                {token === undefined ? (
                  <>
                    <Nav.Link href="/Login">Login</Nav.Link>
                    <Nav.Link href="/Register">Register</Nav.Link>
                  </>
                ) : (
                  <Nav.Link>
                    <p className="login-user-name">{userName}</p>
                  </Nav.Link>
                )}

                {token !== undefined ? (
                  <Nav.Link href="/Register" onClick={handleLogout}>
                    Logout
                  </Nav.Link>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="banner-bg">
        <Container>
          <Row>
            <Col sm={8}>
              <div className="text-start">
                <div>
                  <img
                    src={mop}
                    alt="mop"
                    className="animate__animated animate__swing animate__infinite"
                  />
                </div>
                <h1 className="display-4 heading-h1 ">
                  Enhance Security with Our{" "}
                  <span className="display-4 heading-green ">
                    Trusted Maid Verification
                  </span>
                </h1>
                <div className="py-2">
                  <p className="sub-heading">
                    Reliable Verification for Your Peace of Mind
                  </p>
                  {/* <p className="sub-heading">Information is power.</p> */}
                </div>
                <Row className="py-2">
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <Link to="/register">
                      <button className="login-btn  animate__animated animate__jello">
                        Start Verifying Today
                      </button>
                    </Link>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <div className="text-center">
            <h3 className="display-6 heading-h3">
              Our Goal —{" "}
              <span className="display-6 heading-h3-green">Zero Harm</span>
            </h3>
          </div>
          <Row className="pt-5">
            <Col sm={6}>
              <div className="">
                <p>
                  Housegirls , Nannies, Domestic Workers, Gardeners and other
                  workers who committed crime before or assisted criminals to
                  commit crime or are simply Lazy, Irresponsible, unaccountable,
                  beat children, molest children and so on...... should NEVER be
                  given another chance to serve in a Home or Office of an
                  unsuspecting Employer.
                </p>
                <p>
                  Help your neighbor know them before he/she becomes another
                  Victim. Do this by registering your existing Househelp,
                  housegirl, Nanny or any other worker in your house or office
                  and keep updating their progress on as often as possible while
                  they are still with you. When they are finally gone, make your
                  Closing Comments and rate them.
                </p>
                <p>
                  This information will help their next possible Employer know
                  who they are before officially allowing them to his her home
                  or office. To be Forewarned is be Forearmed.
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <Row className="mb-4">
                <Col className="col-lg-6 col-md-6 col-sm-12 ">
                  <div className="goal-box text-center">
                    <img src={maid} alt="servant" className="img-fluid" />
                    <p>We offer screening services for household staff.</p>
                  </div>
                </Col>
                <Col className="col-lg-6 col-md-6 col-sm-12 ">
                  <div className="goal-box text-center">
                    <img src={nurse} alt="servant" className="img-fluid" />
                    <p>Trust us for healthcare worker verifications.</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="goal-box text-center">
                    <img src={worker} alt="servant" className="img-fluid" />
                    <p>
                      Reliable screening for industrial and logistics staff.
                    </p>
                  </div>
                </Col>
                <Col className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="goal-box text-center">
                    <img src={historian} alt="servant" className="img-fluid" />
                    <p>Secure your workplace with our staff screening.</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <div className="text-center">
            <h3 className="display-6 heading-h3">
              Our <span className="display-6 heading-h3-green">Features</span>
            </h3>
          </div>
          <Row className="pt-5">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="services-box text-center">
                <div className="pb-2">
                  <img src={verified} alt="maid" className="img-fluid" />
                  <h5 className="pt-2">Comprehensive Worker Profiles</h5>
                </div>
                <div className="text-center">
                  <p className="p-blue">
                    In-Depth Information at Your Fingertips
                  </p>
                  <p>
                    Access detailed profiles of housegirls, nannies, domestic
                    workers, gardeners, and more. Make informed hiring decisions
                    with essential background information and performance
                    ratings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="services-box text-center">
                <div className="pb-2">
                  <img src={progress} alt="maid" className="img-fluid" />
                  <h5 className="pt-2">Real-time Progress Updates</h5>
                </div>
                <div className="text-center">
                  <p className="p-blue">Stay Informed, Stay Secure</p>
                  <p>
                    Receive real-time updates on your workers' performance and
                    behavior. Stay in the know as they work in your home or
                    office, ensuring a safe and reliable environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="services-box text-center">
                <div className="pb-2">
                  <img src={safety} alt="maid" className="img-fluid" />
                  <h5 className="pt-2">Safety Ratings and Reviews</h5>
                </div>
                <div className="text-center">
                  <p className="p-blue">Community-Powered Accountability</p>
                  <p>
                    Rate and provide comments on your workers, helping your
                    neighbors make informed choices. Join a network that
                    prioritizes safety and accountability in hiring decisions.
                  </p>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="py-5 kym-section" id="about-us">
        <Container>
          <Row>
            <Col sm={6}>
              <p className="text-white mb-0">ABOUT</p>
              <h1 className="display-3 kym-heading">
                Know <br />
                Your <span className="display-3 kym-heading-white">Maid</span>
              </h1>
              <div className="pt-3">
                <p className="p-dark">
                  Help us keep the performance record of your Maid and other
                  workers. Their Next Employer deserves to Know who they are
                  before employing them.
                </p>
                <p className="p-dark">
                  The easiest way for a criminal to access the privacy of your
                  Home or Office is by working there. Statistics have shown that
                  67% of the homes and office burglary, break-ins and
                  kidnappings are planned by insiders( workers). Such workers
                  will do anything possible to access your Home or Office. They
                  are even willing to work for free or for a very minimum wage.
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <div className="text-center">
            <h3 className="display-6 heading-h3">
              What Does{" "}
              <span className="display-6 heading-h3-green">It Covers</span>
            </h3>
          </div>
          <Row className="pt-4">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-3">
              <div className="verify-box">
                <div className="verify-border">
                  <div className="text-center py-2">
                    <img src={house} alt="Address Verification" />
                  </div>
                  <div className="text-center">
                    <p className="p-dark text-center mb-0">
                      Address Verification
                    </p>
                    <p>(Physical home visit)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-3">
              <div className="verify-box">
                <div className="verify-border">
                  <div className="text-center py-2">
                    <img src={balance} alt="Address Verification" />
                  </div>
                  <div className="text-center">
                    <p className="p-dark text-center mb-0">
                      Past Criminal Record Verification
                    </p>
                    <p>(Advocate’s Letter Head)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-3">
              <div className="verify-box">
                <div className="verify-border">
                  <div className="text-center py-2">
                    <img src={correct} alt="Address Verification" />
                  </div>
                  <div className="text-center">
                    <p className="p-dark text-center mb-0">
                      Verify if Maid’s documents
                    </p>
                    <p>(for eg. ID card) are genuine</p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <br />
      {redFlagEmp?.length && redFlagEmp?.length !== 0 ? (
        <section>
          <Container>
            <div className="text-center pb-4">
              <h3 className="display-6 heading-h4">
                Red Flagged{" "}
                <span className="display-6 heading-h3-green">Maids</span>
              </h3>
            </div>
            <Row>
              {" "}
              <div>
                <Slider {...settings}>
                  {redFlagEmp &&
                    redFlagEmp.map((list) => (
                      <div className="mb-3">
                        <div className="red-flag-box">
                          <Link
                            to={`/Employee_Info/${list.id}`}
                            className="emp-slider-link"
                          >
                            <div className="">
                              <img
                                src={imagePath + list.passportSize_img}
                                className="img-fluid rf-emp-image"
                              />
                            </div>
                            <div className="ref-flag-content pt-2">
                              <p className="mb-0 pb-num">
                                {list.police_case_no ? (
                                  <>
                                    <b>Case Number :</b> {list.police_case_no}
                                  </>
                                ) : null}
                              </p>

                              <p className="mb-0 pb-num">
                                <b>Crime :</b> {list.redFlag_desc}
                              </p>
                            </div>
                          </Link>
                          <div className="pt-3 text-center">
                            <button
                              type="button"
                              onClick={() => openDoc(list.id)}
                              className="doc-btn"
                              title="click to view documents"
                            >
                              View Reports
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
              <br />
              <div className="d-flex justify-content-center pt-5">
                <Link to="/Red_Flag_Employee_List">
                  <button type="button" className="login-btn">
                    View More
                  </button>
                </Link>
              </div>
            </Row>
          </Container>
        </section>
      ) : null}
      <br />
      {videoData?.length !== 0 ? (
        <section className="py-5">
          <Container>
            <div className="text-center pb-4">
              <h3 className="display-6 heading-h3">
                Employee{" "}
                <span className="display-6 heading-h3-green">Videos</span>
              </h3>
            </div>

            <Row>
              <Slider {...settings2}>
                {videoData &&
                  videoData.map((list) => (
                    <div>
                      <div>
                        <iframe
                          src={`https://youtube.com/embed/${getYouTubeID(
                            list.video_link
                          )}?autoplay=0`}
                          width="90%"
                          height="200"
                        />
                      </div>
                    </div>
                  ))}
              </Slider>
              <br />
              <br />
              <div className=" d-flex justify-content-center pt-5">
                <Link to="/Employee_Video_List">
                  <button type="button" className="login-btn">
                    View More
                  </button>
                </Link>
              </div>
            </Row>
          </Container>
        </section>
      ) : null}
      <section className="faq-section py-5">
        <Container>
          <div className="text-center pb-3">
            <h3 className="display-6 heading-h3">
              Answers to Your Questions:
              <span className="display-6 heading-h3-green">
                {" "}
                A Comprehensive FAQ Guide
              </span>
            </h3>
          </div>
          <Row>
            <Col>
              <Accordion defaultActiveKey="0">
                {FAQList &&
                  FAQList.map((list, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        {index + 1}. {list.question}
                      </Accordion.Header>
                      <Accordion.Body>
                        {list.answer.split("\n").map((paragraph, i) => (
                          <p key={i}>{`${paragraph}`}</p>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                {/* <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    1. Why am I not receiving my OTP verification messages?
                  </Accordion.Header>
                  <Accordion.Body>
                    Kindly check whether your phone has blocked promotional
                    messages by dialing *456*9*5# then go to option 5 to
                    activate all.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    2. Why am I being charged to register in this platform?
                  </Accordion.Header>
                  <Accordion.Body>
                    For Administration purposes which includes maintaining
                    technical support team, Data storage, Data protection and to
                    ensure that only serious subscribers interact with the data
                    in our platform to avoid data misuse.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    3. Who are the Red flagged employees?
                  </Accordion.Header>
                  <Accordion.Body>
                    Employees who display disruptive behavior, harassment, or
                    conflicts with others at the workplace. Employees who are
                    involved in unethical or illegal activities, such as fraud,
                    theft, or other misconduct, may be perceived as red flags.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    4. Why should I register my employee (s) in this platform?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      To deter your employees from committing or abating a crime
                      and running away because they know this information will
                      be available to other employers and he/she may never find
                      another job.
                    </p>
                    <p>
                      When employees know that their current performance is
                      being tracked, recorded and rated, they tend to be more
                      responsible and accountable in their work place to the
                      benefit of their employer and to their own career growth.
                    </p>
                    <p>
                      {" "}
                      The easiest way to stop crimes at Homes and Offices is by
                      sharing employee's data among employers so that those with
                      criminal records can be avoided at all times. This is
                      important especially today when referees can no longer be
                      trusted by employers to give credible information about
                      those jobseekers and forging of documents is in another
                      level all thanks to the current technological advancement.
                    </p>

                    <p>
                      NB: Crimes thrives in darkness or and in ignorance. By
                      posting and exposing the past record of children
                      molesters, kidnappers, children’s abusers, thieves and
                      those who abate crimes or even irresponsible and careless
                      workers, we are able to stop the spread of crimes from one
                      home or office to the next.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    5. Isn't Kes.200 too much?
                  </Accordion.Header>
                  <Accordion.Body>
                    Think about the value of your dear child or children, the
                    value of the properties you leave under the care of a{" "}
                    <b>total stranger</b> called House girl, Gardener and or
                    Security guard. Today they steal from you and run away, you
                    report them to the police BUT no one else get to know about
                    their character. Tomorrow they are hired in another house as
                    maids or guards and again they steal and run or assist
                    robbers to steal from their employers when they are still
                    working there or after they have resigned. It may surprise
                    you to know that a huge number of Maids today are criminals
                    or members of criminal gangs ready to take up house jobs for
                    as little as kes3000 per month but their main motivation is
                    not the wage they receive from you, but to survey your
                    household and finally run away with your valuables or open
                    door to their fellow gang members to rob you. Tracing,
                    tracking and exposing such individuals will not just help in
                    making them unemployable but will also aid investigative
                    agencies to apprehend them with ease and with reliable
                    traceable evidence.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    6. How often should I pay this subscription fee?
                  </Accordion.Header>
                  <Accordion.Body>It’s a one-off payment.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    7. How do I verify a prospective Employee before I hire
                    him/her?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                    For you to be able to verify a prospective employee, you
                    need to kindly sign-up as an Employer first, login then go
                    ahead and search for his or her records using his/her ID
                    number. 
                    </p>
                    <p>
                    If initially registered, you will be able to go
                    through various comments/reviews and ratings from his/her
                    previous employer(s) and then make an informed decision on
                    whether to hire or not to hire the person in question.
                    </p>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="contact-section py-5" id="memes">
        <Container>
          <div className="text-center pb-3">
            <h3 className="display-6 heading-h3">
              Contact Us{" "}
              <span className="display-6 heading-h3-green"> Here</span>
            </h3>
          </div>
          <div className="text-center pb-3">
            For trusted maid verification, reach out to us at{" "}
            <b>+254 788 248583</b>. Your peace of mind starts with a simple call.
          </div>
          <Row>
            <Col sm={6}>
              <div>
                <img className="img-fluid" src={Imgcontact} />
              </div>
            </Col>
            <Col sm={6}>
              <Form className="pt-5">
                <Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        placeholder="Please enter your name"
                        className="mb-3"
                        value={Name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setError({ ...Error, Name: "" });
                        }}
                      />
                    </Form.Group>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Name}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      placeholder="Please enter your Email"
                      className="mb-3"
                      value={Email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError({ ...Error, Email: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Email}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Contact No:</Form.Label>
                      <Form.Control
                        placeholder="Contact No."
                        className="mb-3"
                        value={Contact}
                        onChange={(e) => {
                          setContact(e.target.value);
                          setError({ ...Error, Contact: "" });
                        }}
                      />
                    </Form.Group>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Contact}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Write Your Message here"
                        rows={3}
                        value={Message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setError({ ...Error, Message: "" });
                        }}
                      />
                    </Form.Group>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Message}
                    </div>
                  </Col>
                </Row>
                <div className="row">
                  <Col sm={6}>
                    <button
                      className="login-btn  animate__animated animate__jello"
                      type="submit"
                      onClick={contactUs}
                    >
                      Send Message
                    </button>
                  </Col>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="">
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;

import React, { useState, useEffect } from "react";
import Topbar from "../Home/Topbar";
import Loader from "../Loader/Loader";
import Footer from "../Home/Footer";
import { getEmployee } from "../Api/AdminApi";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { Pagination } from "@mui/material";
import { IoPersonCircle } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { MdPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { PiBagFill } from "react-icons/pi";
import { BsFillPersonVcardFill } from "react-icons/bs";
const imagePath = "https://api.knowyourmaid.com/";

const AllEmployee = () => {
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [Employeedata, setEmployeedata] = useState([]);
  const itemsPerPage = 9; // Change this number based on your desired items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getEmployeeList();
  }, []);

  const getEmployeeList = () => {
    getEmployee("")
      .then((res) => {
        const data = res.data.results;
        setData(data);
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openInfo = (list) => {
    handleShow();
    setEmployeedata(list);
  };

  return (
    <>
      <Topbar />
      {Loading && <Loader />}
      {/* {data &&
              data.map((list,key) => ( */}
      {/* <Container className="py-5">
        <h3>Employee list</h3>
        {currentItems &&
          currentItems.map((list, index) => (
            <div className="row">
              <div className="col-md-12">
                <div className="row red-flag-box">
                  <div className="col-md-4 col-sm-12">
                    <div>
                      <img
                        src={imagePath + list.passportSize_img}
                        className="img-fluid"
                      />
                    </div>
                  </div>

                  <div className="col-md-8 col-sm-12">
                    <div>
                      <p className="emp-name">{list.full_name}</p>

                      <label>Email :</label>
                      <p>{list.email}</p>
                      <label>Address :</label>
                      <p>{list.address_1}</p>
                      <label>Experience :</label>
                      <p>{list.experience_in_years} Year</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </Container> */}

      <Container className="red-flag-container">
        <div className="text-center py-4">
          <h3 className="display-6 heading-h3">
            Employee <span className="display-6 heading-h3-green">List</span>
          </h3>
        </div>

        <Row className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3 d-flex justify-content-center">
          {currentItems &&
            currentItems.map((list, index) => (
              <Col className="mb-3">
                <div
                  style={{ cursor: "pointer" }}
                  title="Click to Watch Employee Information"
                  onClick={() => openInfo(list)}
                >
                  <img
                    src={imagePath + list.passportSize_img}
                    width="100%"
                    height="200"
                  />
                  <div className="pt-2">
                    <p>User Name here</p>
                  </div>
                </div>
              </Col>
            ))}

          {/* <Link to="/Employee_Video_List"> <button type="button" className="btn btn-primary">
              View More
            </button>
            </Link> */}
        </Row>

        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />

        <div>
          <div className="row d-flex justify-content-center pt-5">
            <div className="col-md-10 mb-4">
              <div className=" emp-list-grid">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <img
                    src="https://cdn-icons-png.flaticon.com/256/149/149071.png"
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div>
                    <p>
                      <span>
                        <IoPersonCircle fontSize={25} color="grey" />
                      </span>{" "}
                      Name: {Employeedata.full_name}
                    </p>
                    <p>
                      <span>
                        <IoMdMail fontSize={23} color="grey" />
                      </span>{" "}
                      Email: {Employeedata.email}
                    </p>
                    <p>
                      <span>
                        <MdPhone fontSize={23} color="grey" />
                      </span>{" "}
                      Contact: {Employeedata.contact_number}
                    </p>
                    <p>
                      <span>
                        <IoLocationSharp fontSize={23} color="grey" />
                      </span>{" "}
                      Address: {Employeedata.address_1}
                     
                    </p>
                    <p>
                      <span>
                        <PiBagFill fontSize={23} color="grey" />
                      </span>{" "}
                      Experience in Year: {Employeedata.experience_in_years}
                    </p>
                    <p>
                      <span>
                        <BsFillPersonVcardFill fontSize={23} color="grey" />
                      </span>{" "}
                      Id Number: {Employeedata.id_number}
                    </p>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-10">
              <div className=" emp-list-grid">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <img
                    src="https://cdn-icons-png.flaticon.com/256/149/149071.png"
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div>
                    <p>
                      <span>
                        <IoPersonCircle fontSize={25} color="grey" />
                      </span>{" "}
                      Name: {Employeedata.full_name}
                    </p>
                    <p>
                      <span>
                        <IoMdMail fontSize={23} color="grey" />
                      </span>{" "}
                      Email: {Employeedata.email}
                    </p>
                    <p>
                      <span>
                        <MdPhone fontSize={23} color="grey" />
                      </span>{" "}
                      Contact: {Employeedata.contact_number}
                    </p>
                    <p>
                      <span>
                        <IoLocationSharp fontSize={23} color="grey" />
                      </span>{" "}
                      Address: {Employeedata.address_1}
                    </p>
                    <p>
                      <span>
                        <PiBagFill fontSize={23} color="grey" />
                      </span>{" "}
                      Experience in Year: {Employeedata.experience_in_years}
                    </p>
                    <p>
                      <span>
                        <BsFillPersonVcardFill fontSize={23} color="grey" />
                      </span>{" "}
                      Id Number: {Employeedata.id_number}
                    </p>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {/* ))} */}
      <Footer />

      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Employee Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <span>
              <IoPersonCircle fontSize={25} color="grey" />
            </span>{" "}
            Name: {Employeedata.full_name}
          </p>
          <p>
            <span>
              <IoMdMail fontSize={23} color="grey" />
            </span>{" "}
            Email: {Employeedata.email}
          </p>
          <p>
            <span>
              <MdPhone fontSize={23} color="grey" />
            </span>{" "}
            Contact: {Employeedata.contact_number}
          </p>
          <p>
            <span>
              <IoLocationSharp fontSize={23} color="grey" />
            </span>{" "}
            Address: {Employeedata.address_1}
          </p>
          <p>
            <span>
              <PiBagFill fontSize={23} color="grey" />
            </span>{" "}
            Experience in Year: {Employeedata.experience_in_years}
          </p>
          <p>
            <span>
              <BsFillPersonVcardFill fontSize={23} color="grey" />
            </span>{" "}
            Id Number: {Employeedata.id_number}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* You can add additional buttons here if needed */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllEmployee;

import React, { useState, useEffect } from "react";
import Employer from "../../assets/images/sign-up.jpg";
import Topbar from "../Home/Topbar";
import axios from "axios";
import Swal from "sweetalert2";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { Token } from "@mui/icons-material";
import { Modal, Form, Table } from "react-bootstrap";
import Auth from "../ServiceApi/Auth";
import { Container, Row, Col } from "react-bootstrap";

const Register = () => {
  const role = Auth.getRole();

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [userType, setUserType] = useState("Employer");
  const [UserTypeLabel, setUserTypeLabel] = useState("Employer");
  const [Phone, setPhone] = useState("");
  const [otpPhone, setOtpPhone] = useState("");
  const [Location, setLocation] = useState("");
  const [Loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [show, setShow] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [rolenew, setRoleNew] = useState("");
  const [countdown, setCountdown] = useState(60);
  const [resendAttempts, setResendAttempts] = useState(0);
  const navigate = useNavigate();
  const [Error, setError] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setError({ ...Error, isChecked: "" });
  };
  useEffect(() => {
    // const interval = setInterval(() => {
    //   setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
    // }, 1000);

    const interval = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;
    let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let contactregex = /^[0-9]+$/i;

    if (Name === "") {
      isValid = false;
      Error["Name"] = "Please Enter User name";
    }

    if (isChecked === false) {
      isValid = false;
      Error["isChecked"] = "Please Accept Terms and Conditions";
    }
    // if (Email === "") {
    //   isValid = false;
    //   Error["Email"] = "Please Enter Email";
    // } else if (emailregex.test(Email) === false) {
    //   isValid = false;
    //   Error["Email"] = "Please Enter Valid Email";
    // }
    if (userType === "") {
      isValid = false;
      Error["userType"] = "Please Select User Type";
    }
    if (Password === "") {
      isValid = false;
      Error["Password"] = "Please Enter Password";
    } else if (Password.length < 8) {
      isValid = false;
      Error["Password"] = "Password length should be minimum 8";
    }
    if (ConfirmPassword === "") {
      isValid = false;
      Error["ConfirmPassword"] = "Please Enter Confirm Password";
    } else if (Password !== ConfirmPassword) {
      isValid = false;
      Error["ConfirmPassword"] = "Password and Confirm Password not match";
    }
    if (Phone === "") {
      isValid = false;
      Error["Phone"] = "Please Enter Phone";
    } else if (contactregex.test(Phone) === false) {
      isValid = false;
      Error["Phone"] = "Please Enter Valid Contact No.";
    }
    if (Location === "") {
      isValid = false;
      Error["Location"] = "Please Enter Location";
    }
    setError(Error);
    return isValid;
  };
  const opneModal2 = () => {
    setShow(true);
  };

  const ValidateOtpForm = () => {
    let Error = {};
    let isValid = true;
    if (showOtp === true) {
      if (otp === "") {
        isValid = false;
        Error["otp"] = "Please Enter OTP";
      }
    }
    setError(Error);
    return isValid;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleUserType = (value, label) => {
    setUserType(value);
    setUserTypeLabel(label);
    setError({ ...Error, userType: "" });
    // const { value, label } = e.target.options[e.target.selectedIndex];
    // setUserType(value);
    // setUserTypeLabel(label);
    // setError({ ...Error, userType: "" });
  };

  const RegisterForm = (e) => {
    e.preventDefault();
    setLoading(true);

    if (ValidateForm()) {
      const body = {
        full_name: Name,
        address: Location,
        contact_number: Phone,
        email: Email,
        user_type: UserTypeLabel,
        password: Password,
        password_confirmation: ConfirmPassword,
        Client_ID: 1,
        Client_secret: "Pmi5L9gBGhgIhQyyAMU8c1GAw7d0TKaDv2lDd6an",
        // Client_ID: 4,
        // Client_secret: "7E2RxVGOmHZCPnLXSaFDEdhyRQ64o8xEjEceLMod"
      };
      setOtpPhone(body.contact_number);

      axios
        .post("https://api.knowyourmaid.com/api/register", body)
        // axios.post("http://localhost:8000/api/register",body)
        .then((res) => {
          if (res.status === 200) {
            const Userdetails = {
              full_name: res.data.user.full_name,
              address: res.data.user.address,
              contact_number: res.data.user.contact_number,
              email: res.data.user.email,
              role: res.data.role,
              Token: res.data.token,
            };
            setRoleNew(Userdetails.role);
            const loginInfo = JSON.stringify(Userdetails);
            localStorage.setItem("UserDetails", loginInfo);
            if (Userdetails.role == 3) {
              Swal.fire({
                icon: "success",
                title:
                  "Thanks for registering with Know your maid. Kindly enter the OTP received on your mobile to verify your account.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              setCountdown(60);
              setShowOtp(true);
            } else {
              Swal.fire({
                icon: "success",
                title:
                  "Thanks for registering with Know your maid. Kindly enter the OTP received on your mobile to verify your account. Please make the payment as instructed to complete the registration.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              setCountdown(60);
              setShowOtp(true);
            }
            // if (res.data.user.user_type === "Employer" || res.data.user.user_type === "Visitor") {
            //   navigate("/Payment");
            // } else {
            //   navigate("/login");
            // }
          }
          resetField();
          // navigate("/Login");
        })
        .catch((err) => {
          console.log(err);
          // Handle network errors or other issues.
          Swal.fire({
            icon: "error",
            title: err.response.data.errors.contact_number,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const resetField = () => {
    setName("");
    setEmail("");
    setLocation("");
    setUserType("Employer");
    setPassword("");
    setConfirmPassword("");
    setPhone("");
  };

  // otp verification
  const handleClose = () => setShowOtp(false);
  const handleClose2 = () => setShow(false);

  // const handleResendOtp = (e) => {
  //   e.preventDefault();
  //   debugger;
  //   const body = { contact_number: otpPhone };
  //   axios
  //     .post(`https://api.knowyourmaid.com/api/resend-otp`, body)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         Swal.fire({
  //           icon: "success",
  //           title: "OTP sent successfully on your Contact Number.",
  //           showConfirmButton: true,
  //           confirmButtonColor: "#3085d6",
  //           confirmButtonText: "OK",
  //         });
  //         setSendOtp(true);
  //         setCountdown(30);
  //       } else {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "Failed to Send Otp",
  //           showConfirmButton: true,
  //           confirmButtonColor: "#3085d6",
  //           confirmButtonText: "OK",
  //         });
  //         setSendOtp(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setSendOtp(false);
  //     });
  // };

  const handleResendOtp = (e) => {
    e.preventDefault();
    debugger;

    if (resendAttempts < 3) {
      const body = { contact_number: otpPhone };

      axios
        .post(`https://api.knowyourmaid.com/api/resend-otp`, body)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "OTP sent successfully on your Contact Number.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            setSendOtp(true);
            setCountdown(60);
          } else {
            Swal.fire({
              icon: "warning",
              title: "Failed to Send Otp",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            setSendOtp(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSendOtp(false);
        });

      setResendAttempts((prevAttempts) => prevAttempts + 1);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Maximum resend attempts reached. Please try again later.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (ValidateOtpForm()) {
      const body = {
        contact_number: otpPhone,
        otp: otp,
      };
      axios
        .post(`https://api.knowyourmaid.com/api/verify-otp`, body)
        .then((res) => {
          if (res.status === 200) {
            if (rolenew && rolenew === 3) {
              Swal.fire({
                icon: "success",
                title: "OTP Verified Successfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              setSendOtp(false);
              setShowOtp(false);
              setRoleNew("");
              navigate("/login");
              setCountdown(60);
              setOtpPhone("");
            } else {
              Swal.fire({
                icon: "success",
                title: "OTP Verified Successfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              setSendOtp(false);
              setShowOtp(false);
              setRoleNew("");
              navigate("/Payment", { state: { userType: userType,  contact_number: otpPhone, } });
              setCountdown(60);
              setOtpPhone("");
            }
          } else if (res.status === 401) {
            Swal.fire({
              icon: "error",
              title: "Please enter a valid OTP.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Please enter a valid OTP.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              title: err.response.data.error,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          } else if (err.response.status === 422) {
            Swal.fire({
              icon: "error",
              title: err.response.data.error.otp,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        });
    }
  };

  return (
    <>
      <Topbar />
      {Loading && <Loader />}

      <div class="container mt-3">
        <div class="row justify-content-center">
          <div className="col-sm-6">
            <div className="py-5 text-center">
              <img src={Employer} alt="Employer" className="img-fluid" />
            </div>
          </div>
          <div class="col-md-6">
            <div className="pt-3">
              <div className="text-center">
                <h3 className="display-6 heading-h3">
                  Register
                  <span className="display-6 heading-h3-green"> Here !</span>
                </h3>
              </div>

              <h6 className="text-start">Note:</h6>
              <div>
                <Table bordered responsive="sm">
                  <tbody>
                    <tr>
                      <td>Register as Employer? </td>
                      <td>pay Ksh 2400 Anually</td>
                    </tr>
                    {/* <tr>
                      <td> Register as Visitor?</td>
                      <td>pay only Ksh30</td>
                      
                    </tr> */}
                    <tr>
                      <td colSpan={2}>Was <del>Kes2400</del> Now Kes1200 for the first 1000 Employers to subscribe.</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="py-3">
                <div class="card form-border">
                  <div class="card-body">
                    <form className="row">
                      <div className="form-group mb-3 col-sm-12">
                        <label for="email" class="form-label">
                          Register as an?{" "}
                          <span
                            className="required"
                            style={{ color: "red", fontSize: "1rem" }}
                          >
                            *
                          </span>
                        </label>
                        <div>
                          <input
                            type="radio"
                            id="employer"
                            name="userType"
                            value="Employer"
                            checked={userType === "Employer"}
                            onChange={() =>
                              handleUserType("Employer", "Employer")
                            }
                          />
                          <label htmlFor="employer">Employer</label>

                          <input
                            style={{ marginLeft: "5rem" }}
                            type="radio"
                            id="employee"
                            name="userType"
                            value="Employee"
                            checked={userType === "Employee"}
                            onChange={() =>
                              handleUserType("Employee", "Employee")
                            }
                          />
                          <label htmlFor="employee" className="ml-1">
                            Employee
                          </label>
                        </div>
                        {/* <select
                          id="experience"
                          name="experience"
                          className="form-control"
                          value={userType}
                          onChange={handleUserType}
                        > */}
                        {/* <option value="" disabled>Select experience</option> */}
                        {/* <option value="" disabled>
                            Select user type
                          </option>
                          <option value="0" label="Employer">
                            Employer
                          </option>
                          <option value="1" label="Employee">
                            Employee
                          </option> */}
                        {/* <option value="2" label="Visitor">
                            Visitor
                          </option> */}

                        {/* Add more options as needed */}
                        {/* </select> */}
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.userType}
                        </div>
                      </div>
                      <div class="form-group mb-3 col-sm-12">
                        <label for="username" class="form-label">
                          Full Name{" "}
                          <span
                            className="required"
                            style={{ color: "red", fontSize: "1rem" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="username"
                          value={Name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setError({ ...Error, Name: "" });
                          }}
                          name="username"
                          // required
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.Name}
                        </div>
                      </div>

                      <div class="form-group mb-3 col-sm-6">
                        <label for="email" class="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          value={Email}
                          // onChange={(e) => setEmail(e.target.value)}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setError({ ...Error, Email: "" });
                          }}
                          name="email"
                          // required
                        />
                        {/* <div style={{ color: "red", fontSize: 13 }}>
                          {Error.Email}
                        </div> */}
                      </div>
                      {/* <div class="mb-3">
                        <label for="userType" class="form-label">
                          User Type
                        </label>
                        <select
                          class="form-select"
                          id="userType"
                          value={userType}
                          onChange={(e) => {
                            setUserType(e.target.value);
                            setError({ ...Error, userType: "" });
                          }}
                          name="userType"
                        >
                          <option value="" disabled>
                            Select user type
                          </option>
                          <option value="Employee">Employee</option>
                          <option value="Employers">Employers</option>
                        </select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.userType}
                        </div>
                      </div> */}
                      <div class="form-group mb-3 col-md-6">
                        <label for="email" class="form-label">
                          Phone
                          {/* <p> (Note: please enter your M-pesa registered Number)</p> */}
                          <span
                            className="required"
                            style={{ color: "red", fontSize: "1rem" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="email"
                          // placeholder="M-pesa registered Number"
                          value={Phone}
                          // onChange={(e) => setEmail(e.target.value)}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setError({ ...Error, Phone: "" });
                          }}
                          name="phone"
                          // required
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.Phone}
                        </div>
                      </div>
                      <div class="form-group mb-3 col-sm-12">
                        <label for="email" class="form-label">
                          Location{" "}
                          <span
                            className="required"
                            style={{ color: "red", fontSize: "1rem" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="location"
                          value={Location}
                          // onChange={(e) => setEmail(e.target.value)}
                          onChange={(e) => {
                            setLocation(e.target.value);
                            setError({ ...Error, Location: "" });
                          }}
                          name="location"
                          // required
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.Location}
                        </div>
                      </div>

                      <div class="form-group mb-3 col-sm-6">
                        <label for="password" class="form-label">
                          Password{" "}
                          <span
                            className="required"
                            style={{ color: "red", fontSize: "1rem" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="password"
                            name="password"
                            value={Password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setError({ ...Error, Password: "" });
                            }}
                          />
                          <span
                            className="input-group-text"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FiEye /> : <FiEyeOff />}
                          </span>
                        </div>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.Password}
                        </div>
                      </div>

                      <div class="form-group mb-3 col-sm-6">
                        <label for="confirmPassword" class="form-label">
                          Confirm Password{" "}
                          <span
                            className="required"
                            style={{ color: "red", fontSize: "1rem" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="input-group">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            class="form-control"
                            id="confirmPassword"
                            value={ConfirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              setError({ ...Error, ConfirmPassword: "" });
                            }}
                            name="confirmPassword"
                            // required
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {showConfirmPassword ? <FiEye /> : <FiEyeOff />}
                          </span>
                        </div>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.ConfirmPassword}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        {/* <div className="">
                          <p className="login-links">
                            <a href="/Login">Already have Account?</a>
                          </p>
                        </div> */}
                        <div>
                          <p class="form-label">
                            Already have an account ?{" "}
                            <a className="login-links" href="/Login">
                              Login Here!
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="mb-4">
                        <Form.Check
                          onChange={handleCheckboxChange}
                          checked={isChecked}
                          type="checkbox"
                          label="I agree to"
                        />
                        <a className="login-links" onClick={opneModal2}>
                          Terms and Conditions
                        </a>
                      </div>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {Error.isChecked}
                      </div>

                      <button
                        type="submit"
                        onClick={RegisterForm}
                        class="login-btn"
                      >
                        REGISTER
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showOtp}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-center">
              <h3 className="display-7 heading-h3">
                OTP Verification
                {/* <span className="display-7 heading-h3-green"></span> */}
              </h3>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="container mt-4">
            <div className="row d-flex justify-content-center pt-6">
              <div className="col-sm-12">
                <div className="card form-border">
                  <div className="card-body">
                    <>
                      <div>
                        <label htmlFor="userImage" className="form-label">
                          Please enter OTP sent on your Mobile No
                        </label>
                        <input
                          type="number"
                          name="otp"
                          id="otp"
                          value={otp}
                          className="form-control"
                          onChange={(e) => {
                            setOtp(e.target.value);
                            setError({ ...Error, otp: "" });
                          }}
                        />
                        <br />
                        <p>
                          Resend OTP in{" "}
                          <span style={{ color: "red" }}>
                            {countdown} seconds
                          </span>
                        </p>
                      </div>

                      <br />
                      <div className="row d-flex justify-content-center">
                        {countdown > 0 ? (
                          <button
                            type="submit"
                            class="btn btn-primary col-sm-5"
                            onClick={handleVerifyOtp}
                          >
                            Verify
                          </button>
                        ) : (
                          <button
                            type="submit"
                            class="btn btn-primary col-sm-5"
                            //  disabled={resendAttempts >= 3}
                            onClick={handleResendOtp}
                          >
                            Resend OTP
                          </button>
                        )}
                      </div>
                    </>

                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.otp}
                    </div>
                    <br />
                    {/* <div className="row d-flex justify-content-center">
                    
                       <button
                       type="submit"
                       class="btn btn-primary col-sm-5"
                       onClick={handleVerifyOtp}
                     >
                       Verify
                     </button>
                    
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose2}
        dialogClassName="modal-dialog-centered"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-center">
              <h3 className="display-7 heading-h3">
                Privacy Policy
                {/* <span className="display-7 heading-h3-green"></span> */}
              </h3>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto" }}>
          <div className="privacy-policy-page">
            <Container>
              <br />
              <div className="privacy-container py-3">
                {/* <div className="text-center py-5">
          <h2>Privacy Policy</h2>
        </div> */}
                <Row className="pb-5">
                  <Col>
                    <div className="what-privacy">
                      <ul>
                        <li>
                          <p>Explore the Policy</p>
                        </li>
                        <li>
                          <p>
                            What is the privacy policy and what does it cover?
                          </p>
                        </li>
                        <li>
                          <p>What information do we collect?</p>
                        </li>
                        <li>
                          <p>How do we use the information that we collect?</p>
                        </li>
                        <li>
                          <p>How do we share information with third parties?</p>
                        </li>
                        <li>
                          <p>How long do we keep your information for?</p>
                        </li>
                        <li>
                          <p>
                            How do we respond to legal requests, comply with
                            applicable and prevent harm/data misuse?
                          </p>
                        </li>
                        <li>
                          <p>How will you know the policy has changed?</p>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="privacy-que-box">
                      <p className="prvcy-que">
                        1. What is the privacy policy and what does it cover?
                      </p>
                      <p>
                        This Privacy Policy explains how we collect, use and
                        share your information. It also describes how long we
                        keep your information for and how we keep it safe when
                        we share with legal authorities when requested according
                        to applicable law and how we let you know when we change
                        the existing policy.
                      </p>
                    </div>
                    <div className="privacy-que-box">
                      <p className="prvcy-que">
                        2. What information do we collect?
                      </p>
                      <p>
                        The information that you give us when you sign up for
                        our Products and create a profile, such as your names,
                        your location, email address or phone number.
                      </p>
                      <p>
                        Your employee’s information that you write and upload in
                        your account. Your employee’s profile information which
                        includes their names, their location, their ID
                        information and their photos.
                      </p>
                    </div>
                    <div className="privacy-que-box">
                      <p className="prvcy-que">
                        3. How do we use the information we collect?
                      </p>
                      <p>
                        To identify and verify the owner of the account for
                        accountability.
                      </p>
                      <p>
                        To help other users especially employers who may be in
                        search of data or information relating to a specific
                        person(s) they wish to hire or refer for employment or
                        even red flag to warn other unsuspecting employers from
                        hiring potentially harm individuals in their homes or
                        companies.
                      </p>
                      <p>
                        To help employers easily retrieve the information they
                        have recorded about their employees over time when need
                        arise to either promote them, refer them or even report
                        them to the legal authorities when they have committed
                        crimes and possibly run away.
                      </p>
                      <p>
                        To help employers trace and track the behavior pattern
                        of their employees past, present and future. And by so
                        doing, build a rich employee’s database that other
                        employers can use to understand their potential workers
                        character, capabilities and limitations before hiring
                        them.
                      </p>
                    </div>
                    <div className="privacy-que-box">
                      <p className="prvcy-que">
                        4. How do we share information with third parties?
                      </p>
                      <p>We share certain information with:</p>

                      <ul>
                        <li>
                          <p>Advertisers who show ads on our Products</p>
                        </li>
                        <li>
                          <p>
                            Businesses we hire to market our Products for us
                          </p>
                        </li>
                        <li>
                          <p>
                            Businesses we hire to do things such as offer
                            customer service or conduct surveys
                          </p>
                        </li>
                        <li>
                          <p>
                            Researchers who use it to do things such as
                            innovate, advance technology or improve people's
                            safety
                          </p>
                        </li>
                        <li>
                          <p>
                            We may share also with relevant legal authorities as
                            applicable laws allow upon requests such as court
                            orders, search warrants or subpoenas.
                          </p>
                        </li>
                        <li>
                          <p>
                            We don't sell your information and we never will.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="privacy-que-box">
                      <p className="prvcy-que">
                        5. How long do we keep your information?
                      </p>
                      <p>
                        We keep information for as long as we need it to provide
                        reference for those searching.
                      </p>
                      <p>But you can request that we delete your information</p>
                      <p>
                        We'll delete that information unless we have to keep it
                        for something else, for instance for legal reasons
                      </p>
                    </div>
                    <div className="privacy-que-box">
                      <p className="prvcy-que">
                        6. How do we respond to legal requests, comply with
                        applicable and prevent harm/data misuse?
                      </p>
                      <p>
                        Sometimes we have to provide information to respond to
                        legal requests when necessary
                      </p>
                      <p>
                        If there's a law that says we need to keep your
                        information for some reason, we need to comply with it.
                        So, we'll keep information to obey that law. Examples
                        are, court orders, search warrants and subpoenas.
                      </p>
                      <p>
                        We also sometimes keep information that we think will
                        help prevent or stop bad activity, either on our
                        Platform or in the society.
                      </p>
                    </div>
                    <div className="privacy-que-box">
                      <p className="prvcy-que">
                        7. How will you know the policy has changed?
                      </p>
                      <p>
                        We'll notify you before we make material changes to this
                        Policy. You'll have the opportunity to review the
                        revised Policy before you choose to continue using our
                        platform.
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className="text-center py-5">
                  <h2>Terms of Service</h2>
                </div>
                <Row className="pt-4">
                  <Col>
                    <div>
                      <p>
                        Maids Trace and Track Africa has built a platform
                        (knowyourmaid.com) that enables employers to trace and
                        track their employees. We do this by encouraging
                        employers to subscribe on our platform for a small
                        maintenance fee (the amount can be reviewed from time to
                        time as need arise) and thereafter utilize our platform
                        to keep records of theiremployees’ behavior, character
                        and happenings that take place throughout their working
                        period in the company or in homes/houses. These records
                        form a rich database for other future employers to refer
                        to before hiring such employees. Evey employer always
                        want to know as much as possible about potential
                        employees before hiring them but it’s normally not
                        possible because workers only tell what they want their
                        prospective employers to know and not what employers
                        ought to know.
                      </p>
                      <p>
                        We have provided this platform so that employers can
                        share experiences with each other concerning various
                        employees whether good or bad so that future employers
                        can make decisions to hire or not to hire them from a
                        point of information and not otherwise.
                      </p>
                      <p>
                        As you may be aware that majority of crimes committed
                        today in companies and homes are planned by workers
                        (inside job) in those companies and homes. This is even
                        worse when it comes to employing of Maids/House-helps in
                        our homes to take care of our most valuable items in our
                        houses and our dear children/families and we have no
                        idea who our Maids are. It is now in public domain that,
                        a good number of Maids today are closely working with
                        robbers, kidnappers and thieves and their main role is
                        to seek housemaid jobs in the target homes and then plan
                        the robbery from within. Such workers are ready to
                        receive any amount of wage no matter how little just to
                        gain access to your home. After a short while they will
                        either steal from the employer and run away or they
                        orchestrate robbery, kidnapping or theft while still
                        working there or soon after they leave. Some will invite
                        strangers into your house when you are out for work or
                        even feed your child with sleeping pills to keep them
                        sleeping all day as they do their dirty stuff in and out
                        of the house.
                      </p>
                      <p>
                        Unfortunately, without information sharing among
                        employers, such employees end up being hired by another
                        target unsuspecting employer and the cycle of stealing
                        and run away continues unabated. No wonder the turnover
                        rate especially among Housemaids is very high these
                        days. Sharing information among employers is empowering
                        each other and breaking the cycle of crimes and
                        criminalism perpetrated by workers who hop from one
                        house or company to another.
                      </p>
                      <br />
                      <p className="prvcy-que">
                        Who can use knowyourmaid.com platform.
                      </p>
                      <p>
                        When people stand behind their opinions and actions, our
                        community is safer and more accountable. For this
                        reason, you must:
                      </p>
                      <p>
                        Provide for your account the same name that you use in
                        everyday life and avoid impersonation or fake account.
                        (This is one of the reasons we charge for membership to
                        keep away jokers.)
                      </p>
                      <p>
                        Provide accurate information about yourself and your
                        employee at all times.
                      </p>
                      <p>
                        Only create one account (your own and specify whether
                        you are an employer or visitor) and use it for personal
                        purposes to record information that pertains to you
                        employees. Visitors cannot register employee, they can
                        only search for employees information if they are in
                        possession of such employees ID number (search is only
                        done using employees ID number) and they can also write
                        a comment against what has already been reported by
                        employer(s).
                      </p>
                      <p>
                        Do not share your password, give access to your
                        knowyourmaid.com account to others or transfer your
                        account to anyone else (without our permission).
                      </p>
                      <p>
                        We encourage all employers to record and share their
                        employees’ information with utmost accuracy and
                        transparency. Mudslinging and defamation are highly
                        discouraged on this platform. In fact, an employer
                        cannot Red Flag a former employee without uploading a
                        verifiable OB report from the police to confirm that the
                        crime allegedly committed by such worker has been
                        reported and is under investigation.
                      </p>
                    </div>
                    <br />
                    <div className="privacy-que-box">
                      <p className="prvcy-que">Limit on liability</p>
                      <p>
                        We work hard to provide the best Products we can and to
                        specify clear guidelines for everyone who uses them. Our
                        Products, however, are provided "As is," and to the
                        extent permissible by law, we make no guarantees that
                        they always will be safe, secure, or error-free, or that
                        they will function without disruptions, delays, or
                        imperfections. To the extent permitted by law, we also
                        disclaim all warranties, whether express or implied,
                        including the implied warranties of merchantability,
                        fitness for a particular purpose, title and
                        non-infringement. We do not control ordirect what people
                        and others do or say, and we are not responsible for
                        their actions or conduct (whether online or offline) or
                        any content that they share (including offensive,
                        inappropriate, obscene, unlawful, incorrect, and other
                        objectionable content).
                      </p>
                      <p>
                        We cannot predict when issues may arise with our
                        Products. Accordingly, our liability shall be limited to
                        the fullest extent permitted by applicable law. To the
                        fullest extent permitted by applicable law, under No
                        circumstance will we be liable to you for any lost
                        profits, revenues, information, or data, or
                        consequential, special, indirect, exemplary, punitive or
                        incidental damages arising out of or related to these
                        Terms or Platform (however caused and on any theory of
                        liability, including negligence), even if we have been
                        advised of the possibility of such damages.
                      </p>
                    </div>
                    <div className="privacy-que-box">
                      <p className="prvcy-que">Updating our Terms</p>
                      <p>
                        We work constantly to improve our services and develop
                        new features to make our Platform better for you and our
                        community. As a result, we may need to update these
                        Terms from time to time to accurately reflect our
                        services and practices, to promote a safe and secure
                        experience on our Products and services, and/or to
                        comply with applicable law. We will only make any
                        changes if the provisions are no longer appropriate or
                        if they are incomplete, and only if the changes are
                        reasonable and take due account of your interests, or if
                        the changes are required for safety and security
                        purposes or to comply with applicable law.
                      </p>
                      <p>
                        We will notify you (for example, by email or through our
                        Products) at least 30 days before we make changes to
                        these Terms and give you an opportunity to review them
                        before they go into effect, unless changes are required
                        by law. Once any updated Terms are in effect, you will
                        be bound by them if you continue to use our Platform.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;

import React, { useState, useEffect } from "react";
import EmployerSidebar from "../Sidebar/EmployerSidebar";
import { Container, Row, Col, Card } from "react-bootstrap";
import SideNav from "../Sidebar/SideNav";
import user from "../../assets/images/user.png";
import business from "../../assets/images/business-woman.png";
import unemployed from "../../assets/images/unemployed.png";
import remove from "../../assets/images/disqualified.png";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import { getEmployerEmployeeData } from "../Api/AdminApi";
import Auth from "../ServiceApi/Auth";
import { Link } from "react-router-dom";

const EmployerDashboard = () => {
  const [data, setData] = useState([]);
  const userid = Auth.getEmployerID();

  useEffect(() => {
    getEmpInfo();
  }, []);

  const getEmpInfo = () => {
    getEmployerEmployeeData(userid)
      // axios.get("http://localhost:8000/api/getEmployeeInfo")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {/* <SideNav/> */}
      <EmployerSidebar />
      <div>
        <Container className="pt-5">
          <div>
            <h1 className="pb-4 dashboard-heading ">Employer's Dashboard</h1>
          </div>
          <Row className="pt-3">
            <Col className="col-lg-3 col-md-6 col-sm-12 col-xs-mb-3">
              <Link
                style={{ textDecoration: "none" }}
                to="/Employer_Employee_List"
                state={{section : "employee" }}
              >
                {/* Your link content */}

                <Card className="dash-tiles">
                  <Card.Body>
                    <div className="tile-img-grid">
                      <img
                        className="img-fluid users-img"
                        src={user}
                        alt="user"
                      />
                    </div>
                    <div className="pt-2 text-center">
                      <p>
                        {" "}
                        Employees{" "}
                        <span style={{ fontWeight: "bold", color: "#1FA2FF" }}>
                          {data.count}
                        </span>{" "}
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            {/* <Col className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card className="dash-tiles1">
                <Card.Body>
                  <div className="tile-img-grid">
                    <img
                      className="img-fluid users-img"
                      src={business}
                      alt="user"
                    />
                  </div>
                  <div className="pt-2 text-end">
                    <p>Total Employed</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card className="dash-tiles2">
                <Card.Body>
                  <div className="tile-img-grid">
                    <img
                      className="img-fluid users-img"
                      src={unemployed}
                      alt="user"
                    />
                  </div>
                  <div className="pt-2 text-end">
                    <p>Red Flagged</p>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            <Col className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Link
                style={{ textDecoration: "none" }}
                // to={"/Employer-redflag-employeeList"}
                to="/Employer_Employee_List"
                state={{section : "redFlag" }}
              >
                <Card className="dash-tiles3">
                  <Card.Body>
                    <div className="tile-img-grid">
                      <img
                        className="img-fluid users-img"
                        src={remove}
                        alt="user"
                      />
                    </div>
                    <div className="pt-2 text-center">
                      <p>
                        {" "}
                        Red Flag Employees{" "}
                        <span style={{ fontWeight: "bold", color: "#eb4d4b" }}>
                          {data.redCount}{" "}
                        </span>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
          {/* <Row className="py-3 gx-5">
            <Col className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div>
                <h1 className="pb-4 dashboard-heading display-7">
                  Top Rated Employees
                </h1>
              </div>
              <div className="listbox">
                <ListGroup as="ol" numbered>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
            <Col className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div>
                <h1 className="pb-4 dashboard-heading display-7">
                  Top Services
                </h1>
              </div>
              <div className="listbox">
                <ListGroup as="ol" numbered>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
};

export default EmployerDashboard;

import React, { useState, useEffect } from "react";
import { Table, ModalBody } from "react-bootstrap";
import { Pagination } from "@mui/material";
import axios from "../ServiceApi/AxiosInstance";
import EmployerSidebar from "../Sidebar/EmployerSidebar";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import {
  getContactUs,
  DeleteEmployee,
  AddRedFlag,
  EmployeegetByEmployerId,
} from "../../Components/Api/AdminApi";
import IconButton from "@mui/material/IconButton";
import ReportTwoToneIcon from "@mui/icons-material/ReportTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import DangerousIcon from "@mui/icons-material/Dangerous";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import Avatar from "@mui/material/Avatar";
import Auth from "../ServiceApi/Auth";
import SideNav from "../Sidebar/SideNav";
import Loader from "../Loader/Loader";
import "bootstrap/dist/css/bootstrap.min.css"; //

const ContactUsList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Error, setError] = useState("");
  const [isActive, setIsActive] = useState("");
  const [description, setDescription] = useState("");
  const [employeeID, setEmployeeID] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [Loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);


  const itemsPerPage = 10; // Change this number based on your desired items per page
  const imagePath = "https://api.knowyourmaid.com/";
  const auth = Auth.getUserId();
  const role = Auth.getRole();
  const Employer = Auth.getEmployerID();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleToggleChange = (event) => {
    setIsActive(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === '%') {
      e.preventDefault();
    }
  };
  
  useEffect(() => {
    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let details = data.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(details);
  }, [currentPage]);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getContactUsList();
  }, [searchValue]);

  const openModal = (list) => {
    handleShow();
    setEmployeeID(list.id);
  };
  const submit = (e) => {
    e.preventDefault();
    const data = {
      red_flag: isActive,
      redFlag_desc: description,
    };
    AddRedFlag(data, employeeID)
      // axios
      //   .post(`http://localhost:8000/api/redFlag/${employeeID}`, data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Red Flag added Successfully",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
        cancelData();
        handleClose();
        getContactUsList();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Red Flag Not Added",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      });
  };

  const getContactUsList = () => {
    setLoading(true);
    getContactUs(searchValue)
      // axios.get(`http://localhost:8000/api/getEmp/${auth}`)
      .then((res) => {
        if (res.status == 200) {
          setData(res.data.results);
          setCurrentPage(1);
          let indexOfLastItem = 1 * itemsPerPage;
          let indexOfFirstItem = indexOfLastItem - itemsPerPage;
          let details = res.data.results.slice(
            indexOfFirstItem,
            indexOfLastItem
          );
          setCurrentItems(details)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //   const deleteEmployee = (id) => {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     })
  //       .then((result) => {
  //         if (result.isConfirmed) {
  //           DeleteEmployee(id)
  //             .then((result) => {
  //               Swal.fire({
  //                 icon: "success",
  //                 title: "Employee Deleted Sucessfully",
  //                 showConfirmButton: true,
  //                 confirmButtonColor: "#3085d6",
  //                 // cancelButtonColor: '#d33',
  //                 confirmButtonText: "ok",
  //               });

  //               getEmployeeList();
  //             })
  //             .catch((error) => {
  //               Swal.fire({
  //                 icon: "error",
  //                 title: "Employee Not Deleted",
  //                 showConfirmButton: true,
  //                 confirmButtonColor: "#3085d6",
  //                 // cancelButtonColor: '#d33',
  //                 confirmButtonText: "ok",
  //               });
  //             });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err, "err in trash menu");
  //       });
  //   };

  const cancelData = () => {
    setDescription("");
    setIsActive("");
  };

  return (
    <>
      {role !== 1 ? <EmployerSidebar /> : <SideNav />}
      {Loading && <Loader />}
      <div className="row mt-5">
        <h3 className="display-7 heading-h3 text-center">
          Contact Us<span className="display-7 heading-h3-green"> List</span>
        </h3>
        <Col md={{ span: 3, offset: 9 }}>
          <Form.Group
            as={Row}
            className="justify-content-center"
            controlId="formHorizontalEmail"
          >
            <Col sm={12} className="mb-4">
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  size="small"
                  placeholder="Search by Name or Email"
                  name="searchValue"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyPress={handleKeyPress}
                  startAdornment={
                    <InputAdornment position="start">
                      <FiSearch />
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Col>
          </Form.Group>
        </Col>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-12 text-center">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact No.</th>
                  <th>Message</th>
                  {/* <th>Action</th> */}

                  {/* <th>Delete</th>                                                      
              <th>Review</th> */}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((list, index) => (
                  <tr key={list.id}>
                    <td>
                      {index + 1 + (currentPage * itemsPerPage - itemsPerPage)}
                    </td>
                    <td>{list.name}</td>
                    <td>{list.email}</td>
                    <td>{list.contact_number}</td>
                    <td>{list.message}</td>

                    {/* <td width="200px" className="text-center">
                            <Link to={`/Edit_Employee/${list.id}`}>
                              <IconButton type="btn btn-primary" title="edit">
                                <EditTwoToneIcon />
                              </IconButton>
                            </Link>
                            <IconButton
                              type="btn btn-primary"
                              onClick={() => deleteEmployee(list.id)}
                              title="delete"
                            >
                              <DeleteTwoToneIcon />
                            </IconButton>
                            <Link
                              to="/Reviews"
                              state={
                                { employeeid: list.id } // Replace yourDataObject with the data you want to pass
                              }
                            >
                              <IconButton type="btn btn-primary" title="Review">
                                <RemoveRedEyeTwoToneIcon />
                              </IconButton>
                            </Link>
                          </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Pagination
              count={Math.ceil(data.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsList;

import React, { useState, useEffect } from "react";
import { Table, ModalBody } from "react-bootstrap";
import axios from "axios";
import { Pagination } from "@mui/material";
import SideNav from "../Sidebar/SideNav";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Modal, Container } from "react-bootstrap";
import {
  getEmployee,
  DeleteEmployee,
  AddRedFlag,
} from "../../Components/Api/AdminApi";
import { Col, Row, Form, Card, Image, Button } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import RateReviewIcon from "@mui/icons-material/RateReview";
import DangerousIcon from "@mui/icons-material/Dangerous";
import ReportTwoToneIcon from "@mui/icons-material/ReportTwoTone";
import Avatar from "@mui/material/Avatar";
import NotFound from "../NotFound/NotFound";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import Loader from "../Loader/Loader";

const EmployeeList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Error, setError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [ApiComplete, SetApiComplete] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [description, setDescription] = useState("");
  const [policeNo, setPoliceNo] = useState("");
  const [doc, setDoc] = useState("");
  const [employeeID, setEmployeeID] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const itemsPerPage = 5; // Change this number based on your desired items per page
  const imagePath = "https://api.knowyourmaid.com/";

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setDescription("");
    setPoliceNo("");
    setDoc("");
    setError("");
  };

  const handleToggleChange = (event) => {
    setIsActive(event.target.value);
    setError({ ...Error, isActive: "" });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    let contactregex = /^(?!0+$)\d+$/i;
    let nameregexx = /^[a-zA-Z-' ]+$/;

    if (description === "") {
      isValid = false;
      Error["description"] = "Please Enter Description";
    } else if (nameregexx.test(description) === false) {
      isValid = false;
      Error["description"] = "Please Enter Valid Description.";
    }
    // if(policeNo === ""){
    //   isValid = false;
    //   Error["policeNo"] = "Please Enter Police Booking Number";
    // }

    // if (isActive === "") {
    //   isValid = false;
    //   Error["isActive"] = "Please select option";
    // }
    if (doc === "") {
      isValid = false;
      Error["doc"] = "Please select File";
    }
    if (doc.length !== 0 && doc !== undefined && doc !== "" && doc !== null) {
      if (doc ? doc.type != "application/pdf" : "") {
        isValid = false;
        Error["doc"] = "Please select PDF file !";
      }
    }

    setError(Error);
    return isValid;
  };

  const isValidInput =
    /^[a-zA-Z0-9]*$/.test(searchValue) && !/%/.test(searchValue);

  const handleKeyPress = (e) => {
    if (e.key === "%") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let details = data.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(details);
  }, [currentPage]);

  const openModal = (list) => {
    handleShow();
    // console.log(list, "got list");
    setEmployeeID(list.id);
    // setIsActive(list.red_flag)
  };

  const submit = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      const data = {
        red_flag: "Active",
        redFlag_desc: description,
        police_case_no: policeNo,
        redFlagDoc: doc,
      };
      AddRedFlag(data, employeeID)
        // axios.post(`http://localhost:8000/api/redFlag/${employeeID}`,data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Red Flag added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          cancelData();
          getEmployeeList();
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          // Handle network errors or other issues.
          Swal.fire({
            icon: "error",
            title: err.response.data.errors.redFlagDoc,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        });
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, [searchValue]);

  const getEmployeeList = () => {
    setLoading(true); // Show loader when the request starts
    getEmployee(searchValue)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setCurrentPage(1);
          let indexOfLastItem = 1 * itemsPerPage;
          let indexOfFirstItem = indexOfLastItem - itemsPerPage;
          let details = res.data.results.slice(
            indexOfFirstItem,
            indexOfLastItem
          );
          setCurrentItems(details);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false); // Hide loader when the request is complete (success or error)
        SetApiComplete(true);
      });
  };

  const deleteEmployee = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteEmployee(id)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Employee Deleted Successfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getEmployeeList();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Employee Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash menu");
      });
  };

  const cancelData = () => {
    setDescription("");
    setPoliceNo("");
    setIsActive("");
    setDoc("");
  };

  return (
    <>
      <SideNav />
      {Loading && <Loader />}

      <Container>
        <div className="row mt-5">
          <h3 className="display-7 py-5 heading-h3 text-center">
            Employee <span className="display-7 heading-h3-green">List</span>
          </h3>

          <Row className="d-flex justify-content-between pt-3">
            <Col sm={3} className="mb-3">
              <Link to={"/Employee"}>
                <div>
                  <button
                    type="submit"
                    class="btn-add-employee"
                    title="Click to add new Employee"
                    // onClick={submitVideo}
                  >
                    Add Employee
                  </button>
                </div>
              </Link>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group
                className="justify-content-center"
                controlId="formHorizontalEmail"
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type="text"
                    size="small"
                    placeholder="Search by Employee Id No"
                    name="searchValue"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    startAdornment={
                      <InputAdornment position="start">
                        <FiSearch />
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div>
          {/* <Switch onChange={(e)=>handleListonToggleButton(e,searchValue)} title="on" checked={showEmployee} defaultChecked/> */}
        </div>
        <div className="py-5">
          <div className="row">
            <div className="col-sm-12 table-responsive text-center">
              <Table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    {/* <th>Contact Person Name</th> */}
                    {/* <th>Contact Person No.</th> */}
                    <th>Employee Id No.</th>
                    <th>Contact</th>
                    <th>Address</th>
                    <th>Experience</th>
                    <th>Service Type</th>
                    <th>Image</th>
                    <th>Red Flag</th>
                    <th width="250px">Action</th>

                    {/* <th>Delete</th>                                                      
        <th>Review</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((list, index) => (
                    <tr key={list.id}>
                      <td>
                        {index +
                          1 +
                          (currentPage * itemsPerPage - itemsPerPage)}
                      </td>
                      <td>{list.full_name}</td>
                      <td>{list.email}</td>
                      {/* <td>{list.contact_person}</td> */}
                      {/* <td>{list.contact_per_num}</td> */}
                      <td>{list.id_number === null ? "-" : list.id_number}</td>
                      <td>{list.contact_number}</td>
                      <td>{list.address_1}</td>
                      <td>
                          {list.experience_in_years === "Fresher" ||
                          list.experience_in_years === null
                            ? "Fresher"
                            : `${list.experience_in_years} Year`}
                        </td>
                      <td>
                        {list.services.length === 0
                          ? "-"
                          : list.services
                              .map((a) => a?.service_name)
                              .filter(Boolean) // Filter out null or undefined values
                              .join(", ")}
                      </td>
                      <td className="text-center">
                        <IconButton sx={{ p: 0 }}>
                          <Avatar
                            alt="Remy Sharp"
                            src={imagePath + list.passportSize_img}
                            style={{ cursor: "default" }}
                          />
                        </IconButton>
                      </td>
                      <td>
                        {list.red_flag == "Deactive" ? (
                          <IconButton
                            onClick={() => openModal(list)}
                            title="Click to add Red Flag"
                          >
                            <ReportTwoToneIcon color="success" />
                          </IconButton>
                        ) : (
                          <IconButton
                            // onClick={() => openModal(list)}
                            title="Red Flag Employee"
                          >
                            <ReportTwoToneIcon color="error" />
                          </IconButton>
                        )}
                      </td>

                      <td width="250px" className="text-center">
                        <Link to={`/Edit_Employee/${list.id}`}>
                          <IconButton type="btn btn-primary" title="Edit">
                            <EditTwoToneIcon />
                          </IconButton>
                        </Link>
                        <IconButton
                          type="btn btn-primary"
                          onClick={() => deleteEmployee(list.id)}
                          title="Delete"
                        >
                          <DeleteTwoToneIcon />
                        </IconButton>
                        {/* <Link
                        to="/Reviews"
                        state={
                          { employeeid: list.id } // Replace yourDataObject with the data you want to pass
                        }
                      >
                        <IconButton type="btn btn-primary" title="Add Review">
                          <RateReviewIcon />
                        </IconButton>
                      </Link> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {data.length === 0 && ApiComplete ? <NotFound /> : null}

              <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
              <br />
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="modal-dialog-centered"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <div className="text-center">
                <h3 className="display-7 heading-h3">
                  Add
                  <span className="display-7 heading-h3-green">
                    {" "}
                    Red Flag !
                  </span>
                </h3>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="container mt-4">
              <div className="row d-flex justify-content-center pt-6">
                <div className="col-sm-12">
                  <div className="card form-border">
                    <div className="card-body">
                      <form action="/submit_review" method="POST">
                        <div className="col-sm-12">
                          <label htmlFor="userImage" className="form-label">
                            Add Description
                          </label>
                          <textarea
                            value={description}
                            className="form-control"
                            onChange={(e) => {
                              setDescription(e.target.value);
                              setError({ ...Error, description: "" });
                            }}
                          ></textarea>
                        </div>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.description}
                        </div>

                        <div className="col-sm-12">
                          <label htmlFor="userImage" className="form-label">
                            Police Booking Number
                          </label>
                          <input
                            value={policeNo}
                            className="form-control"
                            onChange={(e) => {
                              setPoliceNo(e.target.value);
                              // setError({ ...Error, policeNo: "" });
                            }}
                          ></input>
                        </div>

                        <div className="col-sm-12">
                          <label htmlFor="userImage" className="form-label">
                            Add Document
                          </label>
                          <input
                            // value={doc}
                            type="file"
                            className="form-control"
                            onChange={(e) => {
                              setDoc(e.target.files[0]);
                              setError({ ...Error, doc: "" });
                            }}
                          ></input>
                        </div>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.doc}
                        </div>

                        {/* <div className="col-sm-12">
                            <label htmlFor="userImage" className="form-label">
                              Employee Active or Inactive?
                            </label>
                          </div>

                          <div>
                            <div>
                              <input
                                type="radio"
                                id="active"
                                name="videoStatus"
                                value="Active"
                                checked={isActive === "Active"}
                                onChange={handleToggleChange}
                              />
                              <label htmlFor="active">Active</label>
                            </div>

                            <div>
                              <input
                                type="radio"
                                id="inactive"
                                name="videoStatus"
                                value="Deactive"
                                checked={isActive === "Deactive"}
                                onChange={handleToggleChange}
                              />
                              <label htmlFor="inactive">Inactive</label>
                            </div>
                          </div> */}
                        <br />
                        <div className="row d-flex justify-content-center">
                          <button
                            type="submit"
                            class="btn btn-primary col-sm-5"
                            onClick={submit}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default EmployeeList;

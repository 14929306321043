import React from "react";
import { Spinner } from "react-bootstrap";
import "./Loader.css";
import { Backdrop, CircularProgress } from "@mui/material";
// import Style from "../../styles/landing.module.css";

export default function Loader() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <div className="kidsloading"></div>
    </Backdrop>

    // <div className='loader'>
    //     <Spinner animation="border" role="status">
    //     </Spinner>
    // </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import SideNav from "../Sidebar/SideNav";

const Employers = () => {
  const [fullName, setfullName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Info, setInfo] = useState("");
  const [Error, setError] = useState("");

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    if (fullName === "") {
      isValid = false;
      Error["fullName"] = "Please Enter Name";
    }

    if (Email === "") {
      isValid = false;
      Error["Email"] = "Please Enter Email";
    }
    if (Phone === "") {
      isValid = false;
      Error["Phone"] = "Please Enter Phone";
    }
    if (Info === "") {
      isValid = false;
      Error["Info"] = "Please Enter Address";
    }

    setError(Error);
    return isValid;
  };

  const submitApplication = (e) => {
    // console.log("hhhhhjgjg")
    e.preventDefault();
    if (ValidateForm()) {
      const body = {
        full_name: fullName,
        address: Info,
        contact_number: Phone,
        user_id: "1",
      };
      axios
        .post("http://http://5.189.150.75:58001/api/employer", body)
      // axios
      // .post("http://localhost:8000/api/employer", body)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Employer added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Something went wrong.",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
        });
    }
  };

  return (
    <>
      <SideNav />
      <div class="container mt-4">
        <div className="text-center py-3">
          <h3 class="mb-4" className="display-7 heading-h3">
            Employer's Job{" "}
            <span className="display-7 heading-h3-green">
              Application Form !
            </span>
          </h3>
        </div>
        <div className="row d-flex justify-content-center pb-3">
          <div className="col-sm-7">
            <div class="card form-border">
              <div className="card-body">
                <form className="row" action="/submit" method="POST">
                  <div class="mb-3 col-sm-6">
                    <label for="fullName" class="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="fullName"
                      name="fullName"
                      value={fullName}
                      onChange={(e) => {
                        setfullName(e.target.value);
                        setError({ ...Error, fullName: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.fullName}
                    </div>
                  </div>
                  <div class="mb-3 col-sm-6">
                    <label for="email" class="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      value={Email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError({ ...Error, Email: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Email}
                    </div>
                  </div>
                  <div class="mb-3 col-sm-6">
                    <label for="phone" class="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      id="phone"
                      name="phone"
                      value={Phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setError({ ...Error, Phone: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Phone}
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="additionalInfo" class="form-label">
                      Address
                    </label>
                    <textarea
                      class="form-control"
                      id="additionalInfo"
                      name="additionalInfo"
                      value={Info}
                      onChange={(e) => {
                        setInfo(e.target.value);
                        setError({ ...Error, Info: "" });
                      }}
                      rows="4"
                    ></textarea>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Info}
                    </div>
                  </div>

                  <div className="row d-flex justify-content-center pt-2">
                    <button
                      type="submit"
                      class="btn btn-primary col-sm-5"
                      onClick={submitApplication}
                    >
                      Submit Application
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employers;

import React, { useState, useEffect } from "react";
import { Table, ModalBody } from "react-bootstrap";
import axios from "axios";
import { Pagination } from "@mui/material";
import SideNav from "../Sidebar/SideNav";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import {
  getEmployer,
  DeleteEmployee,
  AddRedFlag,
} from "../../Components/Api/AdminApi";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Avatar from "@mui/material/Avatar";
import "bootstrap/dist/css/bootstrap.min.css"; //
import Loader from "../Loader/Loader";
import NotFound from "../NotFound/NotFound";

const EmployerList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Error, setError] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [description, setDescription] = useState("");
  const [employeeID, setEmployeeID] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [Loading, setLoading] = useState(false);
  const [ApiComplete,SetApiComplete] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);




  const itemsPerPage = 10; // Change this number based on your desired items per page
  const imagePath = "https://api.knowyourmaid.com/";

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleToggleChange = (event) => {
    setIsActive(event.target.value === "active");
  };

  const handleKeyPress = (e) => {
    if (e.key === '%') {
      e.preventDefault();
    }
  };

  
  useEffect(() => {
    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let details = data.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(details);
  }, [currentPage]);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const openModal = (list) => {
    handleShow();
    console.log(list, "got list");
    setEmployeeID(list.id);
  };
  const submit = (e) => {
    e.preventDefault();
    const data = {
      red_flag: isActive,
      redFlag_desc: description,
    };
    AddRedFlag(data, employeeID)
    // axios.post(`http://localhost:8000/api/redFlag/${employeeID}`,data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Red Flag added Successfully",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
        cancelData();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Red Flag Not Added",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    getEmployerList();
  }, [searchValue]);

  const getEmployerList = () => {
    setLoading(true)
    getEmployer(searchValue)
      .then((res) => {
        setData(res.data.results);
        setCurrentPage(1);
        let indexOfLastItem = 1 * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        let details = res.data.results.slice(
          indexOfFirstItem,
          indexOfLastItem
        );
        setCurrentItems(details);
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setLoading(false)
        SetApiComplete(true)
      })
  };

  const deleteEmployee = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteEmployee(id)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Employee Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getEmployerList();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Employee Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash menu");
      });
  };

  const cancelData = () => {
    setDescription("");
    setIsActive("");
  };


  return (
    <>
      <SideNav />
      {Loading && <Loader/>}

      <div className="row mt-5">
      <h3 className="display-7 heading-h3 text-center">
            Employer <span className="display-7 heading-h3-green">List</span>
          </h3>
          <Col md={{ span: 3, offset: 9 }}>
          <Form.Group
            as={Row}
            className="justify-content-center"
            controlId="formHorizontalEmail"
          >
            <Col sm={12} className="mb-4">
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  size="small"
                  placeholder="Search by Name"
                  name="searchValue"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyPress={handleKeyPress}
                  startAdornment={
                    <InputAdornment position="start">
                      <FiSearch />
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Col>
          </Form.Group>
        </Col>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-12 text-center">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Address</th>
                  {/* <th>Experience</th>
                  <th>Service Type</th>
                  <th>Image</th>
                  <th>Red Flag</th>
                  <th>Action</th> */}

                  {/* <th>Delete</th>                                                      
              <th>Review</th> */}
                </tr>
              </thead>
              <tbody>
              {currentItems.map((list, index) => (
               
                      <tr key={list.id}>
                        <td>{index +
                          1 +
                          (currentPage * itemsPerPage - itemsPerPage)}</td>
                        <td>{list.full_name}</td>
                        <td>{list.email}</td>
                        <td>{list.contact_number}</td>
                        <td>
                          {list.address}
                        </td>
                        
                      </tr>
                    
                    ))}
              </tbody>
            </Table>
            {data.length === 0 && ApiComplete ? <NotFound /> :null}


            <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <div className="text-center">
              <h3 className="display-7 heading-h3">
                Add
                <span className="display-7 heading-h3-green"> Red Flag !</span>
              </h3>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="container mt-4">
            <div className="row d-flex justify-content-center pt-6">
              <div className="col-sm-12">
                <div className="card form-border">
                  <div className="card-body">
                    <form action="/submit_review" method="POST">
                      <div className="col-sm-12">
                        <label htmlFor="userImage" className="form-label">
                          Add Description
                        </label>
                        <textarea
                          value={description}
                          className="form-control"
                          onChange={(e) => {
                            setDescription(e.target.value);
                            setError({ ...Error, video: "" });
                          }}
                        ></textarea>
                      </div>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {Error.video}
                      </div>

                      <div className="col-sm-12">
                        <label htmlFor="userImage" className="form-label">
                          Employee Active or Inactive?
                        </label>
                      </div>

                      <div>
                        <div>
                          <input
                            type="radio"
                            id="active"
                            name="videoStatus"
                            value="active"
                            checked={isActive}
                            onChange={handleToggleChange}
                          />
                          <label htmlFor="active">Active</label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="inactive"
                            name="videoStatus"
                            value="inactive"
                            checked={!isActive}
                            onChange={handleToggleChange}
                          />
                          <label htmlFor="inactive">Inactive</label>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <button
                          type="submit"
                          class="btn btn-primary col-sm-5"
                          onClick={submit}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmployerList;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import getYouTubeID from "get-youtube-id";
import { getActiveVideo } from "../Api/AdminApi";
import { Link } from "react-router-dom";
import Topbar from "../Home/Topbar";
import Footer from "../Home/Footer";
import { Pagination } from "@mui/material";
import Loader from "../Loader/Loader";

const EmployeeVideoList = () => {
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Change this number based on your desired items per page


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = videoData.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getVideos();
  }, []);

  const getVideos = () => {
    setLoading(true);
    getActiveVideo()
      .then((res) => {
       const data = res.data.video_link;
        ;
        setVideoData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Topbar />
      {loading && <Loader />}
      <Container className="red-flag-container">
        <div className="text-center py-4">
          <h3 className="display-6 heading-h3">
            Employee <span className="display-6 heading-h3-green">Videos</span>
          </h3>
        </div>

        <Row>
          {currentItems &&
            currentItems.map((list, index) => (
              <Col md={4} className="mb-3">
                <div>
                  <div>
                    <iframe
                      src={`https://youtube.com/embed/${getYouTubeID(
                        list.video_link
                      )}?autoplay=0`}
                      width="100%"
                      height="200"
                      controls 
                    />
                  </div>
                </div>
              </Col>
            ))}

          {/* <Link to="/Employee_Video_List"> <button type="button" className="btn btn-primary">
              View More
            </button>
            </Link> */}
        </Row>
        <Pagination
          count={Math.ceil(videoData.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </Container>

      <Footer />
    </>
  );
};

export default EmployeeVideoList;

import React from "react";
import { Routes, Route } from "react-router-dom";
import Register from "./UserInfo/Register";
import Login from "./UserInfo/Login";
import Employers from "./AdminSection/Employers";
import Employee from "./AdminSection/Employee";
import Payment from "./AdminSection/Payment";
import Review from "./AdminSection/Review";
import LandingPage from "./Home/LandingPage";
import ServiceType from "./AdminSection/ServiceType";
import EmployeeList from "./AdminSection/EmployeeList";
import ServiceTypeList from "./AdminSection/ServiceTypeList";
import Dashboard from "./AdminSection/Dashboard";
import EditEmployee from "./AdminSection/EditEmployee";
import PrivateComponent from "./PrivateComponent";
import VerifyEmail from "./UserInfo/VerifyEmail";
import ResetPassword from "./UserInfo/ResetPassword";
import EmployerEmployeeList from "./EmployerSection/EmployerEmployeeList";
import AddVideo from "../Components/AdminSection/AddVideo";
import AddFAQ from "../Components/AdminSection/AddFAQ";
import EmployerDashboard from "./AdminSection/EmployerDashboard";
import EmployerReviewList from "./EmployerSection/EmployerReviewList";
import EmployerList from "./AdminSection/EmployerList";
import ReviewList from "./AdminSection/ReviewList";
import RedFlagList from "./HomePages/RedflagList";
import EmployeeVideoList from "../Components/HomePages/EmployeeVideoList";
import RedFlagEmployeeInfo from "./HomePages/RedFlagEmployeeInfo";
import ContactUsList from "./AdminSection/ContactUsList";
import VisitorProfile from "./VisitoSection/VisitorProfile";
import EmployeeProfile from "./EmployeeSection/EmployeeProfile";
import AllEmployee from "./HomePages/AllEmployee";
import PrivacyPolicy from "./Home/PrivacyPolicy";
import RedFlagEmployeeList from "./AdminSection/RedFlagEmployeeList";
import RedflagList from "./EmployerSection/RedflagList";

const Routing = () => {
  return (
    <Routes>
      <Route element={<PrivateComponent />}>
        <Route path="/Employers" element={<Employers />} />
        <Route path="/Employee" element={<Employee />} />
        <Route path="/Reviews" element={<Review />} />
        <Route path="/Service_Type" element={<ServiceType />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Employee_List" element={<EmployeeList />} />
        <Route path="/Service_Type_List" element={<ServiceTypeList />} />
        <Route path="/Edit_Employee/:id" element={<EditEmployee />} />
        <Route path="/Employer_Employee_List" element={<EmployerEmployeeList />}/>
        <Route path="/Add_Video" element={<AddVideo />} />
        <Route path="/Add_FAQ" element={<AddFAQ />} />
        <Route path="/Profile" element={<EmployerDashboard />} />
        <Route path="/Employee_Review_List" element={<EmployerReviewList />} />
        <Route path="/Employer_List" element={<EmployerList />} />
        <Route path="/Review_List" element={<ReviewList />} />
        <Route path="/ContactUs_List" element={<ContactUsList />} />
        <Route path="/EmployeeList"element={<AllEmployee/>}/>
        <Route path="/redflag-employeeList"element={<RedFlagEmployeeList/>}/>
        <Route path="/Employer-redflag-employeeList"element={<RedflagList/>}/>
      </Route>

      <Route path="/" element={<LandingPage />} />

      <Route path="/Login" element={<Login />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

      <Route path="/verify_email/:token" element={<VerifyEmail />} />
      <Route path="/reset_password/:token" element={<ResetPassword />} />
      <Route path="/Red_Flag_Employee_List" element={<RedFlagList />} />
      <Route path="/Payment" element={<Payment />} />
      <Route path="/Employee_Video_List" element={<EmployeeVideoList />} />
      <Route path="/Employee_Info/:id" element={<RedFlagEmployeeInfo />} />
      <Route path="/Visitor_Profile" element={<VisitorProfile />} />
      <Route path="/Employee_Profile" element={<EmployeeProfile />} />


    </Routes>
  );
};

export default Routing;

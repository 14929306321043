import React, { useState, useEffect } from "react";
import SideNav from "../Sidebar/SideNav";
import { Container, Row, Col, Card } from "react-bootstrap";
import user from "../../assets/images/user.png";
import business from "../../assets/images/business-woman.png";
import unemployed from "../../assets/images/unemployed.png";
import remove from "../../assets/images/disqualified.png";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import axios from "../ServiceApi/AxiosInstance";
import { getEmployeeData } from "../Api/AdminApi";
import { Link } from "react-router-dom";
import Auth from "../ServiceApi/Auth";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [token,setToken] = useState("");
  const [mail,setMail] = useState("");

  

  useEffect(()=>{
    let mail = Auth.getEmail();
    setMail(mail)
    const token = Auth.getToken();
    setToken(token)

  },[]);


  useEffect(() => {
    getEmpInfo();
  }, []);

  const getEmpInfo = () => {
    getEmployeeData()
      // axios.get("http://localhost:8000/api/getEmployeeInfo")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <SideNav />
      <div>
        <Container className="pt-5">
          <div>
            <h1 className="pb-4 dashboard-heading ">Dashboard</h1>
          </div>
          <Row className="pt-3">
            <Col className="col-lg-3 col-md-6 col-sm-12 col-xs-mb-3">
              <Link style={{textDecoration:'none'}} to={'/Employee_List'}>
              <Card className="dash-tiles">
                <Card.Body>
                  <div className="tile-img-grid">
                    <img
                      className="img-fluid users-img"
                      src={user}
                      alt="user"
                    />
                  </div>
                  <div className="pt-2 text-center">
                    <p> Employees  <span style={{fontWeight:'bold',color:'#1FA2FF'}}>{data.total_employees}</span>
                    </p>
                  </div>
                </Card.Body>
              </Card>
              </Link>
            </Col>
            <Col className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Link style={{textDecoration:'none'}} to={'/redflag-employeeList'}>
              <Card className="dash-tiles1">
                <Card.Body>
                  <div className="tile-img-grid">
                    <img
                      className="img-fluid users-img"
                      src={business}
                      alt="user"
                    />
                  </div>
                  <div className="pt-2 text-center">
                    <p>RedFlag Employees  <span style={{fontWeight:'bold',color:'#eb4d4b'}}>{data.active_red_flag_employees}</span>
                   </p>
                  </div>
                </Card.Body>
              </Card>
              </Link>
            </Col>

            <Col className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Link style={{textDecoration:'none'}} to={'/Employer_List'}>
              <Card className="dash-tiles1">
                <Card.Body>
                  <div className="tile-img-grid">
                  <img
                      className="img-fluid users-img"
                      src={user}
                      alt="user"
                    />
                  </div>
                  <div className="pt-2 text-center">
                    <p>Employers  <span style={{fontWeight:'bold',color:'#1FA2FF'}}>{data.employer_count}</span>
                   </p>
                  </div>
                </Card.Body>
              </Card>
              </Link>
            </Col>
            {/* <Col className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card className="dash-tiles2">
                <Card.Body>
                  <div className="tile-img-grid">
                    <img
                      className="img-fluid users-img"
                      src={unemployed}
                      alt="user"
                    />
                  </div>
                  <div className="pt-2 text-end">
                    <p>Total Unemployed</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card className="dash-tiles3">
                <Card.Body>
                  <div className="tile-img-grid">
                    <img
                      className="img-fluid users-img"
                      src={remove}
                      alt="user"
                    />
                  </div>
                  <div className="pt-2 text-end">
                    <p>Total Disqualified</p>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
          {/* <Row className="py-3 gx-5">
            <Col className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div>
                <h1 className="pb-4 dashboard-heading display-7">
                  Top Rated Employees
                </h1>
              </div>
              <div className="listbox">
                <ListGroup as="ol" numbered>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
            <Col className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div>
                <h1 className="pb-4 dashboard-heading display-7">
                  Top Services
                </h1>
              </div>
              <div className="listbox">
                <ListGroup as="ol" numbered>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Subheading</div>
                      Cras justo odio
                    </div>
                    <Badge bg="primary" pill>
                      14
                    </Badge>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import Topbar from "../Home/Topbar";
import { EmployeegetById } from "../Api/AdminApi";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Auth from "../ServiceApi/Auth";
import { RiAdminFill } from "react-icons/ri";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { IoPersonCircle } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { MdPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import Image from "react-bootstrap/Image";
import { MdOutlineWorkOutline } from "react-icons/md";
import { MdPermIdentity } from "react-icons/md";
const EmployeeProfile = () => {
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const auth = Auth.getEmployeeID();

  const imagePath = "https://api.knowyourmaid.com/";

  // console.log(Id,"ppp")
  // console.log(userId,"fdfs")

  useEffect(() => {
    getUser(auth);
  }, [auth]);

  const getUser = (id) => {
    EmployeegetById(id)
      .then((res) => {
        setData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateToEditPage = (id) => {
    navigate(`/Edit_Employee/${id}`);
  };

  return (
    <>
      <Topbar />

      <Container>
        <div className="pt-4">
          <h3 className=" text-center display-6 heading-h3">
            Employee <span className="display-6 heading-h3-green">Profile</span>
          </h3>
        </div>

        <Row className="d-flex justify-content-center py-5">
          <div className="col-lg-8">
            <div className="row d-flex justify-content-between visitor-form">
              <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
                <div className="prof-image text-start pb-3 d-flex justify-content-start">
                  <Image
                    src={imagePath + data.passportSize_img}
                    roundedCircle
                    width={120}
                    height={120}
                  />
                  <div className="py-5 px-3">
                    <h5>{data.full_name}</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-2 col-sm-12 mb-3">
                <div className="text-center">
                  <button
                    class="button-18"
                    role="button"
                    onClick={() => navigateToEditPage(data.id)}
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
            <div className="row visitor-form">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row gx-2">
                  <div className="col-lg-6 border p-3 mb-3">
                    <div>
                      <p className="mb-0 p-label">
                        <span>
                          <IoPersonCircle fontSize={25} color="grey" />
                        </span>{" "}
                        Employee Name:{" "}
                      </p>
                      <p className="p-emp-info">{data.full_name}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 border p-3 mb-3">
                    <p className="mb-0 p-label">
                      <span>
                        <IoMdMail fontSize={23} color="grey" />
                      </span>{" "}
                      Employee Email :{" "}
                    </p>
                    <p className="p-emp-info">{data.email}</p>
                  </div>
                  <div className="col-lg-6 border p-3 mb-3">
                    <p className="mb-0 p-label">
                      <span>
                        <MdPhone fontSize={23} color="grey" />
                      </span>{" "}
                      Employee Contact :{" "}
                    </p>
                    <p className="p-emp-info">{data.contact_number}</p>
                  </div>

                  <div className="col-lg-6 border p-3 mb-3">
                    <p className="mb-0 p-label">
                      <span>
                        <MdOutlineWorkOutline fontSize={23} color="grey" />
                      </span>{" "}
                      Experience (in Years) :{" "}
                    </p>
                    <p className="p-emp-info">{data.experience_in_years}</p>
                  </div>
                  <div className="col-lg-6 border p-3 mb-3">
                    <p className="mb-0 p-label">
                      <span>
                        <MdPermIdentity fontSize={23} color="grey" />
                      </span>{" "}
                      ID No :{" "}
                    </p>
                    <p className="p-emp-info">{data.id_number}</p>
                  </div>
                  <div className="col-lg-6 border p-3 mb-3">
                    <p className="Profileservice p-label">
                      <span>
                        <RiAdminFill fontSize={23} color="grey" />
                      </span>{" "}
                      Role And Responsibility :{" "}
                    </p>
                    <ol>
                      {data.services &&
                        data.services.map((a, index) => (
                          <li key={index}>
                            <h6 className="p-emp-info">{a.service_name}</h6>
                          </li>
                        ))}
                    </ol>
                  </div>
                  <div className="col-lg-12 border p-3 mb-3">
                    <p className="mb-0 p-label">
                      <span>
                        <IoLocationSharp fontSize={23} color="grey" />
                      </span>{" "}
                      Employee Address :{" "}
                    </p>
                    <p className="p-emp-info">{data.address_1}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EmployeeProfile;

// import axios from "axios";

import axios from "../ServiceApi/AxiosInstance";
import URL from "../ServiceApi/Server";

//constant data
const addEmployee = async (data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/employee`,
    data: data,
  });
};

const getEmployee = async (search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/employee?search=${search}
    `,
  });
};

const redflagEmployeeList = async (search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/empListRed?search=${search}`,
  });
};

const EmployerRedflagList = (id, search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/getRedByEmployer/${id}?search=${search}`,
  });
};

const EmployeegetById = async (id) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/employee/${id}`,
  });
};

const getallemployeesearchbyId = async (search)=>{
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/searchAllEmp?id_number=${search}`,
  });
}



const EditEmployeesData = async (id, data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/employee/${id}`,
    data: data,
  });
};

const DeleteEmployee = async (id) => {
  return axios({
    method: "DELETE",
    url: `${URL.EmployeeURl}/employee/${id}`,
  });
};

const addService = async (data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/service-type`,
    data: data,
  });
};

const getService = async (search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/service-type?search=${search}`,
  });
};

const ServicegetById = async (id) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/service-type/${id}`,
  });
};

const EditService = async (id, data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/service-type/${id}`,
    id: id,
    data: data,
  });
};

const DeleteService = async (id) => {
  return axios({
    method: "DELETE",
    url: `${URL.EmployeeURl}/service-type/${id}`,
  });
};

const AddReview = async (data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/review`,
    data: data,
  });
};

const getReview = async () => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/review`,
  });
};

const getEmployer = async (search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/employer?search=${search}`,
  });
};

const AddPayment = async (data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/stk_push`,
    data: data,
  });
};

const ReviewgetbyEmployerId = async (id) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/review-emp/${id}`,
  });
};

const AddVideodata = async (data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/video-link`,
    data: data,
  });
};

const getVideo = async (search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/video-link?search=${search}`,
  });
};

const getActiveVideo = async (search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/listVideo`,
  });
};

const DeleteVideo = async (id) => {
  return axios({
    method: "DELETE",
    url: `${URL.EmployeeURl}/video-link/${id}`,
  });
};

const AddRedFlag = async (data, id) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/redFlag/${id}`,
    data: data,
  });
};

const EmployeegetByEmployerId = async (id, search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/getEmp/${id}?search=${search}`,
  });
};

const getRedFlagEmployee = async () => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/empListRed`,
  });
};

const AddContactUs = async (data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/contact_us`,
    data: data,
  });
};

const getContactUs = async (search) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/contact_us?search=${search}`,
  });
};

const EditVideoData = async (id, data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/video-link/${id}`,
    data: data,
  });
};
const getEmployeeData = async () => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/getEmployeeInfo`,
  });
};

const getEmployerEmployeeData = async (id) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/getEmployerEmployeeInfo/${id}`,
  });
};

const getUserById = async (id) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/user/${id}`,
  });
};

const AddQuestionAnswer = async (data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/faqStore`,
    data: data,
  });
};

const getQuestionsAnswers = async () => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/faqList`,
  });
};

const getQuestionsAnswersById = async (id) => {
  return axios({
    method: "GET",
    url: `${URL.EmployeeURl}/faqShow/${id}`,
  });
};

const EditQuestionAnswer = async (id, data) => {
  return axios({
    method: "POST",
    url: `${URL.EmployeeURl}/faqUpdate/${id}`,
    id: id,
    data: data,
  });
};

const DeleteQuestionAnswer = async (id) => {
  return axios({
    method: "DELETE",
    url: `${URL.EmployeeURl}/faqDelete/${id}`,
  });
};

export {
  addEmployee,
  getEmployee,
  redflagEmployeeList,
  EmployerRedflagList,
  EmployeegetById,
  EditEmployeesData,
  DeleteEmployee,
  addService,
  getService,
  ServicegetById,
  EditService,
  DeleteService,
  AddReview,
  getReview,
  getEmployer,
  AddPayment,
  ReviewgetbyEmployerId,
  AddVideodata,
  getVideo,
  getActiveVideo,
  AddRedFlag,
  EmployeegetByEmployerId,
  getRedFlagEmployee,
  AddContactUs,
  getContactUs,
  EditVideoData,
  DeleteVideo,
  getEmployeeData,
  getEmployerEmployeeData,
  getUserById,
  AddQuestionAnswer,
  getQuestionsAnswers,
  getQuestionsAnswersById,
  EditQuestionAnswer,
  DeleteQuestionAnswer,
  getallemployeesearchbyId
};

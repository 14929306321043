import React, { useState, useEffect, useRef } from "react";
import axios from "../ServiceApi/AxiosInstance";
import Swal from "sweetalert2";
import SideNav from "../Sidebar/SideNav";
import { useParams, useNavigate } from "react-router-dom";
import {
  EmployeegetById,
  EditEmployeesData,
  getService,
} from "../Api/AdminApi";
import EmployerSidebar from "../Sidebar/EmployerSidebar";
import { MultiSelect } from "react-multi-select-component";
import { Button, CloseButton, Col, Form, Row } from "react-bootstrap";
import { Box, IconButton, ImageList, ImageListItem } from "@mui/material";
import Auth from "../ServiceApi/Auth";
import Loader from "../Loader/Loader";
import Topbar from "../Home/Topbar";

const EditEmployee = () => {
  const Navigate = useNavigate();
  const role = Auth.getRole();
  const Employer = Auth.getEmployerID();
  const inputRefrence1 = useRef(null);
  const inputRefrence2 = useRef(null);
  const inputRefrence3 = useRef(null);

  const [fullName, setfullName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Experience, setExperience] = useState("");
  const [Id, setId] = useState("");
  const [nationId, setNationId] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [ID, setID] = useState("");
  const [ServiceTypeOption, setServiceTypeOption] = useState([]);
  const [ContactPerson, setContactPerson] = useState("");
  const [ContactPersonNum, setContactPersonNum] = useState("");
  const [IdImage1, setIdImage1] = useState("");
  const [IdImg1Path, setIdImg1Path] = useState("");
  const [IdImage2, setIdImage2] = useState("");
  const [IdImg2Path, setIdImg2Path] = useState("");
  const [Address1, setAddress1] = useState("");
  const [update, setupdate] = useState(false);
  const [NewsImagePath, setNewsImagePath] = useState([]);
  const [serviceSelected, setserviceSelected] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedBackFiles, setSelectedBackFiles] = useState([]);
  const [selectedUserFiles, setSelectedUserFiles] = useState([]);
  const [Errors, setErrors] = useState([]);


  const [Error, setError] = useState("");

  const { id } = useParams();
  const auth = Auth.getUserId();

  const PhotoPath = "https://api.knowyourmaid.com/";

  const imagePath = "https://api.knowyourmaid.com/";

  const [fileLimit, setFileLimit] = useState(false);
  const inputRef = (useRef < HTMLInputElement) | (null > null);
  const MAX_COUNT = 1;
  const MAX_COUNT_USER = 1;
  const handleFileChange = (event, inputType) => {
    const files = event.target.files;

    if (files) {
      const uploaded =
        inputType === "idfImages"
          ? [...(selectedFiles || [])]
          : [...(selectedBackFiles || [])];
      const userFiles = [...(selectedUserFiles || [])];
      let limitExceeded = false;

      Array.from(files).some((file) => {
        if (inputType === "userImage") {
          if (userFiles.findIndex((f) => (f.name === file.name) === -1)) {
            userFiles.push(file);
            if (userFiles.length > MAX_COUNT_USER) {
              alert(`You can only add a maximum of ${MAX_COUNT_USER} file`);
              // setFileLimit(false);
              limitExceeded = true;
              return true;
            }
          }
        } else {
          if (uploaded.findIndex((f) => f.name === file.name) === -1) {
            uploaded.push(file);
            if (uploaded.length > MAX_COUNT) {
              alert(`You can only add a maximum of ${MAX_COUNT} files`);
              // setFileLimit(false);
              limitExceeded = true;
              return true;
            }
          }
        }
        return false;
      });

      if (!limitExceeded) {
        if (inputType === "userImage") {
          setSelectedUserFiles(userFiles);
          setError({ ...Error, userImage1: "" });
        } else if (inputType === "idfImages") {
          setSelectedFiles(uploaded);
          setError({ ...Error, frontImg: "" });
        } else if (inputType === "idbImages") {
          setSelectedBackFiles(uploaded);
          setError({ ...Error, backImg: "" });
        }
      }
    }
  };
  const removeImg = (index, inputType) => {
    let images =
      inputType === "userImage"
        ? [...selectedUserFiles]
        : inputType === "idfImages"
        ? [...selectedFiles]
        : [...selectedBackFiles];
    images.splice(index, 1);
    if (inputType === "userImage") {
      setSelectedUserFiles(images);
    } else if (inputType === "idfImages") {
      setSelectedFiles(images);
    } else {
      setSelectedBackFiles(images);
    }
    setFileLimit(false);
    if (images.length === 0) {
      if (inputType === "userImage") {
        inputRefrence3.current.value = null;
      } else if (inputType === "idfImages") {
        inputRefrence1.current.value = null;
      } else {
        inputRefrence2.current.value = null;
      }
    }
  };

  const handleExperienceChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = event.target.options[event.target.selectedIndex];
    const selectedLabel = selectedOption.text;
    setExperience(selectedValue);
    setError({ ...Error, Experience: "" });
  };

  const handleIdImage1Change = (e) => {
    const selectedImage = e.target.files[0];
    setIdImage1(selectedImage);
    setIdImg1Path(URL.createObjectURL(e.target.files[0]));
    setError({ ...Error, IdImage1: "" });
    // }
  };

  const handleIdImage2Change = (e) => {
    const selectedImage = e.target.files[0];
    setIdImage2(selectedImage);
    setIdImg2Path(URL.createObjectURL(e.target.files[0]));
    setError({ ...Error, IdImage2: "" });
    // }
  };

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let contactregex = /^(?!0+$)\d+$/i;
    let nameregexx = /^[a-zA-Z-' ]+$/;
    let pincoderegex = /^[1-9][0-9]{5}$/;
    // let experiencenoregex = /^0*[0-9]+$/i;

    if (fullName === "") {
      isValid = false;
      Error["fullName"] = "Please Enter Name";
    } else if (nameregexx.test(fullName) === false) {
      isValid = false;
      Error["fullName"] = "Please Enter Valid Name.";
    }
    // if (Email === "") {
    //   isValid = false;
    //   Error["Email"] = "Please Enter Email";
    if (Email !== "" ? emailregex.test(Email) === false : "") {
      isValid = false;
      Error["Email"] = "Please Enter Valid Email";
    }
    if (Phone === "") {
      isValid = false;
      Error["Phone"] = "Please Enter Phone";
    } else if (contactregex.test(Phone) === false) {
      isValid = false;
      Error["Phone"] = "Please Enter Valid Contact No.";
    } else if (Phone.length > 10) {
      isValid = false;
      Error["Phone"] = "Please Enter Valid Contact No.";
    }
    if (Id === "" && nationId === null) {
      isValid = false;
      Error["Id"] = "Please Enter Id Number";
    } else if (
      contactregex.test(Id) === false &&
      contactregex.test(nationId) === false
    ) {
      isValid = false;
      Error["Id"] = "Please Enter Valid Id No.";
    }
    if (ContactPerson === "") {
      isValid = false;
      Error["ContactPerson"] = "Please Enter Contact Person Name";
    } else if (nameregexx.test(ContactPerson) === false) {
      isValid = false;
      Error["ContactPerson"] = "Please Enter Valid Name.";
    }
    if (ContactPersonNum === "") {
      isValid = false;
      Error["ContactPersonNum"] = "Please Enter Contact Person Number";
    } else if (contactregex.test(ContactPersonNum) === false) {
      isValid = false;
      Error["ContactPersonNum"] = "Please Enter Valid Contact No.";
    } else if (ContactPersonNum.length > 10) {
      isValid = false;
      Error["ContactPersonNum"] = "Please Enter Valid Contact No.";
    }
    if (Experience === "") {
      isValid = false;
      Error["Experience"] = "Please Enter Experience";
    }
    //  else if (experiencenoregex.test(Experience) === false) {
    //   isValid = false;
    //   Error["Experience"] = "Please Enter Experience";
    // }
    if (Address1 === "") {
      isValid = false;
      Error["Address1"] = "Please Enter Address";
    }

    if (serviceSelected.length < 1) {
      isValid = false;
      Error["serviceSelected"] = "Please Select Service Type";
    }
    if (selectedFiles === null || selectedFiles.length <= 0) {
      isValid = false;
      Error["frontImg"] = "Please Select Front Id Image";
    }
    if (selectedBackFiles === null || selectedBackFiles.length <= 0) {
      isValid = false;
      Error["backImg"] = "Please Select Back Id Image";
    }
    if (selectedUserFiles === null || selectedUserFiles.length <= 0) {
      isValid = false;
      Error["userImage1"] = "Please Select User Image";
    }

    setError(Error);
    return isValid;
  };

  useEffect(() => {
    getEmployeeById();
  }, []);

  const getEmployeeById = () => {
    setupdate(true);
    setLoading(true);
    EmployeegetById(id)
      .then((res) => {
        const empList = res.data.user;
        const service = res.data.user.services;
        let countyValues = [];
        let countyOption = service.map((a) =>
          countyValues.push({ value: a.id, label: a.service_name })
        );
        setID(empList.id);
        setfullName(empList.full_name);
        setEmail(empList.email);
        setPhone(empList.contact_number);
        setNationId(empList.id_number);
        // setId(empList.id_number);
        setAddress1(empList.address_1);
        setExperience(empList.experience_in_years);
        // setUserImage(empList.passportSize_img);
        // setIdImage1(empList.front_image);
        setContactPerson(empList.contact_person);
        setContactPersonNum(empList.contact_per_num);
        // setIdImage2(empList.back_image);
        {
          empList.front_image === null || empList.front_image === ""
            ? setSelectedFiles([])
            : setSelectedFiles([empList.front_image]);
        }
        {
          empList.passportSize_img === null || empList.passportSize_img === " "
            ? setSelectedUserFiles([])
            : setSelectedUserFiles([empList.passportSize_img]);
        }
        {
          empList.back_image === null || empList.back_image === ""
            ? setSelectedBackFiles([])
            : setSelectedBackFiles([empList.back_image]);
        }
        setserviceSelected(countyValues);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    console.log(selectedImage);
    setUserImage(selectedImage);
    setNewsImagePath(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    getServiceType();
    // getEmployerList();
  }, []);

  const getServiceType = () => {
    getService("").then((res) => {
      const list = res.data.results;
      const newArray = list.map((a) => ({
        value: a.id,
        label: a.service_name,
      }));
      setServiceTypeOption(newArray);
    });
  };

  const EditEmployeeData = (e) => {
    e.preventDefault();

    if (ValidateForm()) {
      const labelArray = serviceSelected.map((item) => item.value);
      const data = {
        full_name: fullName,
        employer_id: Employer,
        email: Email,
        contact_number: Phone,
        address_1: Address1,
        id_number: Id === "" ? Number(nationId) : Number(Id),
        experience_in_years: Experience === "0" ? "Fresher" : Experience,
        service_type_ids: labelArray,
        contact_person: ContactPerson,
        contact_per_num: ContactPersonNum,
        front_image: selectedFiles[0],
        back_image: selectedBackFiles[0],
        passportSize_img: selectedUserFiles[0],
      };
      EditEmployeesData(id, data)
        //  axios.put(`http://localhost:8000/api/employee/${id}`,data)
        //  axios.put(`https://api.knowyourmaid.com/api/employee/${id}`,data)

        .then((res) => {
          if (res.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Employee details updated Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            cancelField();
            if (role === 2) {
              Navigate("/Employer_Employee_List");
            } else if (role === 3) {
              Navigate("/Employee_Profile");
            } else {
              Navigate("/Employee_List");
            }
          }
        })
        .catch((err) => {
          const errordata = err.response?.data?.errors;

          if (errordata) {
            let emailerr = "";
            Object.values(errordata).map((d) => {
              emailerr = d;
              setErrors(d[0]);
            });
            Swal.fire({
              icon: "error",
              title: emailerr,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        });
    }
  };

  const cancelField = () => {
    setfullName("");
    setEmail("");
    setPhone("");
    setAddress1("");
    setExperience("");
    // setUserImage(null);
    // setNewsImagePath("");
    // setNewsImagePath([]);
    setContactPerson("");
    setContactPersonNum("");
    // inputRefrence1.current.value = null;
    setID("");
    setserviceSelected([]);
    setSelectedFiles([]);
    setSelectedBackFiles([]);
    setSelectedUserFiles([]);
  };

  return (
    <>
      {role === 1 ? (
        <SideNav />
      ) : role === 2 ? (
        <EmployerSidebar />
      ) : role === 3 ? (
        <Topbar />
      ) : null}
      {Loading && <Loader />}
      <div class="container mt-4">
        <div className="text-center py-3">
          <h3 class="mb-4" className="display-7 heading-h3">
            Employee Information{" "}
            <span className="display-7 heading-h3-green">Form !</span>
          </h3>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-sm-8">
            <div className="card form-border">
              <div className="card-body">
                <form className="row" action="/submit" method="POST">
                  <div class="form-group mb-3 col-sm-6">
                    <label for="fullName" className="form-label">
                      Full Name{" "}
                      <span
                        className="required"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="fullName"
                      name="fullName"
                      value={fullName}
                      onChange={(e) => {
                        setfullName(e.target.value);
                        setError({ ...Error, fullName: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.fullName}
                    </div>
                  </div>
                  <div class="form-group mb-3 col-sm-6">
                    <label for="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      // disabled
                      name="email"
                      value={Email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError({ ...Error, Email: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Email}
                    </div>
                  </div>

                  <div class="form-group mb-3 col-sm-6">
                    <label for="phone" className="form-label">
                      Phone Number{" "}
                      <span
                        className="required"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="phone"
                      disabled
                      value={Phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setError({ ...Error, Phone: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Phone}
                    </div>
                  </div>
                  <div class="form-group mb-3 col-sm-6">
                    <label for="serviceType" className="form-label">
                      Roles and Responsibility{" "}
                      <span
                        className="required"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <MultiSelect
                      options={ServiceTypeOption}
                      value={serviceSelected}
                      onChange={(selected, _options) => {
                        if (Array.isArray(selected)) {
                          setserviceSelected(selected);
                          setError({ ...Error, serviceSelected: "" });
                        } else {
                          console.error("Selected is not an array:", selected);
                          // Handle this situation as needed
                        }
                      }}
                      labelledBy="Select"
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.serviceSelected}
                    </div>
                  </div>

                  {/* <div>
                    <label for="serviceType" className="form-label">
                      Roles and Responsibilty
                    </label>
                    <select
                      value={serviceSelected}
                      onChange={event => setserviceSelected(event.target.value)}
                    >
                      <option value="">Select an option</option>
                      {ServiceTypeOption.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div class="form-group mb-12 col-sm-12">
                    <label for="Id" className="form-label">
                      Physical Address{" "}
                      <span
                        className="required"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <textarea
                      class="form-control"
                      id="address"
                      name="Id"
                      rows="4" // Adjust the number of rows as needed
                      value={Address1}
                      onChange={(e) => {
                        setAddress1(e.target.value);
                        setError({ ...Error, Address1: "" });
                      }}
                    ></textarea>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Address1}
                    </div>
                  </div>

                  <div className="form-group mb-3 col-sm-6">
                    <label htmlFor="experience" className="form-label">
                      Experience In Years{" "}
                      <span
                        className="required"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <Form.Select
                      id="experience"
                      name="experience"
                      value={Experience}
                      onChange={handleExperienceChange}
                    >
                      <option value="" disabled>
                        select
                      </option>
                      <option value="0">Fresher</option>
                      <option value="1">1 year</option>
                      <option value="2">2 years</option>
                      <option value="3">3 years</option>
                      <option value="4">4 years</option>
                      <option value="5">5 years</option>
                      <option value="6">More than 5 years</option>
                    </Form.Select>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Experience}
                    </div>
                  </div>
                  {/* <div class="form-group mb-3 col-sm-6">
                    <label for="Id" className="form-label">
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="Address2"
                      value={Address2}
                      onChange={(e) => {
                        setAddress2(e.target.value);
                        setError({ ...Error, Address2: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Address2}
                    </div>
                  </div> */}

                  <div class="form-group mb-3 col-sm-6">
                    <label for="address" className="form-label">
                      ID Number{" "}
                      <span
                        className="required"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    {nationId === null ? (
                      <>
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          name="address"
                          value={Id}
                          onChange={(e) => {
                            setId(e.target.value);
                            setError({ ...Error, Id: "" });
                          }}
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.Id}
                        </div>
                      </>
                    ) : (
                      <input
                        type="text"
                        class="form-control"
                        id="address"
                        name="address"
                        disabled
                        value={nationId}
                      />
                    )}
                  </div>

                  {/* <div class="form-group mb-3 col-sm-6">
                    <label for="Id" className="form-label">
                      Address Line 3
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="Address3"
                      value={Address3}
                      onChange={(e) => {
                        setAddress3(e.target.value);
                        setError({ ...Error, Address3: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Address3}
                    </div>
                  </div> */}

                  <div class="form-group mb-3 col-sm-6">
                    <label for="phone" className="form-label">
                      Contact Person Name{" "}
                      <span
                        className="required"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="Address3"
                      value={ContactPerson}
                      onChange={(e) => {
                        setContactPerson(e.target.value);
                        setError({ ...Error, ContactPerson: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.ContactPerson}
                    </div>
                  </div>
                  <div class="form-group mb-3 col-sm-6">
                    <label for="phone" className="form-label">
                      Contact Person Number{" "}
                      <span
                        className="required"
                        style={{ color: "red", fontSize: "1rem" }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="Address3"
                      value={ContactPersonNum}
                      onChange={(e) => {
                        setContactPersonNum(e.target.value);
                        setError({ ...Error, ContactPersonNum: "" });
                      }}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.ContactPersonNum}
                    </div>
                  </div>

                  {/* <div className="form-group mb-3 col-sm-6">
                    <label for="userImage" className="form-label">
                      ID Front Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="userImage"
                      ref={inputRefrence2}
                      accept="image/*"
                      onChange={handleIdImage1Change}
                    />
                    <div style={{ marginTop: "10px" }}>
                      <div className="imagepreview">
                        <img
                          className="img-fluid"
                          src={
                            update === false
                              ? IdImg1Path
                              : IdImage1?.name
                              ? IdImg1Path
                              : PhotoPath + IdImage1
                          }
                          alt="Image Thumbnail"
                          width="30%"
                          height="30"
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.IdImage1}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 col-sm-6">
                    <label for="userImage" className="form-label">
                      ID Back Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="userImage"
                      ref={inputRefrence3}
                      accept="image/*"
                      onChange={handleIdImage2Change}
                    />
                    <div style={{ marginTop: "10px" }}>
                      <div className="imagepreview">
                        <img
                          className="img-fluid"
                          src={
                            update === false
                              ? IdImg2Path
                              : IdImage2?.name
                              ? IdImg2Path
                              : PhotoPath + IdImage2
                          }
                          alt="Image Thumbnail"
                          width="30%"
                          height="30"
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.IdImage2}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 col-sm-12">
                    <label for="userImage" className="form-label">
                      User Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="userImage"
                      ref={inputRefrence1}
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <div style={{ marginTop: "10px" }}>
                      <div className="imagepreview">
                        <img
                          className="img-fluid"
                          src={
                            update === false
                              ? NewsImagePath
                              : userImage?.name
                              ? NewsImagePath
                              : PhotoPath + userImage
                          }
                          alt="Image Thumbnail"
                          width="30%"
                          height="30"
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {Error.userImage}
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <Row className="mb-3">
                    <Form.Group
                      className="col-lg-4 col-md-3 col-sm-12"
                      controlId="formGridEmail"
                    >
                      <Form.Label className="non-bold-label">
                        ID front Image{" "}
                        <span
                          className="required"
                          style={{ color: "red", fontSize: "1rem" }}
                        >
                          {" "}
                          *{" "}
                        </span>
                      </Form.Label>

                      <input
                        className="img-control"
                        ref={inputRefrence1}
                        type="file"
                        multiple
                        name="frontImg"
                        onChange={(event) =>
                          handleFileChange(event, "idfImages")
                        }
                        accept="image/*" // Optional: Limit file types to images
                        disabled={fileLimit}
                      />
                      <div style={{ color: "red", fontSize: 13 }}>
                        {Error?.frontImg}
                      </div>
                      <ImageList
                        sx={{
                          gridAutoFlow: "column",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(160px,160px)) !important",
                          gridAutoColumns: "minmax(160px, 160px)",
                          width: "100%",
                          height: "160px",
                        }}
                      >
                        {selectedFiles &&
                          Array.isArray(selectedFiles) &&
                          selectedFiles.map((item, ind) => {
                            let items = item;
                            console.log(items, "kk");
                            return (
                              <>
                                <ImageListItem key={item?.name}>
                                  {" "}
                                  <img
                                    src={
                                      item?.name === undefined
                                        ? imagePath + item
                                        : URL.createObjectURL(item)
                                    }
                                    loading="lazy"
                                    width="160px"
                                    height="160px"
                                    style={{ objectFit: "contain" }}
                                  />
                                  <IconButton
                                    aria-label="delete"
                                    sx={{
                                      position: "absolute",
                                      top: "0%",
                                      left: "70%",
                                    }}
                                  >
                                    <CloseButton
                                      // fontSize="small"
                                      onClick={() =>
                                        removeImg(ind, "idfImages")
                                      }
                                    />
                                  </IconButton>
                                </ImageListItem>
                              </>
                            );
                          })}
                      </ImageList>
                    </Form.Group>
                    <Form.Group
                      className="col-lg-4 col-md-3 col-sm-12"
                      controlId="formGridEmail"
                    >
                      <Form.Label className="non-bold-label">
                        ID back Image{" "}
                        <span
                          className="required"
                          style={{ color: "red", fontSize: "1rem" }}
                        >
                          {" "}
                          *{" "}
                        </span>
                      </Form.Label>

                      <input
                        className="img-control"
                        ref={inputRefrence2}
                        type="file"
                        name="backImg"
                        multiple
                        onChange={(event) =>
                          handleFileChange(event, "idbImages")
                        }
                        accept="image/*" // Optional: Limit file types to images
                        disabled={fileLimit}
                      />
                      <div style={{ color: "red", fontSize: 13 }}>
                        {Error?.backImg}
                      </div>
                      <ImageList
                        sx={{
                          gridAutoFlow: "column",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(160px,160px)) !important",
                          gridAutoColumns: "minmax(160px, 160px)",
                          width: "100%",
                          height: "160px",
                        }}
                      >
                        {selectedBackFiles &&
                          Array.isArray(selectedBackFiles) &&
                          selectedBackFiles?.map((item, ind) => {
                            let items = item;
                            return (
                              <>
                                <ImageListItem key={item?.name}>
                                  {" "}
                                  <img
                                    src={
                                      item?.name === undefined
                                        ? imagePath + item
                                        : URL.createObjectURL(item)
                                    }
                                    loading="lazy"
                                    width="160px"
                                    height="160px"
                                    style={{ objectFit: "contain" }}
                                  />
                                  <IconButton
                                    aria-label="delete"
                                    sx={{
                                      position: "absolute",
                                      top: "0%",
                                      left: "70%",
                                    }}
                                  >
                                    <CloseButton
                                      // fontSize="small"
                                      onClick={() =>
                                        removeImg(ind, "idbImages")
                                      }
                                    />
                                  </IconButton>
                                </ImageListItem>
                              </>
                            );
                          })}
                      </ImageList>
                    </Form.Group>

                    <Form.Group
                      className="col-lg-4 col-md-3 col-sm-12"
                      controlId="formGridEmail"
                    >
                      <Form.Label className="non-bold-label">
                        User Image{" "}
                        <span
                          className="required"
                          style={{ color: "red", fontSize: "1rem" }}
                        >
                          {" "}
                          *{" "}
                        </span>
                      </Form.Label>
                      <br />
                      <input
                        className="img-control"
                        ref={inputRefrence3}
                        type="file"
                        name="userImage1"
                        multiple
                        onChange={(event) =>
                          handleFileChange(event, "userImage")
                        }
                        accept="image/*" // Optional: Limit file types to images
                        disabled={fileLimit}
                      />
                      {/* <p>*Note: userImage should be Passport size image only.</p> */}
                      <div style={{ color: "red", fontSize: 13 }}>
                        {Error?.userImage1}
                      </div>
                      <ImageList
                        sx={{
                          gridAutoFlow: "column",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(160px,160px)) !important",
                          gridAutoColumns: "minmax(160px, 160px)",
                          width: "100%",
                          height: "160px",
                        }}
                      >
                        {selectedUserFiles &&
                          Array.isArray(selectedUserFiles) &&
                          selectedUserFiles?.map((item, ind) => {
                            let items = item;
                            return (
                              <>
                                <ImageListItem key={item?.name}>
                                  {" "}
                                  <img
                                    src={
                                      item?.name === undefined
                                        ? imagePath + item
                                        : URL.createObjectURL(item)
                                    }
                                    loading="lazy"
                                    width="160px"
                                    height="160px"
                                    style={{ objectFit: "contain" }}
                                  />
                                  <IconButton
                                    aria-label="delete"
                                    sx={{
                                      position: "absolute",
                                      top: "0%",
                                      left: "70%",
                                    }}
                                  >
                                    <CloseButton
                                      // fontSize="small"
                                      onClick={() =>
                                        removeImg(ind, "userImage")
                                      }
                                    />
                                  </IconButton>
                                </ImageListItem>
                              </>
                            );
                          })}
                      </ImageList>
                    </Form.Group>

                    {/* <div style={{ color: "red", fontSize: 13 }}>
                {error?.imagePreviews}
              </div> */}
                  </Row>

                  <div className="row d-flex justify-content-center pt-3">
                    <button
                      type="submit"
                      class="btn btn-primary col-sm-5"
                      onClick={EditEmployeeData}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmployee;

import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Pagination } from "@mui/material";
import axios from "../ServiceApi/AxiosInstance";
import SideNav from "../Sidebar/SideNav";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import {
  ReviewgetbyEmployerId,
  DeleteEmployee,
  getReview,
} from "../../Components/Api/AdminApi";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import Avatar from "@mui/material/Avatar";
import Auth from "../ServiceApi/Auth";
import NotFound from "../NotFound/NotFound";
import Loader from "../Loader/Loader";
import "bootstrap/dist/css/bootstrap.min.css"; //

const ReviewList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [ApiComplete, SetApiComplete] = useState(false);
  const itemsPerPage = 8; // Change this number based on your desired items per page
  const imagePath = "https://api.knowyourmaid.com/";
  const auth = Auth.getUserId();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getReviewList();
  }, []);

  const getReviewList = () => {
    setLoading(true);
    getReview()
      .then((res) => {
        if (res.status == 200) {
          setData(res.data.data);
        }
        // setService(res.data[0].services)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        SetApiComplete(true);
      });
  };

  const deleteEmployee = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteEmployee(id)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Employee Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getReviewList();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Employee Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash menu");
      });
  };

  return (
    <>
      <SideNav />
      {Loading && <Loader />}

      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="display-7 heading-h3 text-center">
              Review <span className="display-7 heading-h3-green">List</span>
            </h3>
            <Table striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Employer Name</th>
                  <th>Employee Name</th>
                  <th>Rating</th>
                  <th>Comments</th>
                  <th>Review Date</th>
                  {/* <th>Contact</th> */}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((list, index) => (
                  <tr key={list.id}>
                    <td>
                      {index + 1 + (currentPage * itemsPerPage - itemsPerPage)}
                    </td>
                    <td>{list.employer_name === null? "Admin":list.employee_name}</td>
                    <td>{list.employee_name}</td>

                    <td><Rating
                      // onClick={handleRating3}
                      readonly
                      initialValue={list.average_rating}
                      // onPointerEnter={onPointerEnter}
                      // onPointerLeave={onPointerLeave}
                      // onPointerMove={onPointerMove}
                      /* Available Props */
                    /></td>
                    <td>{list.comment}</td>
                    <td>{new Date(list.created_at).toLocaleDateString()}</td>



                    {/* <td width="200px" className="text-center">
                                            <Link to={`/Edit_Employee/${list.id}`}><IconButton type="btn btn-primary" title="edit">
                                                <EditTwoToneIcon />
                                            </IconButton></Link>
                                            <IconButton
                                                type="btn btn-primary"
                                                onClick={() => deleteEmployee(list.id)}
                                                title="delete"
                                            >
                                                <DeleteTwoToneIcon />
                                            </IconButton>
                                            <Link to="/Reviews" state={{ employeeid: list.id, } // Replace yourDataObject with the data you want to pass
                                            }><IconButton type="btn btn-primary" title="Review">
                                                    <RemoveRedEyeTwoToneIcon />
                                                </IconButton></Link>
                                        </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
            {data.length === 0 && ApiComplete ? <NotFound /> : null}
            <Pagination
              count={Math.ceil(data.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewList;

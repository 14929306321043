import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import axios from "../ServiceApi/AxiosInstance";
import Swal from "sweetalert2";
import SideNav from "../Sidebar/SideNav";
import { Table } from "react-bootstrap";
import { Pagination } from "@mui/material";
import {
  addService,
  getService,
  EditService,
  ServicegetById,
  DeleteService,
} from "../Api/AdminApi";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";

const ServiceType = () => {
  const [serviceName, setServiceName] = useState("");
  const [Description, setDescription] = useState("");
  const [data, setData] = useState([]);
  const [ID, setID] = useState("");
  const [update, setUpdate] = useState(false);
  const [Error, setError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const itemsPerPage = 5; // Change this number based on your desired items per page

  useEffect(() => {
    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let details = data.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(details);
  }, [currentPage]);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "%") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getServiceList();
  }, [searchValue]);

  const getServiceList = () => {
    getService(searchValue)
      .then((res) => {
        setData(res.data.results);
        setCurrentPage(1);
        let indexOfLastItem = 1 * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        let details = res.data.results.slice(indexOfFirstItem, indexOfLastItem);
        setCurrentItems(details);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    if (serviceName === "") {
      isValid = false;
      Error["serviceName"] = "Please Enter Name";
    }

    if (Description === "") {
      isValid = false;
      Error["Description"] = "Please Enter Description";
    }

    setError(Error);
    return isValid;
  };

  const submitService = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      const body = {
        service_name: serviceName,
        description: Description,
      };
      addService(body)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Service Type added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          getServiceList();
          cancelData();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Something went wrong.",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
        });
    }
  };

  useEffect(() => {
    ServicegetById(ID)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateList = (list) => {
    setUpdate(true);

    setID(list.id);
    setServiceName(list.service_name);
    setDescription(list.description);
  };

  const updateService = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      const data = {
        service_name: serviceName,
        description: Description,
      };
      // axios
      //   .put(`https://api.knowyourmaid.com/api/service-type/${ID}`,data)
      EditService(ID, data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Service Type Updated Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          getServiceList();
          cancelData();
          setUpdate(true);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Something went wrong.",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
        });
    }
  };

  const deleteList = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteService(id)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Service Type Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getServiceList();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Service Type Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash menu");
      });
  };

  const cancelData = () => {
    setServiceName("");
    setDescription("");
  };

  return (
    <>
      <SideNav />
      <div className="container mt-4">
        <div className="text-center">
          <h3 className="display-7 heading-h3">
            Service Type{" "}
            <span className="display-7 heading-h3-green">Form !</span>
          </h3>
        </div>
        <div className="row py-3 d-flex justify-content-center">
          <div className="col-sm-6">
            <div className="card form-border">
              <div className="card-body">
                <form className="row">
                  <div className="mb-3">
                    <label className="form-label">Service Name</label>
                    <input
                      class="form-control"
                      type="text"
                      value={serviceName}
                      onChange={(e) => {
                        setServiceName(e.target.value);
                        setError({ ...Error, serviceName: "" });
                      }}
                    ></input>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.serviceName}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Service Description</label>
                    <textarea
                      className="form-control"
                      value={Description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        setError({ ...Error, Description: "" });
                      }}
                    ></textarea>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Description}
                    </div>
                  </div>

                  <br />
                  {update == false ? (
                    <div className="row d-flex justify-content-center">
                      <button
                        type="submit"
                        class="btn btn-primary col-sm-5"
                        onClick={submitService}
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    <div className="row d-flex justify-content-center">
                      <button
                        type="submit"
                        class="btn btn-primary col-sm-5"
                        onClick={updateService}
                      >
                        Update
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col">
            <h3 className="display-7 heading-h3 text-center">
              Service <span className="display-7 heading-h3-green">List</span>
            </h3>

            <Col md={{ span: 3, offset: 9 }}>
              <Form.Group
                as={Row}
                className="justify-content-center"
                controlId="formHorizontalEmail"
              >
                <Col sm={12} className="mb-4">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Search
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      size="small"
                      placeholder="Search by Service"
                      name="searchValue"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      onKeyPress={handleKeyPress}
                      startAdornment={
                        <InputAdornment position="start">
                          <FiSearch />
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Col>
              </Form.Group>
            </Col>
            <div className="container mt-5">
              <Table striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>Sr.No.</th>

                    <th>Service Name</th>
                    <th>Description</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((list, index) => (
                    <tr key={list.id}>
                      <td>
                        {" "}
                        {index +
                          1 +
                          (currentPage * itemsPerPage - itemsPerPage)}
                      </td>
                      <td>{list.service_name}</td>
                      <td>{list.description}</td>

                      <td className="text-center">
                        <IconButton
                          type="btn btn-primary"
                          title="edit"
                          onClick={() => updateList(list)}
                        >
                          <EditTwoToneIcon />
                        </IconButton>
                        <IconButton
                          type="btn btn-primary"
                          title="Delete"
                          onClick={() => deleteList(list.id)}
                        >
                          <DeleteTwoToneIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Pagination
              count={Math.ceil(data.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceType;

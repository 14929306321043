import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
      <footer className='footer'>
      <div className="ftr-top">
        <Container>
          <Row className="d-flex justify-content-between">
            <Col sm={4}>
              <p className='footer-logo mb-0 text-start'>Know Your Maid </p>
              <div className='btm-border'></div>
              <div className='pt-4'>
                <p className='p-dark text-start'>
                Reliable Verification for Your Peace of Mind
 
                </p>
                {/* <p className='p-dark'>Information is power.</p> */}
              </div>
            </Col>
            <Col sm={5}>
              <div className='footer-grid'>
                <h5 className='p-dark text-center'>
                  Your one stop destination to get
                  your workers verified!
                </h5>
                <div className='pt-3 row d-flex justify-content-center'>
                 <Link to="/register"> <button className='dark-btn col-md-6'>Know More</button></Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='ftr-bottom'>
        <Container>
          <Row>
            <Col>
              <p className='mb-0 text-center'>Copyright By © Know Your Maid 2023-24</p>
            </Col>
          </Row>
        </Container>
      </div>
      </footer>
    </>
  )
}

export default Footer
import React, { useState, useEffect } from "react";
import Topbar from "../Home/Topbar";
import Footer from "../Home/Footer";
import { useParams } from "react-router-dom";
import { EmployeegetById } from "../Api/AdminApi";
import { Container } from "react-bootstrap";
import Loader from "../Loader/Loader";
const RedFlagEmployeeInfo = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");

  const { id } = useParams();

  useEffect(() => {
    getEmployeeInfo();
  }, []);

  const getEmployeeInfo = () => {
    setLoading(true);
    EmployeegetById(id)
      .then((res) => {
        setData(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openDoc = (id) => {
    EmployeegetById(id)
      .then((res) => {
        setFile(res.data.user.redFlagDoc);
     const pdfUrl = 'https://api.knowyourmaid.com/storage/redFlag_Document/0irxzQrt7uDUPZoPQyPqZy4PNJmZX8lwLx8Kxt9U.pdf';
     window.open(pdfUrl, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });

    
  };

  return (
    <>
      <Topbar />
      {loading && <Loader />}
      {/* {data &&
              data.map((list,key) => ( */}
      <Container className="py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row red-flag-box1">
              <div className="col-md-4 col-sm-12">
                <div>
                  <img
                    src="https://media.istockphoto.com/id/1153408113/photo/african-mother-holding-adopted-child.jpg?s=612x612&w=0&k=20&c=3B6IotvJdAYM430NNmhyMwagG6kJz2L23YFJcNB0rUY="
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-8 col-sm-12">
                <div>
                  <p className="emp-name">{data.full_name}</p>
                  <div className="mb-2">
                  <label>Address :</label> <span className="mx-2">{data.address_1}</span>
                  </div>
                  <div className="mb-2">
                  <label>Crime :</label> <span className="mx-2">{data.redFlag_desc}</span>
                  </div>
                  <div className="mb-2">
                  <label>Helpline Number :</label> <span className="mx-2">1852 1256 1212</span>
                  </div>
                 
                  
                 
                 
                </div>
                <div className="row">
                <button type="button" onClick={() => openDoc(data.id)} className="login-btn col-lg-4 col-md-6 col-sm-12" title="click to view documents">
                        View Documents
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* ))} */}
      <Footer />
    </>
  );
};

export default RedFlagEmployeeInfo;

import React, { useState , useEffect } from 'react'
import { Table, Col, Row, Form, } from "react-bootstrap";
import { Pagination } from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import Loader from "../Loader/Loader";
import SideNav from "../Sidebar/SideNav";
import NotFound from "../NotFound/NotFound";
import { redflagEmployeeList } from '../Api/AdminApi';
import ReportTwoToneIcon from "@mui/icons-material/ReportTwoTone";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

function RedFlagEmployeeList() {

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [Loading, setLoading] = useState(false);
    const [ApiComplete, SetApiComplete] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [currentItems, setCurrentItems] = useState([]);
    const itemsPerPage = 5;
    const imagePath = "https://api.knowyourmaid.com/";

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "%") {
          e.preventDefault();
        }
      };
    

  useEffect(() => {
    getEmployeeList();
  }, [searchValue]);

  useEffect(() => {
    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let details = data.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(details);
  }, [currentPage]);

    const getEmployeeList = () => {
        setLoading(true); // Show loader when the request starts
        redflagEmployeeList(searchValue)
          .then((res) => {
            if (res.data.status === 200) {
              setData(res.data.employees);
              setCurrentPage(1);
              let indexOfLastItem = 1 * itemsPerPage;
              let indexOfFirstItem = indexOfLastItem - itemsPerPage;
              let details = res.data.employees.slice(
                indexOfFirstItem,
                indexOfLastItem
              );
              setCurrentItems(details);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false); // Hide loader when the request is complete (success or error)
            SetApiComplete(true);
          });
      };

    return (
        <>
            <SideNav />

            <div className="row mt-5">
                <h3 className="display-7 heading-h3 text-center">
                    RedFlag Employee <span className="display-7 heading-h3-green">List</span>
                </h3>
                <Col md={{ span: 3, offset: 9 }}>
                    <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                    >
                        <Col sm={12} className="mb-4">
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Search
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type="text"
                                    size="small"
                                    placeholder="Search by Employee ID"
                                    name="searchValue"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <FiSearch />
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Col>
                    </Form.Group>
                </Col>
            </div>
            {Loading && <Loader />}

            <div className="container mt-4">
                <div className="row">
                    <div className="col-sm-12">
                        <Table striped bordered hover className='text-center'>
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    {/* <th>Contact Person Name</th> */}
                                    {/* <th>Contact Person No.</th> */}
                                    <th>Employee Id No.</th>
                                    <th>Contact</th>
                                    <th>Address</th>
                                    <th>Experience</th>
                                    <th>Service Type</th>
                                    <th>Image</th>
                                    <th>Red Flag</th>

                                    {/* <th>Delete</th>                                                      
            <th>Review</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((list, index) => (
                                    <tr key={list.id}>
                                        <td>
                                            {index + 1 + (currentPage * itemsPerPage - itemsPerPage)}
                                        </td>
                                        <td>{list.full_name}</td>
                                        <td>{list.email}</td>
                                        {/* <td>{list.contact_person}</td> */}
                                        {/* <td>{list.contact_per_num}</td> */}
                                        <td>{list.id_number}</td>
                                        <td>{list.contact_number}</td>
                                        <td>{list.address_1}</td>
                                        <td>{list.experience_in_years} Year</td>
                                        <td>{list.services.map((a) => a?.service_name + " ")}</td>
                                        <td className="text-center">
                                            <IconButton sx={{ p: 0 }}>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={imagePath + list.passportSize_img}
                                                    style={{ cursor: "default" }}
                                                />
                                            </IconButton>
                                        </td>
                                        <td>
                                            {list.red_flag == "Deactive" ? (
                                                <ReportTwoToneIcon color="success" />
                                            ) : (
                                                <ReportTwoToneIcon color="error" />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {data.length === 0 && ApiComplete ? <NotFound /> : null}
                        <Pagination
                            count={Math.ceil(data.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RedFlagEmployeeList
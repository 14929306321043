// import logo from "./logo.svg";
import React,{useState,useEffect} from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./Components/Routing";


import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  // const [isLoggedIn, setLoggedIn] = useState(false);

  // Set a timer for session timeout (15 minutes in this example)
  const sessionTimeout = 1 * 60 * 1000; // 15 minutes in milliseconds
  let timeoutId;



  const resetTimeout = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(sessionTimeout);
  };

  useEffect(() => {
    // Check if the user is already logged in (e.g., from localStorage)
    const authToken = localStorage.getItem('UserDetails');
    if (authToken) {
      // setLoggedIn(true);
      resetTimeout();
    }

    // Set up event listeners for user activity
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Routing />
      </Router>
    
    </div>
  );
};

export default App;

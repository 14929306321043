import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
// import Divider from '@mui/material/Divider';
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";


import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// import {MdOutlineArrowForwardIos} from 'react-icons/md';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Collapse from "@mui/material/Collapse";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { hasChildren } from "./utils";
// import HomeIcon from "@mui/icons-material/Home";
// import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ReceiptIcon from "@mui/icons-material/Receipt";
import QuizIcon from '@mui/icons-material/Quiz';
// import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import SupportIcon from "@mui/icons-material/Support";
// import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from "@mui/material/Tooltip";
// import AssessmentIcon from "@mui/icons-material/Assessment";
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import { FiLogOut } from 'react-icons/fi';
// import ReceiptIcon from '@mui/icons-material/Receipt';
import { FiLogOut } from "react-icons/fi";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import StreamIcon from "@mui/icons-material/Stream";
import ReportIcon from "@mui/icons-material/Report";
import SchoolIcon from "@mui/icons-material/School";
import SummarizeIcon from "@mui/icons-material/Summarize";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentsIcon from "@mui/icons-material/Payments";
// import Fade from "@mui/material/Fade";
// import ListItemButton from "@mui/material/ListItemButton";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Menu from "@mui/material/Menu";
import Auth from "../ServiceApi/Auth";
// import MenuItem from "@mui/material/MenuItem";
// import { MenuList } from "@mui/material";
// import InboxIcon from "@mui/icons-material/Inbox";
// import DraftsIcon from "@mui/icons-material/Drafts";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dot: {
    backgroundColor: "white",
    fontSize: "100%",
  },
}));

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
  [theme.breakpoints.down("sm")]: {
    width: 0,
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: 0,
    display: "none",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Logout = () => {
  Auth.logout();
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  overflow: "auto",
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundImage:
    "url(https://img.freepik.com/free-photo/abstract-dark-blurred-background-smooth-gradient-texture-color-shiny-bright-website-pattern-banner-header-sidebar-graphic-art-image_1258-82968.jpg?size=626&ext=jpg&uid=R46475531&ga=GA1.2.742989516.1658734392)",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNav = () => {
  // notification state

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [token,setToken] = useState("");
  const [mail,setMail] = useState("");

  

  useEffect(()=>{
    let mail = Auth.getEmail();
    setMail(mail)
    const token = Auth.getToken();
    setToken(token)

  },[]);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isOpen, setIsOpen] = useState(false);
  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [Access, setAccess] = useState([]);
  const history = useNavigate();
  const [counter, setCounter] = useState(0);
  const classes = useStyles();
  const [allNotification, setAllNotification] = useState([]);

  const role = Auth.getRole();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const items = [
    {
      icon: <DashboardIcon className="sd-icons" />,
      title: "Dashboard",
      to: "/dashboard",
    },
    {
      icon: <GroupIcon className="sd-icons" />,
      title: "Employment",
      items: [
       

        {
          icon: <DoubleArrowIcon className="sd-icons" />,
          title: "Employee",
          to: "/Employee",
        },
        // {
        //   icon: <DoubleArrowIcon className="sd-icons" />,
        //   title: "Employee",
        //   to: "/Employee",
        // },

        {
          icon: <DoubleArrowIcon className="sd-icons" />,
          title: "Employee List",
          to: "/Employee_List",
        },

        {
          icon: <DoubleArrowIcon className="sd-icons" />,
          title: "Employer List",
          to: "/Employer_List",
        },
      ],
    },
    {
      icon: <CleaningServicesIcon className="sd-icons" />,
      title: "Service",
      items: [
        {
          icon: <DoubleArrowIcon className="sd-icons" />,
          title: "Service Type",
          to: "/Service_Type",
        },
        // {
        //   icon: <DoubleArrowIcon className="sd-icons" />,
        //   title: "Service Type List",
        //   to: "/Service_Type_List",
        // },
      ],
    },

    {
      icon: <PaymentsIcon className="sd-icons" />,
      title: "Review List",
      to: "/Review_List",
      //   items: [
      //     {
      //       icon: <DoubleArrowIcon className="sd-icons" />,
      //       title: "Exam Room",
      //       to: "/exam-room-form",
      //     },
      //     {
      //       icon: <DoubleArrowIcon className="sd-icons" />,
      //       title: "Exam Type Master",
      //       to: "/exam-type-master",
      //     },
      //     {
      //       icon: <DoubleArrowIcon className="sd-icons" />,
      //       title: "Exam Master",
      //       to: "/exam-master",
      //     },
      //     {
      //       icon: <DoubleArrowIcon className="sd-icons" />,
      //       title: "Exam class-room Master",
      //       to: "/exam-room-details",
      //     },
      //     {
      //       icon: <DoubleArrowIcon className="sd-icons" />,
      //       title: "Time-table",
      //       to: "/time-table",
      //     },
      //     {
      //       icon: <DoubleArrowIcon className="sd-icons" />,
      //       title: "Duty Chart",
      //       to: "/exam-duty-chart",
      //     },
      //     {
      //       icon: <DoubleArrowIcon className="sd-icons" />,
      //       title: "Notice",
      //       to: "/notice",
      //     },
      //   ],
    },

    {
      icon: <ReceiptIcon className="sd-icons" />,
      title: "Add Video",
      to: "/Add_Video",
    },
    {
      icon: <QuizIcon className="sd-icons" />,
      title: "Add FAQ",
      to: "/Add_FAQ",
    },

    {
      icon: <ReceiptIcon className="sd-icons" />,
      title: "Contact us List",
      to: "/ContactUs_List",
    },
    // {
    //   icon: <ReportIcon className="sd-icons" />,
    //   title: "Reports",
    //   to: "/report",
    // },
  ];

  const SingleLevel = ({ item }) => {
    return (
      <ListItem component={Link} to={item.to} button>
        <Tooltip title={item.title}>
          <ListItemIcon>{item.icon}</ListItemIcon>
        </Tooltip>
        <ListItemText className="sidemenu-text" primary={item.title} />
      </ListItem>
    );
  };

  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    const SingleLevel = ({ item }) => {
      return (
        <ListItem component={Link} to={item.to} button>
          <Tooltip title={item.title}>
            <ListItemIcon>{item.icon}</ListItemIcon>
          </Tooltip>
          <ListItemText className="sidemenu-text" primary={item.title} />
        </ListItem>
      );
    };

    const MultiLevel = ({ item }) => {
      const { items: children } = item;
      const [open, setOpen] = useState(false);

      const handleClick = () => {
        setOpen((prev) => !prev);
      };

      return (
        <React.Fragment>
          <ListItem button onClick={handleClick}>
            <Tooltip title={item.title}>
              <ListItemIcon>{item.icon}</ListItemIcon>
            </Tooltip>
            <ListItemText primary={item.title} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((child, key) => (
                <MenuItem
                  component={Link}
                  to={child.to}
                  key={key}
                  item={child}
                />
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    };

    const MenuItem = ({ item }) => {
      const Component = hasChildren(item) ? MultiLevel : SingleLevel;
      return <Component item={item} />;
    };

    // http://localhost:10050/exam-duty?fetch_type=all_faculty_child_data

    // (function () {
    //   setTimeout(() => {
    //     axios({
    //       method: "GET",
    //       url: "/exam-duty",
    //       params: {
    //         fetch_type: "all_faculty_child_data"
    //       }
    //     }).then((res) => {
    //       // debugger
    //       let a = res?.data?.data
    //       // setNotificationData(a);
    //       const b = a.filter((data1) => data1?.Admin_Notification == "true")
    //       setAllNotification(b)
    //       console.log(a, "bbbbb");
    //       setCounter(b.length)
    //       console.log(res, "notification");

    //     });
    //   }, 600000)
    // })();

    return (
      <React.Fragment>
        <ListItem button onClick={handleClick}>
          <Tooltip title={item.title}>
            <ListItemIcon>{item.icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={item.title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MenuItem component={Link} to={child.to} key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  const handleNotification = (data) => {
    if (data) {
      history("/exam-duty-list");
    }
  };

  // const listItemStyle = {
  //   backgroundColor: '#FFD3A3', // Replace with your desired background color
  //   color: 'black', // Replace with your desired text color
  // };

  // const listItemStyleYellow = {
  //   backgroundColor: '#FCFFB2', // Replace with your desired background color
  //   color: 'black', // Replace with your desired text color
  // };
  const listItemStyleBlue = {
    backgroundColor: "#CDF0EA", // Replace with your desired background color
    color: "black", // Replace with your desired text color
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{ backgroundColor: "#007CFB" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 2,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Know Your Maid
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {/* <IconButton sx={{ p: 0 }}>
                <Avatar
                  alt="Remy Sharp"
                  src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
                />
              </IconButton> */}
              {auth && (
                <div className="mx-3">
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    {/* <Badge   variant="dot"  classes={{ badge: classes.dot }}>
                  
                      <NotificationsIcon
                        style={{ fontSize: "30px" }}
                        className="notify-icon"
                      />
                    </Badge> */}
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{
                      top: "5%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        minWidth: "500px",
                        bgcolor: "background.paper",
                        height: "200px",
                      }}
                    >
                      <nav aria-label="main mailbox folders">
                        <List>
                          {allNotification &&
                            allNotification.map((data) => {
                              return (
                                <>
                                  <ListItem
                                    alignItems="flex-start"
                                    style={listItemStyleBlue}
                                  >
                                    <ListItemAvatar>
                                      <Avatar
                                        alt={data?.faculty_name?.faculty_name}
                                        src="/static/images/avatar/1.jpg"
                                      />
                                    </ListItemAvatar>

                                    <ListItemText
                                      sx={{ marginTop: "13px" }}
                                      onClick={() => handleNotification(data)}
                                      primary={
                                        "Faculty name : " +
                                        " " +
                                        data?.faculty_name?.faculty_name +
                                        " " +
                                        data?.faculty_name?.Middle_name +
                                        " " +
                                        data?.faculty_name?.Last_name +
                                        " ,Exam Title : " +
                                        data.exam_duty?.exam?.Exam_title
                                      }
                                      // secondary={
                                      //   <React.Fragment>
                                      //     <Typography
                                      //       sx={{ display: "inline" }}
                                      //       component="span"
                                      //       variant="body2"
                                      //       color="text.primary"
                                      //     >
                                      //       Duty Status: {data?.Duty_Status}
                                      //     </Typography>
                                      //     &nbsp;Exam date {
                                      //       data?.exam_duty?.Exam_date
                                      //     }
                                      //   </React.Fragment>
                                      // }
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </>
                              );
                            })}
                        </List>
                      </nav>
                    </Box>
                  </Menu>
                </div>
              )}

          
                {role === 2 ? "(Employer)" : role === 3 ? "(Employee)" : role === 4 ? "(Visitor)" : "(Admin)"}
                <Link to="/" style={{ color: "white",textDecoration:'none' }}>
                <IconButton onClick={() => Logout()} title="Logout">
                  <PowerSettingsNewIcon fontSize="25px" color="#fff" />{" "}
                </IconButton>
              </Link>
            </Typography>
          </Toolbar>
        </AppBar>

        <Box component="main" sx={{ flexGrow: 1, px: 1, pt: -2 }}>
          <DrawerHeader />
          {/* dashboard ui */}

          <Drawer variant="permanent" open={open} sx={{ overflowY: "auto" }}>
            <DrawerHeader>
              {/* EIS */}
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            {/* <Divider /> */}

            {items.map((item, key) => (
              <MenuItem key={key} item={item} />
            ))}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

export default SideNav;

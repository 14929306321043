import React, { useEffect, useState } from "react";
//packages
import Swal from "sweetalert2";
import { Pagination } from "@mui/material";
import { Table } from "react-bootstrap";
//icons
import IconButton from "@mui/material/IconButton";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
// custom components
import SideNav from "../Sidebar/SideNav";
import Loader from "../Loader/Loader";
// api
import {
  AddQuestionAnswer,
  getQuestionsAnswers,
  getQuestionsAnswersById,
  EditQuestionAnswer,
  DeleteQuestionAnswer,
} from "../Api/AdminApi";
//auth
import Auth from "../ServiceApi/Auth";

const Add_FAQ = () => {
  const userId = Auth.getUserId();
  const [Loading, setLoading] = useState(false);
  const [Question, setQuestion] = useState("");
  const [Answer, setAnswer] = useState("");
  const [data, setData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [FAQId, setFAQId] = useState("");
  const [error, setError] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    getQuestionList();
  }, []);

  //pagination
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  //validation
  const ValidateForm = () => {
    let Error = {};
    let isValid = true;
    if (Question === "") {
      isValid = false;
      Error["Question"] = "Please enter Question.";
    }
    if (Answer === "") {
      isValid = false;
      Error["Answer"] = "Please enter Answer.";
    }
    setError(Error);
    return isValid;
  };

  //handleClear
  const handleClear = () => {
    setQuestion("");
    setAnswer("");
    setError("");
  };

  // get All question list
  const getQuestionList = () => {
    setLoading(true);
    getQuestionsAnswers()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data);
          setLoading(false);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Data not found!",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "error in FAQ list");
        setLoading(false);
      });
  };

  // Add question functinality
  const submitFAQ = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      const data = {
        createdUserID: userId,
        question: Question,
        answer: Answer,
      };
      AddQuestionAnswer(data)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Question added successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            handleClear();
            getQuestionList();
          } else {
            Swal.fire({
              icon: "warning",
              title: "Data not added, Please try again!",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Question Not Added",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        });
    }
  };

  // edit question list
  const editQuestionList = (id) => {
    setLoading(true);
    getQuestionsAnswersById(id)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          setQuestion(result.question);
          setAnswer(result.answer);
          setFAQId(result.id);
          setLoading(false);
          setShowEdit(true);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Data not found!",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "error in FAQ update");
        setLoading(false);
      });
  };

  const UpdateFAQ = (e, id) => {
    e.preventDefault();
    if (ValidateForm()) {
      const updateData = {
        createdUserID: userId,
        question: Question,
        answer: Answer,
      };
      setLoading(true);
      EditQuestionAnswer(id, updateData)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Data Updated Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            setLoading(false);
            setShowEdit(false);
            handleClear();
            getQuestionList();
          } else {
            Swal.fire({
              icon: "warning",
              title: "Data Not Updated Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Data Not Updated Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          setLoading(false);
        });
    }
  };

  const deleteQuestionList = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteQuestionAnswer(id)
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Data Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              getQuestionList();
            } else {
              Swal.fire({
                icon: "warning",
                title: "Data Not Deleted!",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "error in FAQ delete.");
            Swal.fire({
              icon: "error",
              title: "Data Not Deleted",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          });
      }
    });
  };

  return (
    <>
      <SideNav />
      {Loading && <Loader />}
      <div class="container mt-4">
        <div className="text-center">
          <h3 className="display-7 heading-h3">
            Add<span className="display-7 heading-h3-green"> FAQ !</span>
          </h3>
        </div>

        <div className="row d-flex justify-content-center pt-3">
          <div className="col-sm-6">
            <div className="card form-border">
              <div className="card-body">
                <form>
                  <div className="col-sm-12">
                    <label for="Question" className="form-label">
                      Add Question
                    </label>
                    <input
                      value={Question}
                      type="text"
                      name="Question"
                      className="form-control"
                      onChange={(e) => {
                        setQuestion(e.target.value.trimStart());
                        setError({ ...error, Question: "" });
                      }}
                    />
                  </div>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {error.Question}
                  </div>
                  <div className="col-sm-12">
                    <label for="Answer" className="form-label">
                      Add Answer
                    </label>
                    <textarea
                      rows="5"
                      value={Answer}
                      // type="text"
                      name="Question"
                      className="form-control"
                      onChange={(e) => {
                        setAnswer(e.target.value.replace(/(\n\s*)+/g, '\n').trimStart());
                        setError({ ...error, Answer: "" });
                      }}
                    />
                  </div>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {error.Answer}
                  </div>
                  <br />

                  <div className="row d-flex justify-content-center">
                    {showEdit ? (
                      <button
                        type="submit"
                        class="btn btn-primary col-sm-5"
                        onClick={(e)=>UpdateFAQ(e,FAQId)}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="submit"
                        class="btn btn-primary col-sm-5"
                        onClick={submitFAQ}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col">
            <h3 className="display-7 heading-h3 text-center">
              FAQ <span className="display-7 heading-h3-green">List</span>
            </h3>
            <div className="container mt-5">
              <Table striped bordered hover className="text-left">
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Action</th>

                    {/* <th className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((list, index) => (
                    <tr key={list.id}>
                      <td>
                        {index +
                          1 +
                          (currentPage * itemsPerPage - itemsPerPage)}
                      </td>
                      <td width="30%" style={{ whiteSpace: 'pre-wrap' }}>{list.question}</td>
                      <td width="70%" style={{ whiteSpace: 'pre-wrap' }} >{list.answer}</td>
                      <td>
                        <IconButton
                          type="btn btn-primary"
                          onClick={() => editQuestionList(list.id)}
                          title="edit"
                        >
                          <EditTwoToneIcon />
                        </IconButton>

                        <IconButton
                          type="btn btn-primary"
                          onClick={() => deleteQuestionList(list.id)}
                          title="delete"
                        >
                          <DeleteTwoToneIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Pagination
              count={Math.ceil(data.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Add_FAQ;

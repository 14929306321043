import React, { useState, useEffect } from "react";
import Topbar from "../Home/Topbar";
import Auth from "../ServiceApi/Auth";
import { getUserById } from "../Api/AdminApi";
import { Container, Row, Col, Card ,Button} from "react-bootstrap";
import { IoPersonCircle } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { MdPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import Image from 'react-bootstrap/Image';

const VisitorProfile = () => {
  const [data, setData] = useState([]);

  const auth = Auth.getUserId();

  // console.log(Id,"ppp")
  // console.log(userId,"fdfs")

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    getUserById(auth)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Topbar />
      <Container>
        <div className="pt-4"> 
        <h3 className=" text-center display-6 heading-h3">
          Visitor <span className="display-6 heading-h3-green">Profile</span>
        </h3>
        </div>

        <Row className="d-flex justify-content-center py-5">
          <div className="col-lg-7 col-md-8 col-sm-12">
            <div className="visitor-form">
              <div className="prof-image text-center pb-3">
              <Image src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=626&ext=jpg&ga=GA1.1.670526873.1688381618&semt=sph" roundedCircle width={150} />
              </div>
            <div className="row">
              <div className="col-lg-12"> 
              <p><span><IoPersonCircle fontSize={25} color="grey" /></span> Visitor Name: {data.full_name}</p>
              </div>
             <div className="col-lg-12">
             <p><span><IoMdMail fontSize={23} color="grey" /></span> Visitor Email: {data.email}</p>
             </div>
             <div className="col-lg-12">  
             <p><span><MdPhone fontSize={23} color="grey" /></span> Visitor Contact: {data.contact_number}</p>
             </div>
             <div className="col-lg-12">
             <p><span><IoLocationSharp fontSize={23} color="grey" /></span> Visitor Address : {data.address}</p>
             </div>
              <div className="text-center"> 
                <Button className="btn btn-primary col-sm-4">Update</Button>
              </div>
            </div>
            </div>
            
          </div>
        </Row>
      </Container>
    </>
  );
};

export default VisitorProfile;

import axios from "axios";
import URlFile from "../ServiceApi/Server";
import Auth from "./Auth";
import Swal from "sweetalert2";

let headers = {};
const axiosInstance = axios.create({
  baseURL: URlFile.EmployeeURl,
  headers,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await Auth.getToken();
    if (token) {
      config.headers = {
        'Content-Type' : "multipart/form-data",
        'Authorization' : `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    // console.log("interceptors response", response);
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        localStorage.setItem('token', access_token);
        axiosInstance.defaults.headers.common["Authorization"] = access_token;
        return axiosInstance.request(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;


const refreshAccessToken = async () => {
    const token = await Auth.getToken();

    let headers = {
        headers:{
            'Content-Type': 'multipart/form-data',
            'Authorization' : `Bearer ${token}`,
        }
    };
    return new Promise((resolve, reject) => {
        axiosInstance.post(`https://api.knowyourmaid.com/api/refresh-token`, headers)
        .then(async response =>{
            debugger
            resolve(response.data.refresh_token);
            console.log(response,"anku...kdkk");
        }).catch(error =>{
            Swal.fire({
                title:"Session expired",
                text:"You have been logged out",
                icon:"error",
                button:"Ok",
            }).then(()=>{
                Auth.logout()
                window.location.replace('/login')
            })
         reject(error);
        })
    })
};


import React, { useState, useEffect } from "react";
import axios from "axios";
import SideNav from "../Sidebar/SideNav";
import { AddReview, getEmployer } from "../Api/AdminApi";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Auth from "../ServiceApi/Auth";
import EmployerSidebar from "../Sidebar/EmployerSidebar";
import { Rating } from "react-simple-star-rating";
import Avatar from "@mui/material/Avatar";

const Review = () => {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  // const [Rating, setRating] = useState("");
  const [Comment, setComment] = useState("");
  const [EmployerName, setEmployerName] = useState("");
  const [EmployerOption, setEmployerOption] = useState([]);
  const [Error, setError] = useState("");
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);
  const [rating5, setRating5] = useState(0);
  const [rating6, setRating6] = useState(0);
  const [rating7, setRating7] = useState(0);

  console.log(rating1, "rating one");

  const { state } = useLocation();

  const auth = Auth.getUserId();
  const role = Auth.getRole();
  const Employer = Auth.getEmployerID();

  const Navigate = useNavigate();

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    if (rating1 === 0) {
      isValid = false;
      Error["rating1"] = "Please Enter Rating";
    }
    if (rating1 === 0) {
      isValid = false;
      Error["rating2"] = "Please Enter Rating";
    }
    if (rating1 === 0) {
      isValid = false;
      Error["rating3"] = "Please Enter Rating";
    }

    if (rating1 === 0) {
      isValid = false;
      Error["rating4"] = "Please Enter Rating";
    }
    if (rating1 === 0) {
      isValid = false;
      Error["rating5"] = "Please Enter Rating";
    }
    if (rating1 === 0) {
      isValid = false;
      Error["rating6"] = "Please Enter Rating";
    }
    if (rating1 === 0) {
      isValid = false;
      Error["rating7"] = "Please Enter Rating";
    }
    if (Comment === "") {
      isValid = false;
      Error["Comment"] = "Please Enter Comment";
    }

    setError(Error);
    return isValid;
  };

  // useEffect(() => {
  //   getEmployerList();
  // }, []);

  // const getEmployerList = () => {
  //   getEmployer().then((res) => {
  //     console.log(res, "get response");
  //     const list = res.data;
  //     const newArray = list.map((a) => ({
  //       value: a.id,
  //       label: a.full_name,
  //     }));
  //     setEmployerOption(newArray);
  //   });
  // };

  const submitReview = (e) => {
    e.preventDefault();
    if (ValidateForm()) {
      debugger
      const body = {
        employee_id: state.employeeid,
        comments: Comment,
        employer_id: Employer,
        reliability: rating1,
        trustworthiness: rating2,
        hardworking: rating3,
        transparency: rating4,
        self_motivated: rating5,
        teamwork: rating6,
        innovativeness: rating7,
        // average_rating: 2,
      };
      AddReview(body)
        .then((res) => {
          if (res.data.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Thanks for the Review",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            resetData();

            if (role == 2) {
              Navigate("/Employee_Review_List");
            } else {
              Navigate("/Review_List");
            }
          } else if (res.data.status == 400) {
            Swal.fire({
              icon: "error",
              title: res.data.error,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })

        .catch((err) => {
          const errordata = err.data.status;
          console.log(errordata, "pppp");
          Swal.fire({
            icon: "error",
            title: "Something went Wrong",
            showConfirmButtdataon: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
        });
    }
  };

  const handleRating1 = (rate) => {
    setRating1(rate);
    setError({...Error, rating1:""})

    // other logic
  };
  const handleRating2 = (rate) => {
    setRating2(rate);
    setError({...Error, rating2:""})


    // other logic
  };
  const handleRating3 = (rate) => {
    setRating3(rate);
    setError({...Error, rating3:""})


    // other logic
  };
  const handleRating4 = (rate) => {
    setRating4(rate);
    setError({...Error, rating4:""})


    // other logic
  };
  const handleRating5 = (rate) => {
    setRating5(rate);
    setError({...Error, rating5:""})


    // other logic
  };
  const handleRating6 = (rate) => {
    setRating6(rate);
    setError({...Error, rating6:""})


    // other logic
  };
  const handleRating7 = (rate) => {
    setRating7(rate);
    setError({...Error, rating7:""})


    // other logic
  };

  const resetData = () => {
    setRating1("");
    setRating2("");
    setRating3("");
    setRating4("");
    setRating5("");
    setRating6("");
    setRating7("");
    setComment("");
  };

  return (
    <>
      {role == 2 ? <EmployerSidebar /> : <SideNav />}
      {/* <div class="container mt-4">
        <div className="text-center">
          <h3 className="display-7 heading-h3">
            Write a <span className="display-7 heading-h3-green">Review !</span>
          </h3>
        </div>
        <div className="row d-flex justify-content-center pt-3">
          <div className="col-sm-6">
            <div className="card form-border">
              <div className="card-body">
                <form action="/submit_review" method="POST">
                
                  <div class="form-group mb-3">
                    <label for="rating" className="form-label">
                      Rating
                    </label>
                    <select
                      class="form-control"
                      id="rating"
                      name="rating"
                      value={Rating}
                      onChange={(e) => {
                        setRating(e.target.value);
                        setError({ ...Error, Rating: "" });
                      }}
                    >
                      <option value="Select">Select</option>
                      <option value="Very Good">Very Good</option>
                      <option value="Good">Good</option>
                      <option value="Fair">Fair</option>
                      <option value="Poor">Poor</option>
                    </select>
                  </div>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {Error.Rating}
                  </div>
                  <div class="form-group mb-3">
                    <label for="review" className="form-label">
                      Your Review
                    </label>
                    <textarea
                      class="form-control"
                      id="review"
                      name="review"
                      rows="4"
                      value={Comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                        setError({ ...Error, Comment: "" });
                      }}
                    ></textarea>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.Comment}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-primary col-sm-5"
                      onClick={submitReview}
                    >
                      Submit Review
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="container mt-4 py-5">
        <div className="text-center">
          <div className="row d-flex justify-content-center pt-3">
            <div className="col-sm-6">
              <div className="card form-border">
                <div className="card-body text-start">
                  <h5 className="d-flex">
                    <Avatar
                      alt="Travis Howard"
                      src="https://png.pngtree.com/png-vector/20190223/ourmid/pngtree-vector-avatar-icon-png-image_695765.jpg"
                    />{" "}
                    <span className="mx-2 pt-2">
                      Rate the employee on the scale of 1-5 🌟
                    </span>
                  </h5>
                  <br />
                  <div className="review-section">
                    <div className="d-flex justify-content-between border p-2 mb-3">
                      <p className="p-rating">Reliability :</p>
                      <Rating
                        className="rating-stars"
                        onClick={handleRating1}
                        initialValue={rating1}
                        // onPointerEnter={onPointerEnter}
                        // onPointerLeave={onPointerLeave}
                        // onPointerMove={onPointerMove}
                        /* Available Props */
                      />
                    </div>
                     <div style={{ color: "red", fontSize: 13 }}>
                        {Error.rating1}
                      </div>
                    <div className="d-flex justify-content-between border p-2 mb-3">
                      <p className="p-rating"> Trustworthiness : </p>
                      <Rating
                        onClick={handleRating2}
                        initialValue={rating2}
                        // onPointerEnter={onPointerEnter}
                        // onPointerLeave={onPointerLeave}
                        // onPointerMove={onPointerMove}
                        /* Available Props */
                      />
                    </div>
                    <div style={{ color: "red", fontSize: 13 }}>
                        {Error.rating2}
                      </div>
                    <div className="d-flex justify-content-between border p-2 mb-3">
                      <p className="p-rating"> Hardworking : </p>
                      <Rating
                        onClick={handleRating3}
                        initialValue={rating3}
                        // onPointerEnter={onPointerEnter}
                        // onPointerLeave={onPointerLeave}
                        // onPointerMove={onPointerMove}
                        /* Available Props */
                      />
                    </div>
                    <div style={{ color: "red", fontSize: 13 }}>
                        {Error.rating3}
                      </div>
                    <div className="d-flex justify-content-between border p-2 mb-3">
                   <p className="p-rating"> Transparency :</p>
                    <Rating
                      onClick={handleRating4}
                      initialValue={rating4}
                      // onPointerEnter={onPointerEnter}
                      // onPointerLeave={onPointerLeave}
                      // onPointerMove={onPointerMove}
                      /* Available Props */
                    />
                    </div>
                    <div style={{ color: "red", fontSize: 13 }}>
                        {Error.rating4}
                      </div>
                    
                   <div className="d-flex justify-content-between border p-2 mb-3">
                   <p className="p-rating">self-motivated :</p>
                    <Rating
                      onClick={handleRating5}
                      initialValue={rating5}
                      // onPointerEnter={onPointerEnter}
                      // onPointerLeave={onPointerLeave}
                      // onPointerMove={onPointerMove}
                      /* Available Props */
                    />
                   </div>
                   <div style={{ color: "red", fontSize: 13 }}>
                        {Error.rating5}
                      </div>
                   
                   <div className="d-flex justify-content-between border p-2 mb-3">
                  <p className="p-rating"> Teamwork :</p>
                    <Rating
                      onClick={handleRating6}
                      initialValue={rating6}
                      // onPointerEnter={onPointerEnter}
                      // onPointerLeave={onPointerLeave}
                      // onPointerMove={onPointerMove}
                      /* Available Props */
                    />
                   </div>
                   <div style={{ color: "red", fontSize: 13 }}>
                        {Error.rating6}
                      </div>
                   
                    <div className="d-flex justify-content-between border p-2 mb-3">
                   <p className="p-rating"> Innovativeness :</p>
                    <Rating
                      onClick={handleRating7}
                      initialValue={rating7}
                      // onPointerEnter={onPointerEnter}
                      // onPointerLeave={onPointerLeave}
                      // onPointerMove={onPointerMove}
                      /* Available Props */
                    />
                    </div>
                    <div style={{ color: "red", fontSize: 13 }}>
                        {Error.rating7}
                      </div>
                   
                   
                    <div class="form-group mb-3">
                      <label for="review" className="form-label">
                        Comment
                      </label>
                      <textarea
                        class="form-control"
                        id="review"
                        name="review"
                        rows="3"
                        value={Comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                          setError({ ...Error, Comment: "" });
                        }}
                      ></textarea>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {Error.Comment}
                      </div>
                    </div>
                  </div>
                  
                  <div className="row d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-primary col-sm-5"
                      onClick={submitReview}
                    >
                      Submit Review
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;

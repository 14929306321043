class Auth {
    constructor() {
        this.data = localStorage.getItem('UserDetails') || null
        if(this.data !== null){
            this.user_token = JSON.parse(this.data);
        }else{
            this.user_token = null;
        }
        console.log(this.user_token,"data...")
    }
    getToken() {
        if ((this.user_token !== null)) {
            return this.user_token.token
        }
    }
    getRefreshToken() {
        if(this.user_token !== null) {
          return this.user_token.refresh_token;
        }
    }
    getRole() {
        if ((this.user_token !== null)) {
            return this.user_token.role
        }
    }
    getMobileNo(){
        if(this.user_token !== null) {
            return this.user_token.contact_number
            ;
          } 
    }

    getEmail() {
        if ((this.user_token !== null)) {
            return this.user_token.email
        }
    }

        
    getEmployerID() {
        if ((this.user_token !== null)) {
            return this.user_token.employerID
        }
    }
 

    getEmployeeID() {
        if ((this.user_token !== null)) {
            return this.user_token.employeeID
        }
    }

    logout() {
        localStorage.removeItem('UserDetails');
        window.location.href = "/";
    }

    getUserId() {
        if ((this.user_token !== null)) {

            return this.user_token.userId
        }
    }

    getName(){
        if ((this.user_token !== null)) {
            return this.user_token.Name
        }

    }

    getContact() {
        if ((this.user_token !== null)) {
            return this.user_token.contact_number
        }
    }
}
export default new Auth()


import React, { useState, useEffect } from "react";
import axios from "axios";
import Topbar from "../Home/Topbar";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import Staff from "../../assets/images/login-illustration-kym.jpg";
import Swal from "sweetalert2";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Auth from "../ServiceApi/Auth";
import Loader from "../Loader/Loader";
import { Modal } from "react-bootstrap";

const Login = () => {
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otpPhone, setOtpPhone] = useState("");
  const [countdown, setCountdown] = useState(30); 
  const [resendAttempts, setResendAttempts] = useState(0);
  const [rolenew, setRoleNew] = useState("");
  const [Phone, setPhone] = useState("");
  const [Error, setError] = useState([]);
  const [cardNo,setCardNo] = useState(0);

  const navigate = useNavigate();
  const auth = Auth.getContact();
  const role = Auth.getRole();

  useEffect(()=>{
    const contact_number = Auth.getMobileNo();
    setCardNo(contact_number)
  },[Auth])

  const handleClose = () => setShowOtp(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  
 

  const ValidateOtpForm = () => {
    let Error = {};
    let isValid = true;
    if (showOtp === true) {
      if (otp === "") {
        isValid = false;
        Error["otp"] = "Please Enter OTP";
      }
    }
    setError(Error);
    return isValid;
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (ValidateOtpForm()) {
      const body = {
        contact_number: otpPhone,
        otp: otp,
      };
      axios
        .post(`https://api.knowyourmaid.com/api/verify-otp`, body)
        .then((res) => {
          if (res.status === 200) {
            if (role && role === 3) {
              Swal.fire({
                icon: "success",
                title: "OTP Verified Successfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              setSendOtp(false);
              setShowOtp(false);
              setRoleNew("");
              setOtpPhone("")
              setCountdown(30);
              window.location.href = "/login";
            } else {
              Swal.fire({
                icon: "success",
                title: "OTP Verified Successfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
              setSendOtp(false);
              setShowOtp(false);
              setRoleNew("");
              setOtpPhone("")
              setCountdown(30);
              // navigate("/Payment" );
              navigate('/Payment', { state: { "cardNo": cardNo } });
            }
          } else if (res.status === 401) {
            Swal.fire({
              icon: "error",
              title: "Please enter a valid OTP.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Please enter a valid OTP.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              title: err.response.data.error,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          } else if (err.response.status === 422) {
            Swal.fire({
              icon: "error",
              title: err.response.data.error.otp,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        });
    }
  };

  const ValidateForm = () => {
    let Error = {};
    let isValid = true;

    let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let contactregex = /^[0-9]+$/i;

    if (userName === "") {
      isValid = false;
      Error["userName"] = "Please Enter Mobile No.";
    } else if (contactregex.test(userName) === false) {
      isValid = false;
      Error["userName"] = "Please Enter Valid Mobile No.";
    }

    if (password === "") {
      isValid = false;
      Error["password"] = "Please Enter Password";
    }

    setError(Error);
    return isValid;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onForgotPass = () => {
    Swal.fire({
      title: "Forgot Password",
      text: "Enter Contact Number",
      input: "number",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        let valid = true;
        // const emailregex =
        //   /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
        let contactregex = /^(?!0+$)\d+$/i;
        if (login === "") {
          Swal.showValidationMessage(`Please Enter Contact Number`);
          valid = false;
        } else {
          if (!contactregex.test(login)) {
            Swal.showValidationMessage(`Please Enter Valid Contact Number`);
            valid = false;
          }
        }
        if (valid === true) {
          let data = { contact_number: login };
          let headers = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          return (
            axios
              .post("https://api.knowyourmaid.com/api/forget_password", data)
              // return axios.post('http://localhost:8000/api/forget_password', data)

              .then((res) => {
                if (res.status === 200) {
                  return res.data.message;
                }
                //
                else {
                  throw new Error("Wrong details");
                }
              })
              .catch((e) => {
                Swal.showValidationMessage(`Request failed: Wrong details`);
                console.log(e, "err in forgot password");
              })
          );
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Forgot password link sent to your registered mobile number.",
            "",
            "success"
          );
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "warning",
          title: "Something went wrong",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      });
  };

  // useEffect(() => {
  //   const timeoutDuration = 60 * 1000; // 1 hour in milliseconds

  //   // Function to reset the session timeout on user activity
  //   const resetSessionTimeout = () => {
  //     clearTimeout(sessionTimeout);
  //     sessionTimeout = setTimeout(handleSessionTimeout, timeoutDuration);
  //   };

  //   // Attach the resetSessionTimeout function to user activity events
  //   document.addEventListener("mousemove", resetSessionTimeout);
  //   document.addEventListener("keydown", resetSessionTimeout);

  //   // Set the initial session timeout
  //   let sessionTimeout = setTimeout(handleSessionTimeout, timeoutDuration);

  //   // Clean up the event listeners when the component unmounts
  //   return () => {
  //     clearTimeout(sessionTimeout);
  //     document.removeEventListener("mousemove", resetSessionTimeout);
  //     document.removeEventListener("keydown", resetSessionTimeout);
  //   };
  // }, []);

  // const handleSessionTimeout = () => {
  //   // Clear session storage or perform any logout actions here
  //   localStorage.removeItem("UserDetails");
  //   sessionStorage.removeItem("UserDetails");
  //   // Redirect to the login page or any other page
  //   window.location.href = "/";
  // };

  const LoginForm = (e) => {
    setLoading(true);
    e.preventDefault();
    if (ValidateForm()) {
      const body = {
        contact_number: userName,
        password: password,
        Client_ID: 1,
        Client_secret: "Pmi5L9gBGhgIhQyyAMU8c1GAw7d0TKaDv2lDd6an",
      };
      setOtpPhone(body.contact_number);
      axios
        .post("https://api.knowyourmaid.com/api/login", body)
        // axios
        //   .post("http://localhost:8000/api/login", body)
        .then((res) => {
          if (res.status === 200) {
            const UserDetails = {
              email: res.data.user.email,
              userId: res.data.user.id,
              token: res.data.token,
              contact_number : res.data.user.contact_number,
              // refreshToken:res.data.token,
              role: res.data.role,
              employerID: res.data.role === 2 ? res.data.employer_id : null,
              Name: res.data.user.full_name,
              employeeID: res.data.role === 3 ? res.data.employee_id : null,
              // role:"Admin"
            };
            const loginDetails = JSON.stringify(UserDetails);
            localStorage.setItem("UserDetails", loginDetails);
            // sessionStorage.setItem('UserDetails', loginDetails);

            if (res.data.role === 1) {
              window.location.href = "/Dashboard";
            } else if (res.data.role === 2) {
              window.location.href = "/Profile";
            } else if (res.data.role === 3) {
              window.location.href = `/Employee_Profile`;
            } else if (res.data.role === 4) {
              window.location.href = `/EmployeeList`;
            }
            // setSessionTimeout();
          } else {
            Swal.fire({
              icon: "error",
              title: "Invalid Credentials",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            const res = err.response;
            if (res.status === 401) {
              Swal.fire({
                icon: "error",
                title: res?.data?.message,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              setShowOtp(true);
            } else if (res.status === 402) {
              Swal.fire({
                icon: "error",
                title: res?.data?.message,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              navigate("/Payment", { state: { userType: res.data.userType, contact_number:"0" + res.data.contactNumber 
 } });
            } else if (res.status === 403) {
              Swal.fire({
                icon: "error",
                title: res?.data?.message,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Invalid credentials",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        });
    }
    setLoading(false);
  };

  // const handleSendOtp = (e) => {
  //   debugger;
  //   e.preventDefault();
  //   const body = { contact_number: otpPhone };
  //   axios
  //     .post(`https://api.knowyourmaid.com/api/resend-otp`, body)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         Swal.fire({
  //           icon: "success",
  //           title: "OTP sent successfully on your Contact Number.",
  //           showConfirmButton: true,
  //           confirmButtonColor: "#3085d6",
  //           confirmButtonText: "OK",
  //         });
  //         setSendOtp(true);
  //         setCountdown(30);
  //       } else {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "Failed to Send Otp",
  //           showConfirmButton: true,
  //           confirmButtonColor: "#3085d6",
  //           confirmButtonText: "OK",
  //         });
  //         setSendOtp(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setSendOtp(false);
  //     });
  // };


  const handleSendOtp = (e) => {
    e.preventDefault();
    if (resendAttempts < 3) {
      const body = { contact_number: otpPhone };

      axios.post(`https://api.knowyourmaid.com/api/resend-otp`, body)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "OTP sent successfully on your Contact Number.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            setSendOtp(true);
            setCountdown(600);
          } else {
            Swal.fire({
              icon: "warning",
              title: "Failed to Send Otp",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            setSendOtp(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSendOtp(false);
        });

      setResendAttempts(prevAttempts => prevAttempts + 1);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Maximum attempts reached. Please try again later.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };


  return (
    <>
      <Topbar />
      {loading && <Loader />}
      <div className="container mt-3">
        <div className="row justify-content-center">
          <Col sm={6}>
            <div>
              <img src={Staff} alt="Cleaning-staff" className="img-fluid" />
            </div>
          </Col>
          <div className="col-md-5">
            <div className="pt-3">
              <div className="text-center">
                <h3 className="display-6 heading-h3">
                  Login{" "}
                  <span className="display-6 heading-h3-green">Here !</span>
                </h3>
              </div>
              <div className="pt-3">
                <div class="card form-border">
                  <div class="card-body">
                    <form>
                      <div class="mb-3">
                        <label for="username" class="form-label">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="username"
                          name="username"
                          value={userName}
                          onChange={(e) => {
                            setuserName(e.target.value);
                            setError({ ...Error, userName: "" });
                          }}
                        />
                        <div className="error-msg">{Error.userName}</div>
                      </div>
                      <div class="mb-3">
                        <label for="password" class="form-label">
                          Password
                        </label>
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setError({ ...Error, password: "" });
                            }}
                          />
                          <span
                            className="input-group-text"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FiEye /> : <FiEyeOff />}
                          </span>
                        </div>

                        <div className="error-msg">{Error.password}</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <p className="login-links">
                            <a href="#" onClick={onForgotPass}>
                              Forgot Password?
                            </a>
                          </p>
                        </div>
                        <div>
                          <p className="login-links">
                            <a href="/Register">Sign Up Here!</a>
                          </p>
                        </div>
                      </div>
                      <button
                        type="submit"
                        onClick={LoginForm}
                        class="login-btn"
                      >
                        LOGIN
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showOtp}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-center">
              <h3 className="display-7 heading-h3">
                OTP Verification
                {/* <span className="display-7 heading-h3-green"></span> */}
              </h3>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="container mt-4">
            <div className="row d-flex justify-content-center pt-6">
              <div className="col-sm-12">
                <div className="card form-border">
                  <div className="card-body">
                    {sendOtp === true ? (
                      <div>
                        <label htmlFor="userImage" className="form-label">
                          Please enter OTP sent on your Mobile No
                        </label>
                        <input
                          type="number"
                          name="otp"
                          id="otp"
                          value={otp}
                          className="form-control"
                          onChange={(e) => {
                            setOtp(e.target.value);
                            setError({ ...Error, otp: "" });
                          }}
                        />
                         <br/>
                        <p>
                        Resend OTP in <span style={{color:"red"}}>{Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}</span> seconds
                        </p>
                      </div>
                    ) : null}{" "}
                    <div style={{ color: "red", fontSize: 13 }}>
                      {Error.otp}
                    </div>
                    <br />
                    <div className="row d-flex justify-content-center">
                      {sendOtp === false ? (
                        <button
                          type="submit"
                          class="btn btn-primary col-sm-5"
                          onClick={handleSendOtp}
                        >
                          Send OTP
                        </button>
                      ) : (
                        countdown > 0 ?
                        <button
                          type="submit"
                          class="btn btn-primary col-sm-5"
                          onClick={handleVerifyOtp}
                        >
                          Verify
                        </button>
                        :
                         <button
                         type="submit"
                         class="btn btn-primary col-sm-5"
                         disabled={resendAttempts >= 3}
                         onClick={handleSendOtp}
                       >
                         Resend OTP
                       </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import { getRedFlagEmployee,EmployeegetById } from "../Api/AdminApi";
import { Container, Row, Col, Form } from "react-bootstrap";
import Topbar from "../Home/Topbar";
import Footer from "../Home/Footer";
import { Pagination } from "@mui/material";
import Loader from "../Loader/Loader";

const RedFlagList = () => {
  const [redFlagEmp, setRedFlagEmp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2; // Change this number based on your desired items per page
  const imagePath = "https://api.knowyourmaid.com/";



  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = redFlagEmp.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const openDoc = (id) => {
    EmployeegetById(id)
      .then((res) => {
        setFile(res.data.user.redFlagDoc);
        let liveurl=`https://api.knowyourmaid.com`
        const pdfUrl = `${liveurl}/${res.data.user.redFlagDoc}`;
        //  `${liveurl}/${res.data.user.redFlagDoc}`;
          // "https://api.knowyourmaid.com/storage/redFlag_Document/0irxzQrt7uDUPZoPQyPqZy4PNJmZX8lwLx8Kxt9U.pdf";
        window.open(pdfUrl, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRedFlagEmp();
  }, []);

  const getRedFlagEmp = () => {
    setLoading(true);
    getRedFlagEmployee()
      // axios.get("http://localhost:8000/api/empListRed")
      .then((res) => {
        const data = res.data.employees;
        setRedFlagEmp(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Topbar />
      {loading && <Loader />}
      <Container className="red-flag-container">
        <div>
          <div className="text-center py-4">
            <h3 className="display-6  heading-h4">
              Red Flagged{" "}
              <span className="display-6 heading-h3-green">Maids</span>
            </h3>
          </div>

          <Row>
            {currentItems &&
              currentItems.map((list, index) => (
                <div className="col-md-12">
                  <div className="row red-flag-box">
                    <div className="col-md-4 col-sm-12">
                      <div>
                        <img
                          src={imagePath+list.passportSize_img}
                          className="img-fluid rf-emp-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <div>
                        <p className="emp-name">{list.full_name}</p>
                       <div>
                       <label><b>Address :</b> <span >{list.address_1},{list.address_2},{list.address_3}</span> </label>
                       </div>
                       
                        <div>
                        <label><b>Crime :</b> <span className="mx-2">{list.redFlag_desc}</span> </label>
                        </div>
                        
                       <div>
                       <label><b>Helpline Number :</b> <span className="mx-2">1852 1256 1212</span></label>
                       </div>
                       <br/>
                       <button
                          type="button"
                          onClick={() => openDoc(list.id)}
                          className="doc-btn"
                          title="click to view documents"
                        >
                          View Reports
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Row>
        </div>
        <br/>
        <Pagination
          count={Math.ceil(redFlagEmp.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </Container>

      <Footer />
    </>
  );
};

export default RedFlagList;

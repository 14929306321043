import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Topbar from "./Topbar";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <>
    <Topbar/>
    <div className="privacy-policy-page">
      <Container >
        <br/>
       <div className="privacy-container py-3">
       <div className="text-center py-5">
          <h2>Privacy Policy</h2>
        </div>
        <Row className="pb-5">
          <Col>
            <div className="what-privacy">
              <ul>
                <li>
                  <p>Explore the Policy</p>
                </li>
                <li>
                  <p>What is the privacy policy and what does it cover?</p>
                </li>
                <li>
                  <p>What information do we collect?</p>
                </li>
                <li>
                  <p>How do we use the information that we collect?</p>
                </li>
                <li>
                  <p>How do we share information with third parties?</p>
                </li>
                <li>
                  <p>How long do we keep your information for?</p>
                </li>
                <li>
                  <p>
                    How do we respond to legal requests, comply with applicable
                    and prevent harm/data misuse?
                  </p>
                </li>
                <li>
                  <p>How will you know the policy has changed?</p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="privacy-que-box">
              <p className="prvcy-que">
                1. What is the privacy policy and what does it cover?
              </p>
              <p>
                This Privacy Policy explains how we collect, use and share your
                information. It also describes how long we keep your information
                for and how we keep it safe when we share with legal authorities
                when requested according to applicable law and how we let you
                know when we change the existing policy.
              </p>
            </div>
            <div className="privacy-que-box">
              <p className="prvcy-que">2. What information do we collect?</p>
              <p>
                The information that you give us when you sign up for our
                Products and create a profile, such as your names, your
                location, email address or phone number.
              </p>
              <p>
                Your employee’s information that you write and upload in your
                account. Your employee’s profile information which includes
                their names, their location, their ID information and their
                photos.
              </p>
            </div>
            <div className="privacy-que-box">
              <p className="prvcy-que">
                3. How do we use the information we collect?
              </p>
              <p>
                To identify and verify the owner of the account for
                accountability.
              </p>
              <p>
                To help other users especially employers who may be in search of
                data or information relating to a specific person(s) they wish
                to hire or refer for employment or even red flag to warn other
                unsuspecting employers from hiring potentially harm individuals
                in their homes or companies.
              </p>
              <p>
                To help employers easily retrieve the information they have
                recorded about their employees over time when need arise to
                either promote them, refer them or even report them to the legal
                authorities when they have committed crimes and possibly run
                away.
              </p>
              <p>
                To help employers trace and track the behavior pattern of their
                employees past, present and future. And by so doing, build a
                rich employee’s database that other employers can use to
                understand their potential workers character, capabilities and
                limitations before hiring them.
              </p>
            </div>
            <div className="privacy-que-box">
              <p className="prvcy-que">
                4. How do we share information with third parties?
              </p>
              <p>We share certain information with:</p>

              <ul>
                <li>
                  <p>Advertisers who show ads on our Products</p>
                </li>
                <li>
                  <p>Businesses we hire to market our Products for us</p>
                </li>
                <li>
                  <p>
                    Businesses we hire to do things such as offer customer
                    service or conduct surveys
                  </p>
                </li>
                <li>
                  <p>
                    Researchers who use it to do things such as innovate,
                    advance technology or improve people's safety
                  </p>
                </li>
                <li>
                  <p>
                    We may share also with relevant legal authorities as
                    applicable laws allow upon requests such as court orders,
                    search warrants or subpoenas.
                  </p>
                </li>
                <li>
                  <p>We don't sell your information and we never will.</p>
                </li>
              </ul>
            </div>
            <div className="privacy-que-box">
              <p className="prvcy-que">
                5. How long do we keep your information?
              </p>
              <p>
                We keep information for as long as we need it to provide
                reference for those searching.
              </p>
              <p>But you can request that we delete your information</p>
              <p>
                We'll delete that information unless we have to keep it for
                something else, for instance for legal reasons
              </p>
            </div>
            <div className="privacy-que-box">
              <p className="prvcy-que">
                6. How do we respond to legal requests, comply with applicable
                and prevent harm/data misuse?
              </p>
              <p>
                Sometimes we have to provide information to respond to legal
                requests when necessary
              </p>
              <p>
                If there's a law that says we need to keep your information for
                some reason, we need to comply with it. So, we'll keep
                information to obey that law. Examples are, court orders, search
                warrants and subpoenas.
              </p>
              <p>
                We also sometimes keep information that we think will help
                prevent or stop bad activity, either on our Platform or in the
                society.
              </p>
            </div>
            <div className="privacy-que-box">
              <p className="prvcy-que">
                7. How will you know the policy has changed?
              </p>
              <p>
                We'll notify you before we make material changes to this Policy.
                You'll have the opportunity to review the revised Policy before
                you choose to continue using our platform.
              </p>
            </div>
          </Col>
        </Row>
        <div className="text-center py-5">
          <h2>Terms of Service</h2>
        </div>
        <Row className="pt-4">
          <Col>
            <div>
              <p>
                Maids Trace and Track Africa has built a platform
                (knowyourmaid.com) that enables employers to trace and track
                their employees. We do this by encouraging employers to
                subscribe on our platform for a small maintenance fee (the
                amount can be reviewed from time to time as need arise) and
                thereafter utilize our platform to keep records of
                theiremployees’ behavior, character and happenings that take
                place throughout their working period in the company or in
                homes/houses. These records form a rich database for other
                future employers to refer to before hiring such employees. Evey
                employer always want to know as much as possible about potential
                employees before hiring them but it’s normally not possible
                because workers only tell what they want their prospective
                employers to know and not what employers ought to know.
              </p>
              <p>
                We have provided this platform so that employers can share
                experiences with each other concerning various employees whether
                good or bad so that future employers can make decisions to hire
                or not to hire them from a point of information and not
                otherwise.
              </p>
              <p>
                As you may be aware that majority of crimes committed today in
                companies and homes are planned by workers (inside job) in those
                companies and homes. This is even worse when it comes to
                employing of Maids/House-helps in our homes to take care of our
                most valuable items in our houses and our dear children/families
                and we have no idea who our Maids are. It is now in public
                domain that, a good number of Maids today are closely working
                with robbers, kidnappers and thieves and their main role is to
                seek housemaid jobs in the target homes and then plan the
                robbery from within. Such workers are ready to receive any
                amount of wage no matter how little just to gain access to your
                home. After a short while they will either steal from the
                employer and run away or they orchestrate robbery, kidnapping or
                theft while still working there or soon after they leave. Some
                will invite strangers into your house when you are out for work
                or even feed your child with sleeping pills to keep them
                sleeping all day as they do their dirty stuff in and out of the
                house.
              </p>
              <p>
                Unfortunately, without information sharing among employers, such
                employees end up being hired by another target unsuspecting
                employer and the cycle of stealing and run away continues
                unabated. No wonder the turnover rate especially among
                Housemaids is very high these days. Sharing information among
                employers is empowering each other and breaking the cycle of
                crimes and criminalism perpetrated by workers who hop from one
                house or company to another.
              </p>
              <br/>
              <p className="prvcy-que">
                Who can use knowyourmaid.com platform.
              </p>
              <p>
                When people stand behind their opinions and actions, our
                community is safer and more accountable. For this reason, you
                must:
              </p>
              <p>
                Provide for your account the same name that you use in everyday
                life and avoid impersonation or fake account. (This is one of
                the reasons we charge for membership to keep away jokers.)
              </p>
              <p>
                Provide accurate information about yourself and your employee at
                all times.
              </p>
              <p>
                Only create one account (your own and specify whether you are an
                employer or visitor) and use it for personal purposes to record
                information that pertains to you employees. Visitors cannot
                register employee, they can only search for employees
                information if they are in possession of such employees ID
                number (search is only done using employees ID number) and they
                can also write a comment against what has already been reported
                by employer(s).
              </p>
              <p>
                Do not share your password, give access to your knowyourmaid.com
                account to others or transfer your account to anyone else
                (without our permission).
              </p>
              <p>
                We encourage all employers to record and share their employees’
                information with utmost accuracy and transparency. Mudslinging
                and defamation are highly discouraged on this platform. In fact,
                an employer cannot Red Flag a former employee without uploading
                a verifiable OB report from the police to confirm that the crime
                allegedly committed by such worker has been reported and is
                under investigation.
              </p>
            </div>
            <br/>
            <div className="privacy-que-box">
              <p className="prvcy-que">Limit on liability</p>
              <p>
                We work hard to provide the best Products we can and to specify
                clear guidelines for everyone who uses them. Our Products,
                however, are provided "As is," and to the extent permissible by
                law, we make no guarantees that they always will be safe,
                secure, or error-free, or that they will function without
                disruptions, delays, or imperfections. To the extent permitted
                by law, we also disclaim all warranties, whether express or
                implied, including the implied warranties of merchantability,
                fitness for a particular purpose, title and non-infringement. We
                do not control ordirect what people and others do or say, and we
                are not responsible for their actions or conduct (whether online
                or offline) or any content that they share (including offensive,
                inappropriate, obscene, unlawful, incorrect, and other
                objectionable content).
              </p>
              <p>
                We cannot predict when issues may arise with our Products.
                Accordingly, our liability shall be limited to the fullest
                extent permitted by applicable law. To the fullest extent
                permitted by applicable law, under No circumstance will we be
                liable to you for any lost profits, revenues, information, or
                data, or consequential, special, indirect, exemplary, punitive
                or incidental damages arising out of or related to these Terms
                or Platform (however caused and on any theory of liability,
                including negligence), even if we have been advised of the
                possibility of such damages.
              </p>
            </div>
            <div className="privacy-que-box">
              <p className="prvcy-que">Updating our Terms</p>
              <p>
                We work constantly to improve our services and develop new
                features to make our Platform better for you and our community.
                As a result, we may need to update these Terms from time to time
                to accurately reflect our services and practices, to promote a
                safe and secure experience on our Products and services, and/or
                to comply with applicable law. We will only make any changes if
                the provisions are no longer appropriate or if they are
                incomplete, and only if the changes are reasonable and take due
                account of your interests, or if the changes are required for
                safety and security purposes or to comply with applicable law.
              </p>
              <p>
                We will notify you (for example, by email or through our
                Products) at least 30 days before we make changes to these Terms
                and give you an opportunity to review them before they go into
                effect, unless changes are required by law. Once any updated
                Terms are in effect, you will be bound by them if you continue
                to use our Platform.
              </p>
            </div>
          </Col>
        </Row>
       </div>
      </Container>
    </div>
    <Footer/>
    </>
  );
};

export default PrivacyPolicy;

import React from 'react'
import { Col, Card } from 'react-bootstrap';
import './NotFound.css'
import { useNavigate } from "react-router-dom";
import sad from '../../assets/images/sad.png'

export default function NotFound({name}) {
    const navigate = useNavigate();

    return (
        <div className='container mt-5'>
        <div className='row justify-content-center '>
            <Card className="text-center shadow p-3  bg-white rounded m-0" style={{ width: '40rem' }}>
            <Card.Body>
            {/* <h1 className='text sry' style={{fontWeight:'800', color:'#E1AD01'}}>SORRY</h1> */}
                <h1 className='text'>{name}</h1>
                {/* <span className="search-message-empty-decal">
                    <span className="search-message-empty-decal-eyes">:</span>
                    <span>(</span>
                </span> */}
                
                {/* <div className='sad-image justify-content-center'>
                    <img src={sad} alt='sad' />
                </div> */}
                <h2 className="search-message-empty-message">
                    No Records Found.
                </h2>
                {/* <div className="button_style">
                    <button
                        type="button"
                        className="btn btn-pos2"
                        onClick={() => navigate(-1)}
                    >
                        Return
                    </button>
                </div> */}
                </Card.Body>
            </Card>
            </div>
        </div>

    )
}

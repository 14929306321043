import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
// import { VerifyEmailData } from './LoginRegisterAPI';
import check from "../../assets/images/check.png";
import "./VerifyEmail.css";
import swal from "sweetalert";
import axios from "axios";
import Auth from "../ServiceApi/Auth";

const VerifyEmail = () => {
  const [data, setData] = useState({
    verifyEmail: true,
    token: "",
  });
  const role = Auth.getRole();

  let { token } = useParams();
  useEffect(() => {
    // const user = searchParams.get("user")
    setData({ ...data, token: token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkEmailVerification = (e) => {
    e.preventDefault();
    let token = data.token;
    axios
      .post(`https://api.knowyourmaid.com/api/verify_email/${token}`)
      // axios.post(`http://localhost:8000/api/verify_email/${token}`)

      .then((res) => {
        console.log(res, "get response of verify email");
        if (res.status === 200) {
          swal("Email Verified Successfully", "", "success");
          if (role === 3) {
            window.location.href = "/login";
          } else {
            window.location.href = "/Payment";
          }
        } else {
          swal("Not verified", "", "error");
        }
      })
      .catch((err) => {
        console.log(err, "err in email verification");
      });
  };

  return (
    <div>
      <Modal
        show={data.verifyEmail}
        id="verifyEmail"
        className="modal-min verifyEmailModal animate__animated animate__zoomIn"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="modal-body m-0 text-center">
          <div>
            <img src={check} alt="" />
          </div>
          <br />
          <h1 style={{ fontWeight: "600" }}>Verify Your Email</h1>
          <br />
          <form onSubmit={checkEmailVerification}>
            <Button
              type="submit"
              className="btn btn-primary shadow-none"
              size="lg"
            >
              Click here to Verify
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default VerifyEmail;
